import React from 'react';
import { Autocomplete, Button, Flex, Box, Group, Text, Tooltip } from '@mantine/core';
import { IconCheck, IconPencil, IconTrash, IconX } from '@tabler/icons-react';
import { Face } from '../../types/Face';

interface FaceCardFooterProps {
    face: Face;
    isHovered: boolean;
    isEditing: boolean;
    onAction: (action: 'confirm' | 'deny' | 'untrack') => void;
    onEdit: () => void;
    autocompleteProps: {
        searchValue: string;
        suggestions: string[];
        onSearchChange: (value: string) => void;
        onOptionSubmit: (value: string) => void;
        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    };
}

export const FaceCardFooter: React.FC<FaceCardFooterProps> = ({
                                                                  face,
                                                                  isHovered,
                                                                  isEditing,
                                                                  onAction,
                                                                  onEdit,
                                                                  autocompleteProps
                                                              }) => {

    if (face.is_person?.confirmed) {
        return (
            <Group grow preventGrowOverflow={false} wrap="nowrap" h="100%" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                <Box
                    w="100%"
                    h="80%"
                    bg="darkgrey"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                    onClick={onEdit}
                >
                    <Text size="sm" ta="center" c="dark">
                        {face.is_person
                            ? `${face.is_person.person.first} ${face.is_person.person.last}`
                            : "Unnamed Person"}
                    </Text>
                </Box>
            </Group>
        );
    }


    if (isEditing) {
        return (
            <Group grow preventGrowOverflow={false} wrap="nowrap" h="100%" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                <Box w="100%" h="80%">
                    <Autocomplete
                        width="100%"
                        height="100%"
                        value={autocompleteProps.searchValue}
                        onChange={autocompleteProps.onSearchChange}
                        data={autocompleteProps.suggestions}
                        onOptionSubmit={autocompleteProps.onOptionSubmit}
                        onKeyDown={autocompleteProps.onKeyDown}
                        placeholder="Enter name"
                        styles={{
                            input: { textAlign: 'center' }
                        }}
                        autoFocus
                    />
                </Box>
            </Group>
        );
    }

    return (
        <Group grow preventGrowOverflow={false} wrap="nowrap" h="100%" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
            <Tooltip
                label={face.is_person?
                    `This is not ${face.is_person.person.first} ${face.is_person.person.last}` :
                    'Untrack this face'}
                withArrow
                openDelay={500}
                position="bottom"
            >
                <Button
                    variant="transparent"
                    color="dark"
                    onClick={() => face.is_person ?
                        onAction('deny') :
                        onAction('untrack')}
                    p={0}
                >
                    {face.is_person ?
                        <IconX size={16} /> :
                        <IconTrash size={16} />}
                </Button>
            </Tooltip>
            <Box
                w="70%"
                h="80%"
                bg="lightgray"
                style={{
                    borderRadius: '5px',
                    outline: '1px solid darkgray',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
                onClick={onEdit}
            >
                <Text size="sm" ta="center" c="dark">
                    {face.is_person
                        ? `${face.is_person.person.first} ${face.is_person.person.last}?`
                        : "?"}
                </Text>
            </Box>
            {face.is_person ? (
                <Tooltip
                    label={`Confirm face as ${face.is_person.person.first} ${face.is_person.person.last}`}
                    withArrow
                    openDelay={500}
                    position="bottom"
                >
                    <Button
                        variant="transparent"
                        color="dark"
                        onClick={() => onAction('confirm')}
                        p={0}
                    >
                        <IconCheck size={16} />
                    </Button>
                </Tooltip>
            ) : (
                <Button
                    variant="transparent"
                    color="lightgray"
                    onClick={onEdit}
                    p={0}
                >
                    <IconPencil size={16} />
                </Button>
            )}
        </Group>
    );
};