import React, {useEffect, useState, useRef, useCallback} from 'react';
import {
    Blockquote,
    Divider,
    Flex,
    Loader,
    Title,
    Text,
    Box,
    ActionIcon,
    Stack,
    Group,
    Button,
    Notification, Modal, Alert, Progress, Paper, Center, ThemeIcon, Anchor
} from '@mantine/core';
import {
    IconInfoCircle,
    IconTrash,
    IconDownload,
    IconRefresh,
    IconX,
    IconPhoto,
    IconCreditCard,
    IconCheck,
    IconAlertCircle, IconCircleCheck
} from "@tabler/icons-react";
import {EventGroup} from './EventGroup';
import {UnsortedImageGroup} from './UnsortedImageGroup';
import {GalleryContentSkeleton} from './ContentSkeleton';
import ImageModal from '../ImageModal';
import {EventDetailsForm} from './EventDetailsForm';
import {
    deleteImages,
    fetchImages,
    resetImageMetadata,
    fetchImageDetails
} from '../../api/Image';
import {fetchEvents, updateEvent} from "../../api/Event";
import {EventData} from '../../types/Event';
import {ImageData} from '../../types/Image';
import {Link} from "react-router-dom";


const APP_BASE_URL = process.env.REACT_APP_BASE_URL;

// todo: is there a way I can break up this function by moving some parts to other files. its getting really long?
export function Gallery() {
    // event groupings, sorted from most recent to least recent. Also states to track event editing state.
    const [events, setEvents] = useState<EventData[]>([]);
    const [eventsLoading, setEventsLoading] = useState(false);
    const [isEditingEvent, setIsEditingEvent] = useState(false);
    const [currentEditingEvent, setCurrentEditingEvent] = useState<EventData | null>(null);

    // all the images without event groupings - note: these should be sorted according to time_uploaded.
    const [unsorted, setUnsorted] = useState<ImageData[]>([]);
    const [unsortedLoading, setUnsortedLoading] = useState(false);
    const [isUnsortedVisible, setIsUnsortedVisible] = useState(false);

    // selected image / fullscreen image (these images could be from events or unsorted (or a combo of both)
    const [selectedImages, setSelectedImages] = useState<Set<string>>(new Set());
    const [fullscreenImage, setFullscreenImage] = useState<string | null>(null);

    // Download-related state
    const [ws, setWs] = useState<WebSocket | null>(null);
    const [isDownloading, setIsDownloading] = useState(false); //downloading notification / button state
    const [downloadSucceeded, setDownloadSucceeded] = useState(false); // success notification
    const [downloadFailed, setDownloadFailed] = useState(false); // fail notification
    const [downloadProgress, setDownloadProgress] = useState(0); // progress bar - allowed values: [0, 100]
    const [downloadStatus, setDownloadStatus] = useState<string | null>(null); // todo: i think i can delete this.

    const [downloadData, setDownloadData] = useState<{
        urls?: { url: string; filename: string }[];
        zipUrl?: string
    } | null>(null);

    // Credit limit modal state
    const [isCreditLimitModalOpen, setIsCreditLimitModalOpen] = useState(false);
    const [creditLimitInfo, setCreditLimitInfo] = useState<{
        downloadCount: number;
        downloadLimit: number;
        remainingCredits: number;
    } | null>(null);

    // content reference (for sizing the bottom with the buttons)
    const contentRef = useRef<HTMLDivElement>(null);
    const [contentWidth, setContentWidth] = useState<number | null>(null);

    // on mount, load events/unsorted & update container width
    // on unmount remove event window size observer
    useEffect(() => {
        const loadData = async () => {
            await Promise.all([loadEvents(), loadUnsorted()]);
            // once I'm done loading images & the first image renders,
            // I can set the container width on mount.
            if (contentRef.current) {
                setContentWidth(contentRef.current.offsetWidth);
            }
        };

        loadData();

        const handleResize = () => {
            if (contentRef.current) {
                setContentWidth(contentRef.current.offsetWidth);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    // clean up WebSocket on unmount
    useEffect(() => {
        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, [ws]);

    const connectWebSocket = useCallback(() => {
        const token = sessionStorage.getItem('token');
        console.log('Creating WebSocket for download...');

        let wsUrl = process.env.REACT_APP_WEBSOCKET
        const fullWsUrl = `${wsUrl}?Authorization=${token}`;

        const webSocket = new WebSocket(fullWsUrl);

        webSocket.onopen = () => {
            console.log('WebSocket connected!');
            setDownloadStatus("Connected to download service.");
        };

        webSocket.onmessage = (event) => {
            try {
                const message = JSON.parse(event.data);
                console.log('WebSocket message received:', message);

                if (message.type === 'downloadProgress') {
                    switch (message.status) {
                        case 'progress':
                            setDownloadStatus(`Processing image ${message.current} of ${message.total} (${message.key})...`);
                            setDownloadProgress(Math.round(message.current * 100 / message.total));
                            break;

                        case 'urlReady':
                            setDownloadStatus(`URL ready for image ${message.key}`);
                            setDownloadProgress(Math.round(message.current * 100 / message.total));
                            downloadSingleFile(message.url, message.filename || message.key);
                            break;

                        case 'complete':
                            setDownloadStatus("Download complete!");

                            if (message.urls || message.zipUrl || message.downloadUrls) {
                                handleDownloadComplete(true);
                            }

                            // Handle specific URL format cases
                            if (message.urls) {
                                // New format: array of {url, filename} objects
                                console.log("all individual urls should be done downloading!")
                            } else if (message.zipUrl) {
                                // Zip download case
                                downloadZipFile(message.zipUrl);
                                // window.open(message.zipUrl, '_blank');
                            } else if (message.downloadUrls) {
                                // Legacy format: object with key-url pairs
                                Object.entries(message.downloadUrls).forEach(([key, url]) => {
                                    if (typeof url === 'string') {
                                        downloadSingleFile(url, key);
                                    } else {
                                        console.error(`Invalid URL type for key ${key}`);
                                    }
                                });
                            }
                            break;

                        case 'error':
                            setDownloadStatus(`Download failed: ${message.message}`);
                            handleDownloadComplete(false);

                            if (message.message && message.message.includes('Download limit reached')) {
                                // Extract credit information if available
                                const downloadCount = message.downloadCount || 0;
                                const downloadLimit = message.downloadLimit || 10;
                                const remainingCredits = Math.max(0, downloadLimit - downloadCount);

                                // Set credit limit info and show the modal
                                setCreditLimitInfo({
                                    downloadCount,
                                    downloadLimit,
                                    remainingCredits
                                });
                                setIsCreditLimitModalOpen(true);
                            }

                            break;

                        default:
                            console.log('Unknown message status:', message.status);
                    }
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        };

        webSocket.onerror = (error) => {
            console.error('WebSocket error:', error);
            setDownloadStatus("Connection error. Please try again.");
            setIsDownloading(false);
        };

        webSocket.onclose = () => {
            console.log('WebSocket closed.');
            setIsDownloading(false);
            setDownloadStatus("Download session ended.");
            setWs(null);
        };

        return webSocket;
    }, []);

    const onImageSelect = (images: string | string[]) => {
        setSelectedImages((prevSelected) => {
            const updatedSelection = new Set(prevSelected);
            if (Array.isArray(images)) {
                images.forEach((image) => updatedSelection.add(image));
            } else {
                updatedSelection.add(images);
            }
            return updatedSelection;
        });
    };

    const onImageDeselect = (images: string | string[]) => {
        setSelectedImages((prevSelected) => {
            const updatedSelection = new Set(prevSelected);
            if (Array.isArray(images)) {
                images.forEach((image) => updatedSelection.delete(image));
            } else {
                updatedSelection.delete(images);
            }
            return updatedSelection;
        });
    };

    const [visibleEventKeys, setVisibleEventKeys] = useState<Set<string>>(new Set());

    // Load events with pagination
    const loadEvents = async () => {
        setEventsLoading(true);
        try {
            let {events} = await fetchEvents();
            setEvents(events);
            const imageCount = events.reduce((sum, event) => sum + event.images.length, 0);
            console.log(`Loaded ${events.length} events with ${imageCount} images`);
        } catch (error) {
            console.error('Failed to load events:', error);
        } finally {
            setEventsLoading(false);
        }
    };

    // Create a ref to track which images are currently being fetched
    const inFlightImageRequests = useRef(new Set<string>());

    // Load thumbnails for visible events
    const loadThumbnailsForVisibleEvents = useCallback(async () => {
        if (visibleEventKeys.size === 0 && !isUnsortedVisible) return;

        // Get all image keys from visible events that don't have thumbnails yet
        // and are not already being fetched
        const imagesToLoad: string[] = [];

        // Check events first
        events.forEach(event => {
            if (visibleEventKeys.has(event.key)) {
                event.images.forEach(image => {
                    if (!image.thumbnail_url && !inFlightImageRequests.current.has(image.key)) {
                        imagesToLoad.push(image.key);
                    }
                });
            }
        });

        // Then check unsorted images if they're visible
        if (isUnsortedVisible) {
            unsorted.forEach(image => {
                if (!image.thumbnail_url && !inFlightImageRequests.current.has(image.key)) {
                    imagesToLoad.push(image.key);
                }
            });
        }

        if (imagesToLoad.length === 0) return;

        try {
            // Mark these images as in-flight
            imagesToLoad.forEach(key => inFlightImageRequests.current.add(key));

            // Batch image thumbnail requests (max 50 at a time)
            const BATCH_SIZE = 50;
            for (let i = 0; i < imagesToLoad.length; i += BATCH_SIZE) {
                const batch = imagesToLoad.slice(i, i + BATCH_SIZE);

                // Fetch image data will return a map of image keys to urls
                const {image_details} = await fetchImageDetails(batch);

                // Update the events with the thumbnail data
                setEvents(prevEvents => {
                    const updatedEvents = [...prevEvents];

                    // Loop through each returned image detail
                    Object.entries(image_details).forEach(([key, url]) => {
                        // Type assertion to ensure url is treated as a string
                        const thumbnailUrl = url as string;

                        // For each event, find the image with matching key and update its thumbnail_url
                        for (const event of updatedEvents) {
                            const imageIndex = event.images.findIndex(img => img.key === key);
                            if (imageIndex !== -1) {
                                event.images[imageIndex] = {
                                    ...event.images[imageIndex],
                                    thumbnail_url: thumbnailUrl
                                };
                                break;
                            }
                        }

                        // Remove from in-flight set once processed
                        inFlightImageRequests.current.delete(key);
                    });

                    return updatedEvents;
                });

                // Update unsorted images with thumbnail data
                setUnsorted(prevUnsorted => {
                    const updatedUnsorted = [...prevUnsorted];

                    Object.entries(image_details).forEach(([key, url]) => {
                        // Type assertion to ensure url is treated as a string
                        const thumbnailUrl = url as string;

                        // Find the image in unsorted and update its thumbnail_url
                        const imageIndex = updatedUnsorted.findIndex(img => img.key === key);
                        if (imageIndex !== -1) {
                            updatedUnsorted[imageIndex] = {
                                ...updatedUnsorted[imageIndex],
                                thumbnail_url: thumbnailUrl
                            };
                        }

                        // Remove from in-flight set once processed
                        inFlightImageRequests.current.delete(key);
                    });

                    return updatedUnsorted;
                });
            }
        } catch (error) {
            console.error('Failed to load thumbnails:', error);

            // In case of error, remove all batch items from in-flight requests
            imagesToLoad.forEach(key => inFlightImageRequests.current.delete(key));
        }
    }, [visibleEventKeys, events, unsorted, isUnsortedVisible]);

    // Effect to load thumbnails when visible events or unsorted visibility changes
    useEffect(() => {
        loadThumbnailsForVisibleEvents();
    }, [visibleEventKeys, isUnsortedVisible, loadThumbnailsForVisibleEvents]);

    const loadUnsorted = async () => {
        setUnsortedLoading(true);
        try {
            let {images} = await fetchImages(false, true);
            setUnsorted(images);
            console.log(`Loaded ${images.length} unsorted images`);
        } catch (error) {
            console.error('Failed to load unsorted images:', error);
        } finally {
            setUnsortedLoading(false);
        }
    };

    // Track which events are visible
    const handleEventVisibilityChange = (eventKey: string, isVisible: boolean) => {
        setVisibleEventKeys(prev => {
            const updated = new Set(prev);
            if (isVisible) {
                updated.add(eventKey);
            } else {
                updated.delete(eventKey);
            }
            return updated;
        });
    };

    // Track unsorted images visibility
    const handleUnsortedVisibilityChange = (isVisible: boolean) => {
        setIsUnsortedVisible(isVisible);
    };

    const handleDeleteSelected = async () => {
        if (selectedImages.size === 0) return;

        const selectedKeys = Array.from(selectedImages);
        console.log('Deleting selected images:', selectedKeys);

        // Save a copy of the previous events for rollback
        const previousEvents = [...events];
        const previousUnsorted = [...unsorted];

        // Optimistically remove images, and drop events with no remaining images
        setEvents((prevEvents) =>
            prevEvents
                .map((event) => ({
                    ...event,
                    images: event.images.filter((image) => !selectedKeys.includes(image.key))
                }))
                .filter((event) => event.images.length > 0) // Remove events with no images
        );

        // Also remove from unsorted
        setUnsorted(prevUnsorted =>
            prevUnsorted.filter(image => !selectedKeys.includes(image.key))
        );

        try {
            setEventsLoading(true);
            const {deletedImages} = await deleteImages(selectedKeys);

            if (deletedImages.length > 0) {
                console.log('Successfully deleted:', deletedImages);
                setSelectedImages(new Set()); // Clear selection
            } else {
                throw new Error('No images deleted');
            }
        } catch (error) {
            console.error(error);
            alert('Failed to delete images. Please try again.');

            // Rollback to the previous events state
            setEvents(previousEvents);
            setUnsorted(previousUnsorted);
        } finally {
            setEventsLoading(false);
        }
    };

    // Update the handleDownloadSelected function
    const handleDownloadSelected = async () => {
        if (selectedImages.size === 0 || isDownloading) return;

        console.log('Downloading selected images:', Array.from(selectedImages));
        setIsDownloading(true);
        setDownloadStatus("Connecting to download service...");
        setDownloadProgress(1);

        // Close existing connection if any
        if (ws) {
            console.log("Closing existing WebSocket...");
            ws.close();
        }

        // Create a new WebSocket connection
        const newWs = connectWebSocket();
        setWs(newWs);

        // Wait for the WebSocket to be ready
        const waitForOpenConnection = (socket: WebSocket): Promise<void> => {
            return new Promise<void>((resolve) => {
                if (socket.readyState === WebSocket.OPEN) {
                    resolve();
                } else {
                    socket.onopen = () => resolve();
                }
            });
        };

        try {
            await waitForOpenConnection(newWs);
            console.log('WebSocket is ready, sending download request...');

            setDownloadStatus("Requesting download...");
            const message = JSON.stringify({
                action: 'download',
                keys: Array.from(selectedImages)
            });

            console.log(`Sending message: ${message}`);
            newWs.send(message);
        } catch (error) {
            console.error('WebSocket connection failed:', error);
            setDownloadStatus("Failed to connect to download service.");
            setIsDownloading(false);
        }
    };

    // Helper functions for downloading
    const downloadSingleFile = async (url: string, filename: string): Promise<void> => {
        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            const blob = await response.blob();
            const objectUrl = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = objectUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(objectUrl);

            return Promise.resolve();
        } catch (error) {
            console.error('Download failed:', error);
            return Promise.reject(error);
        }
    };

    const downloadZipFile = async (url: string): Promise<void> => {
        try {
            const response = await fetch(url);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            const blob = await response.blob();
            const objectUrl = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = objectUrl;
            link.download = 'shoebox-downloads.zip'; // Set the desired filename
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(objectUrl);

            return Promise.resolve();
        } catch (error) {
            console.error('Download failed:', error);
            return Promise.reject(error);
        }
    };
    
    // Function to handle purchasing credits
    const handlePurchaseCredits = () => {
        // Redirect to the credits purchase page
        window.open(`${APP_BASE_URL}/#pricing`, '_blank');
        setIsCreditLimitModalOpen(false);
    };

    // Function to retry download with fewer images
    const handleRetryWithFewerImages = () => {
        // Close the modal
        setIsCreditLimitModalOpen(false);

        // If there's remaining credits, automatically select that many images
        if (creditLimitInfo && creditLimitInfo.remainingCredits > 0) {
            // Select only the number of images allowed by remaining credits
            const selectedImagesArray = Array.from(selectedImages);
            const limitedSelection = new Set(
                selectedImagesArray.slice(0, creditLimitInfo.remainingCredits)
            );

            // Update selected images
            setSelectedImages(limitedSelection);

            // Notify the user about the change
            setDownloadStatus(`Selection limited to ${limitedSelection.size} images based on your remaining download credits.`);

            // Wait a moment before initiating the download
            setTimeout(() => {
                handleDownloadSelected();
            }, 1500);
        }
    };

    // todo: instead of deleting images, i would instead like to put them into the unsortedImages and ideally
    //  place them so that they are added to the array in the correct position based upon image.time_uploaded.
    const handleResetMetadata = async () => {
        const selectedKeys = Array.from(selectedImages);
        console.log('Resetting metadata for images:', selectedKeys);

        // Save a copy of the previous events for rollback
        const previousEvents = [...events];
        const previousUnsorted = [...unsorted];

        // Optimistically remove images, and drop events with no remaining images
        setEvents((prevEvents) =>
            prevEvents
                .map((event) => ({
                    ...event,
                    images: event.images.filter((image) => !selectedKeys.includes(image.key))
                }))
                .filter((event) => event.images.length > 0) // Remove events with no images
        );

        // Also remove from unsorted
        setUnsorted(prevUnsorted =>
            prevUnsorted.filter(image => !selectedKeys.includes(image.key))
        );

        try {
            const {resetImages} = await resetImageMetadata(selectedKeys);
            console.log(`Metadata reset for images:`, resetImages);

            if (resetImages.length > 0) {
                console.log('Successfully reset:', resetImages);
                setSelectedImages(new Set()); // Clear selection
            } else {
                throw new Error('Image could not be reset');
            }
        } catch (error) {
            console.error(error);
            alert('Failed to reset images. Please try again.');

            // Rollback to the previous states
            setEvents(previousEvents);
            setUnsorted(previousUnsorted);
        }
    };

    const handleEditEventClick = (event: EventData) => {
        setCurrentEditingEvent(event);
        setIsEditingEvent(true);
    };

    const updateSelectedEvent = async (eventKey: string, updatedEvent: EventData) => {
        // Save a copy of the previous events for rollback
        const previousEvents = [...events];

        try {
            // Optimistically update the event
            setEvents(prevEvents => {
                const updatedEvents = [...prevEvents];
                const eventIndex = updatedEvents.findIndex(e => e.key === eventKey);

                if (eventIndex === -1) return prevEvents;

                updatedEvents[eventIndex] = updatedEvent;

                console.log(updatedEvents);
                console.log('Event index:', eventIndex);

                const currentEvent = updatedEvents[eventIndex];
                const previousEvent = updatedEvents[eventIndex - 1];
                const nextEvent = updatedEvents[eventIndex + 1];

                // Check if the event is already in the correct position
                if (eventIndex === 0 && nextEvent && new Date(currentEvent.date!) >= new Date(nextEvent.date!)) {
                    console.log('Event is already the most recent, no reorder needed.');
                    return updatedEvents;
                }

                if (previousEvent && nextEvent) {
                    const isMoreRecentThanNext = new Date(currentEvent.date!) >= new Date(nextEvent.date!);
                    const isLessRecentThanPrevious = new Date(currentEvent.date!) <= new Date(previousEvent.date!);

                    if (isMoreRecentThanNext && isLessRecentThanPrevious) {
                        console.log('Event is in the correct position, no reorder needed.');
                        return updatedEvents;
                    }
                }

                // Remove the event from its current position
                updatedEvents.splice(eventIndex, 1);

                // Find the new position based on date (newest first)
                const newIndex = updatedEvents.findIndex(e => {
                    if (!e.date || !currentEvent.date) return false;
                    return new Date(currentEvent.date).getTime() > new Date(e.date).getTime();
                });

                // Insert the updated event at its new position
                const finalPosition = newIndex === -1 ? updatedEvents.length : newIndex;
                updatedEvents.splice(finalPosition, 0, currentEvent);

                return updatedEvents;
            });

            // Perform the actual update request
            const response = await updateEvent(updatedEvent);

            if (!response) {
                throw new Error('No response from updateEvent');
            }

        } catch (error) {
            console.error('Failed to update event:', error);
            alert('Failed to update event. Reverting changes.');

            // Rollback to the previous state
            setEvents(previousEvents);
        }
    };

    // Unified notification component
    const renderNotification = () => {
        if (isDownloading) {
            return (
                <Notification
                    ml={'xl'} mr={'xl'} radius="lg"
                    loading withBorder
                    title="Downloading..."
                    color="blue"
                    withCloseButton={false}
                    style={{ zIndex: 3 }}
                    icon={downloadProgress === 100 ? null : <Loader size="sm" />}
                    closeButtonProps={{ icon: <IconX size={16} /> }}
                >
                     <Progress mt="sm" size="md" value={downloadProgress} animated />
                </Notification>
            );
        }

        if (downloadSucceeded) {
            return (
                <Notification
                    ml={'xl'} mr={'xl'} radius="lg"
                    withBorder
                    title="Download Complete"
                    color="teal"
                    withCloseButton
                    style={{ zIndex: 3 }}
                    icon={<IconCheck size={18} />}
                />
            );
        }

        if (downloadFailed) {
            return (
                <Notification
                    ml={'xl'} mr={'xl'} radius="lg"
                    withBorder
                    title="Download Failed"
                    color="red"
                    withCloseButton
                    style={{ zIndex: 3 }}
                    icon={<IconAlertCircle size={18} />}
                >
                    {downloadStatus || "An error occurred during the download."}
                </Notification>
            );
        }

        return null;
    };

    // Handle success and failure notifications
    const handleDownloadComplete = (success: boolean) => {
        setIsDownloading(false);
        setDownloadSucceeded(success);
        setDownloadFailed(!success);

        setTimeout(() => {
            setDownloadSucceeded(false);
            setDownloadFailed(false);
        }, 3000);
    };


    return (
        <Flex direction="column" style={{flexGrow: 1, padding: '16px'}}>
            <Title order={1}>Gallery</Title>
            <Divider mt="xs"/>
            <Blockquote color="blue" icon={<IconInfoCircle/>} mt="lg" mb="lg">
                This gallery tab is where all of your sorted images will be displayed
                after you have finished organizing them!
            </Blockquote>

            <div ref={contentRef} style={{position: 'relative'}}>
                {/* If there are no event/image display message for user*/}
                {!eventsLoading && events.length === 0 && !unsortedLoading && unsorted.length === 0 &&
                    <Paper w="100%" mt="md" shadow="md" radius="md" withBorder p="xl">
                        <Stack align="center" gap="md">
                            <ThemeIcon size="xl" radius="xl" color="blue">
                                <IconCircleCheck size={32}/>
                            </ThemeIcon>
                            <Text size="lg" w={600} ta="center">
                               Looks like you don't have any images yet.
                            </Text>
                            <Text c="gray.6" ta="center">
                                <Anchor component={Link} to="/import" underline="always">Import</Anchor> images here to get started!
                            </Text>
                        </Stack>
                    </Paper>
                }

                {/* if events/images still loading, display loader*/}
                {(eventsLoading || unsortedLoading) && (
                    <Box mt="md">
                        <GalleryContentSkeleton/>
                    </Box>
                )}

                {/* if there are events to display, display them! */}
                {events.length > 0 && !eventsLoading && (
                    <Stack gap="md">
                        {events.map(event => (
                            <EventGroup
                                key={event.key}
                                event={event}
                                onImageSelect={onImageSelect}
                                onImageDeselect={onImageDeselect}
                                onImageClick={(image: ImageData) => setFullscreenImage(image.thumbnail_url || '')}
                                onEditEventClick={handleEditEventClick}
                                onVisibilityChange={handleEventVisibilityChange}
                            />
                        ))}
                    </Stack>
                )}

                {/* if there are images to display, display them! */}
                {unsorted.length > 0 && !unsortedLoading && (
                    <UnsortedImageGroup
                        key={'unsorted-image-grouping'}
                        images={unsorted}
                        onImageSelect={onImageSelect}
                        onImageDeselect={onImageDeselect}
                        onImageClick={(image: ImageData) => setFullscreenImage(image.thumbnail_url || '')}
                        onVisibilityChange={handleUnsortedVisibilityChange}
                    />
                )}
            </div>

            <ImageModal
                imageUrl={fullscreenImage}
                onClose={() => setFullscreenImage(null)}
            />

            {/* Download Notifications & Bottom Toolbar */}
            {/* fixed position at bottom of screen; conditional renders based on selected images / downloading state */}
            {selectedImages.size > 0 && (
                <Box
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        zIndex: 2,
                        width: contentWidth ? `${contentWidth}px` : '100%',
                        left: contentRef.current?.getBoundingClientRect().left || 0,
                    }}
                >
                    <Stack gap={'sm'}>
                        {renderNotification()}
                        <Box
                            style={{
                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                transition: 'width 0.2s ease, left 0.2s ease'
                            }}
                        >
                        <Flex justify="space-between" mt="md" mb="md" ml="xl" mr='xl'>
                            {/* Left-aligned group */}
                            <Box style={{width: '33%', display: 'flex', justifyContent: 'flex-start'}}>
                                <Group gap="xs">
                                    <Button size="md" variant="dark" color="blue" radius="xl">
                                        {selectedImages.size}
                                    </Button>
                                    <Text fw={500} size="xl">
                                        Image{selectedImages.size !== 1 ? 's' : ''} Selected
                                    </Text>
                                </Group>
                            </Box>

                            {/* Center button */}
                            <Box style={{width: '33%', display: 'flex', justifyContent: 'center'}}>
                                <Button
                                    size="lg"
                                    variant="filled"
                                    color="blue"
                                    onClick={handleDownloadSelected}
                                    leftSection={<IconDownload size={24}/>}
                                    disabled={isDownloading}
                                >
                                    Download
                                    {/*{isDownloading ? "Downloading..." : "Download"}*/}
                                </Button>
                            </Box>

                            {/* Right buttons */}
                            <Box style={{width: '33%', display: 'flex', justifyContent: 'flex-end'}}>
                                <Group gap="xs">
                                    <ActionIcon size="xl" variant="filled" color="red" onClick={handleDeleteSelected}>
                                        <IconTrash size={24}/>
                                    </ActionIcon>
                                    <ActionIcon size="xl" variant="filled" color="red" onClick={handleResetMetadata}>
                                        <IconRefresh size={24}/>
                                    </ActionIcon>
                                </Group>
                            </Box>
                        </Flex>
                        </Box>
                    </Stack>
                </Box>
            )}

            {/* Credit Limit Modal */}
            <Modal
                opened={isCreditLimitModalOpen}
                onClose={() => setIsCreditLimitModalOpen(false)}
                title={<Title order={3}>Download Limit Reached</Title>}
                size="md"
                centered
            >
                <Stack gap="md">
                    <Text>
                        You've reached your download limit on the free plan.
                        {creditLimitInfo && creditLimitInfo.remainingCredits > 0 ?
                            ` You have ${creditLimitInfo.remainingCredits} downloads remaining out of your limit of ${creditLimitInfo.downloadLimit}.` :
                            ' You have used all your available downloads.'}
                    </Text>

                    <Alert
                        icon={<IconInfoCircle size={16} />}
                        title="Free Plan Limitations"
                        color="blue"
                        variant="light"
                    >
                        Your free plan includes {creditLimitInfo?.downloadLimit || 10} image downloads.
                        Upgrade to unlock unlimited downloads and additional premium features.
                    </Alert>

                    <Flex gap="md" justify="space-between" mt="md">
                        {creditLimitInfo && creditLimitInfo.remainingCredits > 0 && (
                            <Button
                                variant="outline"
                                onClick={handleRetryWithFewerImages}
                                leftSection={<IconPhoto size={18} />}
                            >
                                Download {creditLimitInfo.remainingCredits} Images
                            </Button>
                        )}

                        <Button
                            color="blue"
                            onClick={handlePurchaseCredits}
                            leftSection={<IconCreditCard size={18} />}
                            fullWidth={!(creditLimitInfo && creditLimitInfo.remainingCredits > 0)}
                        >
                            Upgrade Account
                        </Button>
                    </Flex>
                </Stack>
            </Modal>

            {/* Event Details Form Modal */}
            {isEditingEvent &&
                <Modal
                    opened={isEditingEvent}
                    onClose={() => setIsEditingEvent(false)}
                    title="Edit Event Details"
                    size="80%"
                    style={{
                        width: '80vw',
                        height: '80vh',
                        maxWidth: 'none',
                    }}
                >
                    {currentEditingEvent && (
                        <EventDetailsForm
                            eventData={currentEditingEvent}
                            onEventUpdate={(updatedEvent: EventData) => {
                                updateSelectedEvent(currentEditingEvent.key, updatedEvent);
                                setIsEditingEvent(false);
                            }}
                        />
                    )}
                </Modal>
            }
        </Flex>
    );
}