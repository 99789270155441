import React from 'react';
import { Box, Flex, Text, Title, Image, Container, Group } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';

interface StepItem {
    number: string;
    title: string;
    titleLineBreak?: boolean;
    description: string;
    imageSrc?: string;
    mobileImageSrc?: string;
    desktopImageSrc?: string;
}

interface StepProps {
    features: StepItem[];
}

const NumberCircle = ({ number }: { number: string }) => (
    <Box
        style={{
            width: '70px',
            height: '70px',
            borderRadius: '50%',
            backgroundColor: '#3284c1',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexShrink: 0,
            position: 'relative',
            zIndex: 2
        }}
    >
        <Text
            style={{
                color: 'white',
                fontSize: '2.5rem',
                fontWeight: 'bold',
                lineHeight: 1
            }}
        >
            {number}
        </Text>
    </Box>
);

export const Steps: React.FC<StepProps> = ({ features }) => {
    const { width, height } = useViewportSize();
    const isMobile = width < 768;
    const isTablet = width >= 768 && width < 1024;

    // Format the title with a line break if needed
    const formatTitle = (title: string, shouldBreak?: boolean) => {
        if (!shouldBreak || isMobile) return title;

        const parts = title.split('&');
        if (parts.length !== 2) return title;

        return (
            <>
                {parts[0].trim()}&nbsp;&<br />
                {parts[1].trim()}
            </>
        );
    };

    // Get the correct image based on screen size
    const getImageSrc = (feature: StepItem) => {
        if (isMobile && feature.mobileImageSrc) {
            return feature.mobileImageSrc;
        } else if (!isMobile && feature.desktopImageSrc) {
            return feature.desktopImageSrc;
        }
        return feature.imageSrc;
    };

    // Calculate font size based on viewport width
    const getTitleFontSize = () => {
        if (isMobile) return '1.75rem';
        if (isTablet) return '2.5rem';
        return '3.5rem';
    };

    const getDescriptionFontSize = () => {
        if (isMobile) return '0.9rem';
        if (isTablet) return '1.1rem';
        return '1.3rem';
    };

    return (
        <Container size="xl" px={isMobile ? "xs" : "md"} style={{ overflow: 'hidden' }}>
            {features.map((feature, index) => {
                const isEven = index % 2 === 1;
                const isLast = index === features.length - 1;
                const hasImage = getImageSrc(feature) !== undefined;

                return (
                    <Box key={index} my={isMobile ? "10vh" : "20vh"} pos="relative">
                        <Flex
                            direction={(isMobile || !hasImage) ? "column" : isEven ? "row-reverse" : "row"}
                            align="center"
                            justify={!hasImage ? "center" : "space-between"}
                            gap={isMobile ? "md" : "sm"}
                            h={isMobile ? 'auto' : hasImage ? "auto" : "auto"}
                            style={{
                                position: 'relative',
                                flexWrap: 'wrap'
                            }}
                        >
                            <Box
                                style={{
                                    flex: isMobile ? "100%" : hasImage ? "0 1 40%" : "0 1 60%",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: !hasImage ? 'center' : (isMobile ? 'center' : (isEven ? 'flex-end' : 'flex-start')),
                                    maxWidth: isMobile ? "100%" : (hasImage ? "40%" : "60%"),
                                }}
                            >
                                <Flex justify="center" mb={isMobile ? "xl" : "lg"}>
                                    <NumberCircle number={feature.number} />
                                </Flex>
                                <Title
                                    mt={isMobile? "xl": 'sm'}
                                    order={2}
                                    style={{
                                        fontFamily: 'Bebas Neue',
                                        fontWeight: 400,
                                        fontStyle: 'bold',
                                        fontSize: getTitleFontSize(),
                                        textTransform: 'uppercase',
                                        lineHeight: 1,
                                        textAlign: !hasImage || isMobile ? 'center' : (isEven ? 'right' : 'left'),
                                        marginBottom: '1rem',
                                        wordBreak: 'break-word',
                                        hyphens: 'auto'
                                    }}
                                >
                                    {formatTitle(feature.title, feature.titleLineBreak)}
                                </Title>

                                <Text
                                    style={{
                                        fontSize: getDescriptionFontSize(),
                                        lineHeight: 1.5,
                                        color: '#333',
                                        textAlign: !hasImage || isMobile ? 'center' : (isEven ? 'right' : 'left'),
                                        maxWidth: "100%",
                                        wordWrap: 'break-word'
                                    }}
                                >
                                    {feature.description}
                                </Text>
                            </Box>

                            {hasImage && (
                                <Box
                                    style={{
                                        flex: isMobile ? "1 0 100%" : "0 1 55%",
                                        display: "flex",
                                        justifyContent: isMobile ? 'center' : (isEven ? 'left' : 'right'),
                                        alignItems: "center",
                                        maxWidth: isMobile ? "100%" : "55%",
                                        marginTop: isMobile ? "2rem" : 0
                                    }}
                                >
                                    <Image
                                        src={getImageSrc(feature)}
                                        style={{
                                            maxHeight: isMobile ? "auto" : "60vh",
                                            width: "auto",
                                            maxWidth: "100%",
                                            objectFit: "contain"
                                        }}
                                    />
                                </Box>
                            )}
                        </Flex>
                    </Box>
                );
            })}
        </Container>
    );
};

// Example usage component
export const FeaturesSection: React.FC = () => {
    const featureData = [
        {
            number: "1",
            title: "Upload Your Photos",
            titleLineBreak: false,
            description: "Scan your photos any way that works for you! Use a scanning service, DIY, or use the SortMyShoebox Mobile App*. Check out our Photo Scanning Guide to learn more about our tips, tricks and recommendations for getting started.",
            imageSrc: undefined,
            mobileImageSrc: "./HeroScreenShots.png",
            desktopImageSrc: undefined
        },
        {
            number: "2",
            title: "Identify Faces & Build Your Family Tree",
            titleLineBreak: true,
            description: "Tagging know people and providing context such as birthdays, key life events, and relationships help our AI algorithms deliver the best date predictions & generate context-aware captions.",
            imageSrc: "./CreateNewEvent.png",
            mobileImageSrc: "./NameFaces.png",
            desktopImageSrc: "./NameFaces.png"
        },
        {
            number: "3",
            title: "We'll Help You Organize & Add Metadata",
            titleLineBreak: true,
            description: "Let our AI assist you in de-duplicating photos, predicting dates, labeling events, and adding locations, keywords, and captions!",
            imageSrc: "./CreateNewEvent.png",
            mobileImageSrc: "./CreateNewEvent.png",
            desktopImageSrc: "./CreateNewEvent.png"
        },
        {
            number: "4",
            title: "Export & Enjoy",
            titleLineBreak: false,
            description: "We'll export your full-sized images with all the metadata embeddings so you're ready to share them anywhere.",
            imageSrc: undefined,
            mobileImageSrc: undefined,
            desktopImageSrc: undefined
        }
    ];

    return <Steps features={featureData} />;
};

export default FeaturesSection;