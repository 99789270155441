import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { checkAndRefreshTokenIfNeeded, logout } from "./api/Auth";
import { UserProfile } from './types/User'

export interface AuthState {
    isAuthenticated: boolean;
    userProfile: UserProfile;
}

export const useAuth = () => {
    const [authState, setAuthState] = useState<AuthState>({
        isAuthenticated: false,
        userProfile: {
            email: null,
            firstName: null,
            lastName: null,
            profileUrl: null,
            sub: null,
        }
    });

    const [loading, setLoading] = useState(true);

    const setUserProfileFromSession = () => {
        setAuthState({
            isAuthenticated: true,
            userProfile: {
                email: sessionStorage.getItem('email'),
                firstName: sessionStorage.getItem('firstName'),
                lastName: sessionStorage.getItem('lastName'),
                profileUrl: sessionStorage.getItem('profileUrl'),
                sub: localStorage.getItem('sub'),
                uploadCount: Number(sessionStorage.getItem('uploadCount')),
                uploadLimit: Number(sessionStorage.getItem('uploadLimit')),
                downloadCount: Number(sessionStorage.getItem('downloadCount')),
                downloadLimit: Number(sessionStorage.getItem('downloadLimit'))
            }
        });
    };

    const updateUserCounters = (updates: {
        uploadCount?: number;
        downloadCount?: number;
        uploadLimit?: number;
        downloadLimit?: number;
    }) => {
        // Update session storage with new values
        if (updates.uploadCount !== undefined) {
            sessionStorage.setItem('uploadCount', updates.uploadCount.toString());
        }

        if (updates.downloadCount !== undefined) {
            sessionStorage.setItem('downloadCount', updates.downloadCount.toString());
        }
        // Update session storage with new values
        if (updates.uploadLimit !== undefined) {
            sessionStorage.setItem('uploadLimit', updates.uploadLimit.toString());
        }

        if (updates.downloadLimit !== undefined) {
            sessionStorage.setItem('downloadLimit', updates.downloadLimit.toString());
        }
        // Update the auth state with new values
        setAuthState(prevState => ({
            ...prevState,
            userProfile: {
                ...prevState.userProfile,
                uploadCount: updates.uploadCount !== undefined
                    ? updates.uploadCount
                    : prevState.userProfile.uploadCount,
                downloadCount: updates.downloadCount !== undefined
                    ? updates.downloadCount
                    : prevState.userProfile.downloadCount,
                uploadLimit: updates.uploadLimit !== undefined
                    ? updates.uploadLimit
                    : prevState.userProfile.uploadLimit,
                downloadLimit: updates.downloadLimit !== undefined
                    ? updates.downloadLimit
                    : prevState.userProfile.downloadLimit
            }
        }));
    };


    const checkAuthStatus = useCallback(async () => {
        setLoading(true);

        const token = sessionStorage.getItem('token');
        const sub = localStorage.getItem('sub');

        if (!token || !sub) {
            // Attempt to refresh token if we don't have a valid token in session storage
            const tokenCheck = await checkAndRefreshTokenIfNeeded();

            if (tokenCheck.isValid) {
                setUserProfileFromSession();
                setLoading(false);
                return true;
            }

            // If refresh fails, user is not authenticated
            setAuthState(prev => ({ ...prev, isAuthenticated: false }));
            setLoading(false);
            return false;
        }

        // If we have a token and sub, assume authenticated
        setUserProfileFromSession();
        setLoading(false);
        return true;
    }, []);

    // Axios interceptor for token refresh on 401 errors
    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            response => response,
            async error => {
                console.log("🔥 Intercepted error:", error);

                const originalRequest = error.config;
                if ((error.response?.status === 401 || error.response?.status === 400) && !originalRequest._retry) {
                    console.log(`🔄 ${error.response?.status} detected, attempting token refresh...`);
                    originalRequest._retry = true;

                    try {
                        const tokenCheck = await checkAndRefreshTokenIfNeeded();
                        console.log("✅ Token refresh result:", tokenCheck);

                        if (tokenCheck.isValid) {
                            const newToken = sessionStorage.getItem('token');
                            if (newToken) {
                                originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                                console.log("🔄 Retrying request with new token...");
                                return axios(originalRequest);
                            } else {
                                console.log("❌ No new token found, logging out...");
                            }
                        }
                    } catch (refreshError) {
                        console.error("❌ Token refresh failed:", refreshError);
                    }

                    await handleLogout();
                }

                console.log("❌ Request failed after handling:", error);
                return Promise.reject(error);
            }
        );

        return () => axios.interceptors.response.eject(interceptor);
    }, []);


    // Check authentication status on mount and tab visibility change
    useEffect(() => {
        checkAuthStatus();

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                checkAuthStatus();
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
    }, [checkAuthStatus]);

    const handleLogout = async () => {
        console.log("handling logout...");
        await logout();
        setAuthState({
            isAuthenticated: false,
            userProfile: {
                email: null,
                firstName: null,
                lastName: null,
                profileUrl: null,
                sub: null,
            }
        });
        window.location.href = `${process.env.REACT_APP_BASE_URL}`;
    };

    const handleLogin = () => {
        setUserProfileFromSession();
    };

    // Automatically log in the user if visiting the login page with a valid refresh token
    const autoLoginIfPossible = async () => {
        setLoading(true);
        const tokenCheck = await checkAndRefreshTokenIfNeeded();
        if (tokenCheck.isValid) {
            setUserProfileFromSession();
        }
        setLoading(false);
    };

    return {
        ...authState,
        loading,
        checkAuthStatus,
        handleLogin,
        handleLogout,
        autoLoginIfPossible,
        updateUserCounters
    };
};
