import {SegmentedControl, Tooltip} from "@mantine/core";
import {IconCalendar, IconCalendarMonth} from "@tabler/icons-react";
import { DateFormat } from '../../types/Event';
import React from "react";

// todo: add year?

interface DateFormatControlProps {
    dateFormat: DateFormat;
    onDateFormatChange: (newFormat: DateFormat) => void;
}

export const DateFormatControl = ({dateFormat, onDateFormatChange}: DateFormatControlProps) => (
    <SegmentedControl
        size="sm"
        value={dateFormat}
        onChange={(newFormat: string) => {
            console.log(newFormat);
            if (newFormat === DateFormat.DAY || newFormat === DateFormat.MONTH) {
                onDateFormatChange(newFormat);
            } else {
                onDateFormatChange(DateFormat.DAY);
            }
        }}
        data={[
            {
                value: DateFormat.DAY,
                label: (
                    <Tooltip
                        openDelay={0.5}
                        label="specify day"
                        position="bottom"
                    >
                        <IconCalendar size={16}/>
                    </Tooltip>
                )
            },
            {
                value: DateFormat.MONTH,
                label: (
                    <Tooltip
                        openDelay={0.5}
                        label="specify month"
                        position="bottom"
                    >
                        <IconCalendarMonth size={16}/>
                    </Tooltip>
                )
            }
            // todo: maybe later?
            // {
            //     value: DateFormat.YEAR,
            //     label: (
            //         <Tooltip
            //             openDelay={0.5}
            //             label="specify year"
            //             position="bottom"
            //         >
            //             <IconCalendarMonth size={16}/>
            //         </Tooltip>
            //     )
            // }
        ]}
    />
);