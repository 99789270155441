import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Textarea, LoadingOverlay, Text } from '@mantine/core';
import { PersonBetter} from "../../types/Person";
import { useDebouncedCallback } from 'use-debounce';

interface NotesPanelProps {
    person: PersonBetter;
    onUpdate: (updatedFields: Partial<PersonBetter>) => Promise<void>;
    isUpdating: boolean;
}

export const NotesPanel: React.FC<NotesPanelProps> = React.memo(({
                                                                     person,
                                                                     onUpdate,
                                                                     isUpdating
                                                                 }) => {
    const [notes, setNotes] = useState(person.notes || '');
    const [lastSaveTime, setLastSaveTime] = useState<number>(Date.now());
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const lastSavedContentRef = useRef(person.notes || '');
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const isSavingRef = useRef(false);

    // Create a debounced save function
    const debouncedSave = useDebouncedCallback(
        async (content: string) => {
            if (content === lastSavedContentRef.current || isSavingRef.current) {
                return;
            }

            isSavingRef.current = true;
            // console.log("Saving notes...");

            try {
                await onUpdate({ notes: content });
                lastSavedContentRef.current = content;
                setLastSaveTime(Date.now());
                setHasUnsavedChanges(false);
                // console.log("Notes saved successfully");
            } catch (error) {
                // console.error('Failed to save notes:', error);
                setNotes(lastSavedContentRef.current);
            } finally {
                isSavingRef.current = false;
            }
        },
        3000, // 3 second delay
        { maxWait: 10000 } // Maximum 10 seconds before forced save
    );

    // Handle notes change
    const handleNotesChange = useCallback((value: string) => {
        setNotes(value);
        setHasUnsavedChanges(true);
        debouncedSave(value);
    }, [debouncedSave]);

    // Handle manual save with Ctrl+S
    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        if ((event.ctrlKey || event.metaKey) && event.key === 's') {
            event.preventDefault();
            debouncedSave.flush();
        }
    }, [debouncedSave]);

    // Set up keyboard listener
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    // Sync with external changes to person.notes
    useEffect(() => {
        if (person.notes !== lastSavedContentRef.current) {
            // console.log("External notes change detected - syncing state");
            setNotes(person.notes || '');
            lastSavedContentRef.current = person.notes || '';
            setHasUnsavedChanges(false);
            setLastSaveTime(Date.now());
        }
    }, [person.notes]);

    // Save on unmount if there are unsaved changes
    useEffect(() => {
        return () => {
            if (hasUnsavedChanges && !isSavingRef.current) {
                debouncedSave.flush();
            }
        };
    }, [hasUnsavedChanges, debouncedSave]);

    const getTimeSinceLastSave = useCallback(() => {
        const timeDiff = Date.now() - lastSaveTime;
        if (timeDiff < 60000) {
            return 'just now';
        } else {
            return `${Math.floor(timeDiff / 60000)} minutes ago`;
        }
    }, [lastSaveTime]);

    return (
        <Box mt={15} p={20} pos="relative">
            <LoadingOverlay visible={isUpdating} />
            <Textarea
                ref={textareaRef}
                size="lg"
                placeholder={`This is a great place to jot down any stories/memories you have about/with ${person.first} ${person.last}`}
                autosize
                minRows={2}
                value={notes}
                onChange={(event) => handleNotesChange(event.currentTarget.value)}
                disabled={isUpdating}
            />
            <Text size="xs" color="dimmed" mt={5}>
                {hasUnsavedChanges
                    ? "Saving..."
                    : `Last saved: ${getTimeSinceLastSave()}`
                }
            </Text>
        </Box>
    );
});

NotesPanel.displayName = 'NotesPanel';