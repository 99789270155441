import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Box, Button, Text, Select, Group, Stack, ActionIcon, LoadingOverlay } from '@mantine/core';
import { IconTrash, IconPlus } from '@tabler/icons-react';
import { PersonBetter, Relationship, RelationshipType, RelationshipTypes} from "../../types/Person";
import { useDebouncedCallback } from 'use-debounce';
import {fetchPeople} from "../../api/People";

interface RelationshipsPanelProps {
    person: PersonBetter;
    onUpdate: (updatedFields: Partial<PersonBetter>) => Promise<void>;
    isUpdating: boolean;
}

export const RelationshipsPanel: React.FC<RelationshipsPanelProps> = React.memo(({
                                                                                     person,
                                                                                     onUpdate,
                                                                                     isUpdating,
                                                                                 }) => {

    const [fullPeople, setFullPeople] = useState<PersonBetter[]>([]);
    const [people, setPeople] = useState<{value: string, label: string}[]>([]);
    const [relationships, setRelationships] = useState<Relationship[]>(() => {
        const initialRelationships = person.relationships || [];
        return initialRelationships.map(rel => ({
            ...rel,
            name: rel.person?.seed_key || null
        }));
    });

    const [hasPendingChanges, setHasPendingChanges] = useState(false);
    const lastSavedRef = useRef<Relationship[]>(person.relationships || []);
    const isSavingRef = useRef(false);

    useEffect(() => {
        const loadPeople = async () => {
            try {
                const fetchedPeople = await fetchPeople();
                setFullPeople(fetchedPeople);
                let pairs: { value: string; label: string }[] = fetchedPeople.map((p: PersonBetter) => ({
                    value: p.seed_key,
                    label: `${p.first} ${p.last}`.trim(),
                }));
                setPeople(pairs);
            } catch (error) {
                console.error('Failed to load people for autocomplete:', error);
            }
        };
        loadPeople();
    }, []);

    const hasUnsavedChanges = useCallback(() => {
        const currentRelationships = JSON.stringify(relationships);
        const lastSaved = JSON.stringify(lastSavedRef.current);
        return currentRelationships !== lastSaved;
    }, [relationships]);

    const saveChanges = async (updatedRelationships: Relationship[]) => {
        if (isSavingRef.current) {
            return;
        }
        isSavingRef.current = true;
        setHasPendingChanges(false);

        try {
            await onUpdate({ relationships: updatedRelationships });
            lastSavedRef.current = updatedRelationships;
        } catch (error) {
            console.error('Failed to save relationships:', error);
            // Silently fail - keep local changes even if save fails
        } finally {
            isSavingRef.current = false;
        }
    };

    const debouncedSave = useDebouncedCallback(
        (updatedRelationships: Relationship[]) => {
            if (hasUnsavedChanges()) {
                saveChanges(updatedRelationships);
            }
        },
        3000,
        { maxWait: 5000 }
    );

    const handleAddRelationship = useCallback(() => {
        const newRelationships = [...relationships, {
            type: null,
            name: null,
            person: undefined
        }];
        setRelationships(newRelationships);
        setHasPendingChanges(true);
    }, [relationships]);

    const handleRemoveRelationship = useCallback(async (index: number) => {
        const updatedRelationships = relationships.filter((_, i) => i !== index);
        setRelationships(updatedRelationships);
        setHasPendingChanges(true);
        debouncedSave(updatedRelationships);
    }, [relationships, debouncedSave]);

    const handleUpdateRelationship = useCallback((
        index: number,
        field: 'type' | 'name',
        value: string | null
    ) => {
        const updatedRelationships = relationships.map((rel, i) => {
            if (i === index) {
                if (field === 'name') {
                    const selectedPerson: PersonBetter | undefined = fullPeople.find((p:PersonBetter) => p.seed_key === value);
                    return {
                        ...rel,
                        name: value,
                        person: selectedPerson || undefined
                    } as Relationship;
                }
                return {
                    ...rel,
                    [field]: field === 'type' ? (value as RelationshipType | null) : value
                } as Relationship;
            }
            return rel;
        });

        setRelationships(updatedRelationships);
        setHasPendingChanges(true);
        debouncedSave(updatedRelationships);
    }, [relationships, debouncedSave, fullPeople]);

    const handleBlur = useCallback(() => {
        if (hasPendingChanges) {
            debouncedSave.flush();
        }
    }, [hasPendingChanges, debouncedSave]);

    useEffect(() => {
        if (JSON.stringify(person.relationships) !== JSON.stringify(lastSavedRef.current)) {
            setRelationships(person.relationships || []);
            lastSavedRef.current = person.relationships || [];
        }
    }, [person.relationships]);

    useEffect(() => {
        return () => {
            if (hasPendingChanges && !isSavingRef.current) {
                debouncedSave.flush();
            }
        };
    }, [hasPendingChanges, debouncedSave]);

    return (
        <Box mt={20} p={20} pos="relative">
            <LoadingOverlay visible={isUpdating} />
            <Stack gap="md">
                {relationships.length === 0 ? (
                    <Text>No relationships have been added yet.</Text>
                ) : (
                    relationships.map((relationship, index) => (
                        <Group key={index} justify="space-between" align="center">
                            <Select
                                data={RelationshipTypes}
                                value={relationship.type || ''}
                                onChange={(value) => handleUpdateRelationship(index, 'type', value)}
                                onBlur={handleBlur}
                                placeholder="Select relationship type"
                                style={{ flex: 1 }}
                                disabled={isUpdating}
                            />
                            <Text>of</Text>
                            <Select
                                data={people}
                                value={relationship.name || ''}
                                onChange={(value) => handleUpdateRelationship(index, 'name', value)}
                                onBlur={handleBlur}
                                placeholder="Select Person"
                                style={{ flex: 1 }}
                                disabled={isUpdating}
                            />
                            <ActionIcon
                                color="red"
                                onClick={() => handleRemoveRelationship(index)}
                                variant="outline"
                                disabled={isUpdating}
                            >
                                <IconTrash size={16} />
                            </ActionIcon>
                        </Group>
                    ))
                )}

                <Button
                    onClick={handleAddRelationship}
                    leftSection={<IconPlus size={16} />}
                    variant="outline"
                    disabled={isUpdating}
                >
                    Add Relationship
                </Button>
            </Stack>
        </Box>
    );
});

RelationshipsPanel.displayName = 'RelationshipsPanel';