import React, {useState, useEffect, useRef} from 'react';
import {
    Button,
    Checkbox,
    createTheme,
    Divider,
    Flex,
    Group,
    MantineProvider,
    Paper,
    Text,
    Title,
    Skeleton,
    Box, Stack, ThemeIcon, Anchor
} from '@mantine/core';
import {ImageData} from '../../types/Image';
import ImageWithSkeleton from '../ImageWithSkeleton';
import {useIntersection} from '@mantine/hooks';
import {IconCircleCheck} from "@tabler/icons-react";
import {Link} from "react-router-dom";

interface UnsortedImageGroupProps {
    images: ImageData[],
    onImageSelect: (images: string | string[]) => void,
    onImageDeselect: (images: string | string[]) => void,
    onImageClick: (images: string | string[]) => void,
    onVisibilityChange?: (isVisible: boolean) => void,
    containerWidth: number | null,
    containerLeft: number,
}

export const UnsortedImageGroup: React.FC<UnsortedImageGroupProps> = ({
                                                                          images,
                                                                          onImageSelect,
                                                                          onImageDeselect,
                                                                          onImageClick,
                                                                          onVisibilityChange,
                                                                          containerWidth,
                                                                          containerLeft
                                                                      }) => {
    const [selectedImages, setSelectedImages] = useState<Set<string>>(new Set());
    // const [hoveredImage, setHoveredImage] = useState<string | null>(null);

    // Set up intersection observer with threshold and rootMargin
    const {ref: inViewRef, entry} = useIntersection({
        root: null,
        threshold: 0.1,
        rootMargin: '150%' // Pre-load when getting close to viewport
    });

    // Report visibility changes to parent component
    useEffect(() => {
        if (onVisibilityChange && entry) {
            onVisibilityChange(entry.isIntersecting);
        }
    }, [entry, onVisibilityChange]);


    const toggleImageSelection = (image: ImageData, event?: React.MouseEvent) => {
        event?.stopPropagation();
        if (selectedImages.has(image.key)) {
            const newSelection = new Set(selectedImages);
            newSelection.delete(image.key);
            setSelectedImages(newSelection);
            onImageDeselect(image.key);
        } else {
            setSelectedImages(new Set(selectedImages).add(image.key));
            onImageSelect(image.key);
        }
    };

    const theme = createTheme({cursorType: 'pointer'});

    return (
        <Paper ref={inViewRef}>
            <Flex direction="row" wrap="wrap" gap={{base: '3px', sm: '3px'}} justify={{sm: '3px'}}>
                {images.map((image) => (
                    <div
                        key={`${image.key}`}
                        style={{
                            position: 'relative',
                            width: 'calc((100% - 15px) / 5)',
                            aspectRatio: '1 / 1'
                        }}
                    >
                        <div
                            onClick={(e) => toggleImageSelection(image, e)}
                            style={{
                                position: 'absolute',
                                top: 5,
                                left: 5,
                                zIndex: 1,
                                width: '24px',
                                height: '24px'
                            }}
                        >
                            <MantineProvider theme={theme}>
                                <Checkbox
                                    checked={selectedImages.has(image.key)}
                                    readOnly
                                />
                            </MantineProvider>
                        </div>

                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                                border: selectedImages.has(image.key) ? '3px solid #228be6' : 'none',
                                overflow: 'hidden'
                            }}
                        >
                            {image.thumbnail_url ? (
                                <ImageWithSkeleton
                                    src={image.thumbnail_url}
                                    alt={`Photo ${image.datetime ? `taken on ${image.datetime}` : 'with unknown date'}`}
                                    onClick={() => {
                                        toggleImageSelection(image)
                                    }}
                                />
                            ) : (
                                <Skeleton width='100%' height="100%"/>
                            )}
                        </div>
                    </div>
                ))}
            </Flex>
        </Paper>
    );
};