import axios, {AxiosError} from 'axios';
import {EventData} from "../aiGroupImages/GroupInterfaces";
import {DuplicateImageGroup} from "../../types/Image";

const API_BASE_URL = process.env.REACT_APP_PATH_TO_API;

// todo: refactor this API file to a Faces.ts folder?

export interface GetDuplicateImagesResponseType {
    duplicates: DuplicateImageGroup[];
    hasMore: boolean;
}

const handleApiError = (error: AxiosError, context: string) => {
    if (error.response) {
        console.log(`${context} - Server responded with error:`, {
            status: error.response.status,
            data: error.response.data
        });
    } else if (error.request) {
        console.log(`${context} - No response received:`, error.request);
    } else {
        console.log(`${context} - Error setting up request:`, error.message);
    }
    throw new Error(`Failed to ${context.toLowerCase()}. Please try again later.`);
};

export const fetchDuplicates = async (page: number, limit: number): Promise<GetDuplicateImagesResponseType> => {
    console.log('Fetching duplicates:', { page, limit });

    try {
        const response = await axios.get(`${API_BASE_URL}/duplicates`, {
            params: {
                page: page.toString(),
                limit: limit.toString(),
            },
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        });

        console.log(response.data);

        console.log('Duplicates fetched successfully', {
            count: response.data.duplicates?.length
        });

        let duplicates: GetDuplicateImagesResponseType = response.data;
        return duplicates;
    } catch (error) {
        handleApiError(error as AxiosError, 'Fetch duplicate images');
        throw error;
    }
};

export const fetchDuplicatesInImages = async (images: string[]): Promise<GetDuplicateImagesResponseType> => {
    console.log('Fetching duplicates in images:', { imageCount: images.length });

    try {
        const response = await axios.post(
            `${API_BASE_URL}/duplicates`,
            { image_keys: images },
            {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );
        console.log(response.data);

        console.log('Duplicates in images fetched successfully', {
            count: response.data.duplicates?.length
        });

        let duplicates: GetDuplicateImagesResponseType = response.data;
        return duplicates;
    } catch (error) {
        handleApiError(error as AxiosError, 'Fetch duplicate images');
        throw error;
    }
};

export const mergeDuplicatesInImage = async (duplicateGroup: DuplicateImageGroup) => {
    console.log('Merging duplicates for image:', {
        mainImageKey: duplicateGroup.key,
        duplicateCount: duplicateGroup.images.length
    });

    try {
        const response = await axios.patch(
            `${API_BASE_URL}/duplicates`,
            { keep_image_key: duplicateGroup.key },
            {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );

        console.log('Duplicates merged successfully', {
            mainImageKey: duplicateGroup.key
        });

        return response.data;
    } catch (error) {
        handleApiError(error as AxiosError, 'Merge duplicates');
    }
};