import React, { useState } from 'react';
import {Blockquote, Divider, Title, Flex} from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { PersonBetter } from "../../types/Person";
import { Face } from "../../types/Face";
import { FacesForPersonModal } from "./FacesForPersonModal";
import { FaceGrid } from "./FaceGrid";
import { PeopleCarousel } from "./PeopleCarousel";
import {createPerson} from "../../api/People";
import {AddFacesModal} from "./AddFacesToFamilyTreeModal"

export const IdentifyFaces = () => {
    const [namedPeople, setNamedPeople] = useState<PersonBetter[]>([]);
    const [selectedPerson, setSelectedPerson] = useState<PersonBetter | null>(null);

    const [newPerson, setNewPerson] = useState<PersonBetter | null>(null);
    const [isAddNewPersonOpen, setIsAddNewPersonOpen] = useState(false);
    // const [isCreatingPerson, setIsCreatingPerson] = useState(false);

    const onNewPersonNeeded = async (face: Face, name: string) => {
        setIsAddNewPersonOpen(true);

        try {
            const p: PersonBetter = await createPerson(name, face.face_key);
            console.log('added new person:', p);
            setNewPerson(p);
            return p; // and success
        } catch (error) {
            console.error('Failed to create person:', error);
            setIsAddNewPersonOpen(false);
            return null;
            // todo: return some error?
        }

    };

    const handleCloseAddPersonModal = () => {
        setIsAddNewPersonOpen(false);
        if (newPerson) {
            setNamedPeople((prev) => [...prev, newPerson]);
        }
        setNewPerson(null);
    };

    return (
        <Flex direction='column'>
            <Title order={1}>Identify Faces</Title>
            <Divider mt='xs' />
            <Blockquote color='blue' icon={<IconInfoCircle />} mt='lg' mb='lg'>
                Identifying people in photos is crucial as it helps organize images accurately, enabling easy retrieval and browsing. Moreover, it enhances the AI's ability to predict ages, facilitating the estimation of when the photos were taken based on known birthdates.
            </Blockquote>

            <PeopleCarousel namedPeople={namedPeople} onPersonClick={setSelectedPerson} />

            <FaceGrid onNewPersonNeeded={onNewPersonNeeded} />

            {selectedPerson && <FacesForPersonModal person={selectedPerson} onClose={() => setSelectedPerson(null)} />}

            {isAddNewPersonOpen && (
                <AddFacesModal
                    person={newPerson}
                    onClose={handleCloseAddPersonModal}
                />
            )}
        </Flex>
    );
};
