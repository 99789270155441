import React from 'react';
import { Card, Flex, Image, Paper, Box } from '@mantine/core';
import { Face } from '../../types/Face';
import { FaceCardFooter } from './FaceCardFooter';
import ImageWithSkeleton from "../ImageWithSkeleton";

interface FaceCardProps {
    face: Face;
    isHovered: boolean;
    isEditing: boolean;
    onHover: (faceKey: string) => void;
    onLeave: () => void;
    onAction: (action: 'confirm' | 'deny' | 'untrack') => void;
    onEdit: () => void;
    autocompleteProps: {
        searchValue: string;
        suggestions: string[];
        onSearchChange: (value: string) => void;
        onOptionSubmit: (value: string) => void;
        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    };
}

export const FaceCard: React.FC<FaceCardProps> = ({
                                                      face,
                                                      isHovered,
                                                      isEditing,
                                                      onHover,
                                                      onLeave,
                                                      onAction,
                                                      onEdit,
                                                      autocompleteProps
                                                  }) => {
    return (
        <div style={{
            position: 'relative',
            width: 'calc((100% - 17px) / 6)',
            cursor: 'pointer',
            margin: '1px'
        }}>
            <Card
                p={0}
                w="100%"
                style={{ aspectRatio: '4 / 5' }}
                onMouseEnter={() => onHover(face.face_key)}
                onMouseLeave={onLeave}
            >
                <Flex direction="column" h="100%">
                    <Box style={{ flexBasis: '80%', position: 'relative' }}>
                        <ImageWithSkeleton
                            src={face.face_url}
                            // height="100%"
                            // width="100%"
                            // fit="cover"
                         alt={"face profile image"}
                        onClick={()=>{}}/>
                    </Box>
                    <Paper
                        style={{
                            flexBasis: '20%',
                            width: '100%',
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0
                        }}
                        bg={face.is_person?.confirmed ? "darkgrey" : "lightgray"}
                    >
                        <FaceCardFooter
                            face={face}
                            isHovered={isHovered}
                            isEditing={isEditing}
                            onAction={onAction}
                            onEdit={onEdit}
                            autocompleteProps={autocompleteProps}
                        />
                    </Paper>
                </Flex>
            </Card>
        </div>
    );
};