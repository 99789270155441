import React from "react";
import {Flex, Paper, Skeleton, Text} from "@mantine/core";
import {Carousel} from "@mantine/carousel";

export const PeopleCarouselSkeleton: React.FC = () => (
    <>
        {/*<Text size="xl" fw={500} mt="sm">Named Faces:</Text>*/}
        <Paper
            shadow="sm"
            radius="md"
            withBorder
            p="md"
            mt="md"
            style={{backgroundColor: '#f0f0f0'}}
        >
            <Carousel
                slideSize={{base: '50%', sm: '33.333333%', md: '25%', lg: '20%'}}
                slideGap="md"
                align="start"
                slidesToScroll={1}
                withControls
                loop
            >
                {Array(5).fill(0).map((_, index) => (
                    <Carousel.Slide key={index}>
                        <Flex
                            direction="column"
                            align="center"
                            p="10px"
                            style={{
                                cursor: 'pointer',
                                width: '100%'
                            }}
                        >
                            <Skeleton
                                circle
                                height={120}
                                width={120}
                                mb="md"
                            />
                            <Skeleton
                                height={20}
                                width="70%"
                            />
                        </Flex>
                    </Carousel.Slide>))}
            </Carousel>
        </Paper>
    </>
);