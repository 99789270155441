import axios from 'axios';
import {ImageData} from '../types/Image'

const API_BASE_URL = process.env.REACT_APP_PATH_TO_API;

/**
 * Fetches gallery images with pagination and filtering
 */

export const fetchImages = async (includeGrouped: boolean = true, includeUngrouped: boolean = true): Promise<{images: ImageData[]}> => {
    if (!includeGrouped && !includeUngrouped) {
        throw new Error('either includeGrouped or includeUngrouped must be true');
    }

    try {
        const response = await axios.get(`${API_BASE_URL}/gallery`, {
            params: {
                includeGrouped: includeGrouped.toString(),
                includeUngrouped: includeUngrouped.toString()
            },
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        });

        console.log(response.data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 400) {
            throw new Error(error.response.data.error || 'Invalid parameters provided');
        }
        console.error('Failed to fetch gallery images:', error);
        throw new Error('Failed to load images. Please try again later.');
    }
};


export const deleteImages = async (imageKeys: string | string[]): Promise<{ deletedImages: string[]}> => {
    const keys = Array.isArray(imageKeys) ? imageKeys : [imageKeys];

    try {
        const response = await axios.delete(`${API_BASE_URL}/images`, {
            data: { imageKeys: keys },
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        });

        console.log('Delete response:', response.data);

        return {
            deletedImages: response.data.deleted_images || [],
        };

    } catch (error) {
        console.error('Failed to delete images:', error);
        return { deletedImages: [] }; // Graceful handling with empty return
    }
};


export const resetImageMetadata = async (imageKeys: string | string[]): Promise<{ resetImages: string[]}> => {
    const keys = Array.isArray(imageKeys) ? imageKeys : [imageKeys];

    try {
        const response = await axios.patch(`${API_BASE_URL}/images`, {
            // todo: add an action: reset param?
            imageKeys: keys
        }, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        });

        console.log('Metadata reset response:', response.data);
        return {
            resetImages: response.data.images_reset || [],
        };
        // return response.data;

    } catch (error) {
        console.error('Failed to reset image metadata:', error);
        return { resetImages: [] }; // Gracefully handle errors
    }
};


interface DownloadResponse {
    urls?: { url: string; filename: string }[];
    zipUrl?: string;
    message?: string;
    error?: string;
}

export const downloadImages = async (imageKeys: string | string[]): Promise<DownloadResponse> => {
    const keys = Array.isArray(imageKeys) ? imageKeys : [imageKeys];

    try {
        const response = await axios.post<DownloadResponse>(`${API_BASE_URL}/images/download`, {
            imageKeys: keys
        }, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        });

        console.log(response);

        // Validate response format
        if (response.data.error) {
            throw new Error(response.data.error);
        }

        if (!response.data.urls && !response.data.zipUrl) {
            throw new Error('Invalid response format from server');
        }

        console.log('Download response:', response.data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 400) {
            throw new Error(error.response.data.error || 'Invalid image IDs provided');
        }
        console.error('Failed to initiate download:', error);
        throw new Error('Failed to prepare download. Please try again later.');
    }
};


// todo: what is the promise type here?
export const fetchImageDetails = async (keys: string | string[]) => {
    let imageKeys = Array.isArray(keys) ? keys : [keys];

    try {
        const response = await axios.post(`${API_BASE_URL}/images`, {
            imageKeys: imageKeys
        }, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        });

        // Validate response format
        if (response.data.error) {
            throw new Error(response.data.error);
        }

        console.log(response.data)

        // console.log('Download response:', response.data);
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 400) {
            throw new Error(error.response.data.error || 'Invalid image IDs provided');
        }
        console.error('Failed to getImageDetails:', error);
        throw new Error('Failed to get image details. Please try again later.');
    }
};



// todo: fetchImageDetails this will accept an image: ImageData, and promise and ImageData
//  image data & query extra data like the full size image, captions, events, faces, etc.

// todo: duplicatesAPI here?

// todo: fetch & merge duplicates.