// AppRoutes.tsx
import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { AuthenticationTitle, AuthStateOptions } from './components/authentication/Authenticate';
import { AuthCallback } from './components/authentication/AuthCallback';
import { ProtectedRoute } from './ProtectedRoute';
import { AuthenticatedLayout } from './AuthenticatedLayout';
import { useAuth } from './UseAuthHook';
import {LandingPage} from './landing/LandingPage'
export function AppRoutes() {
    const { handleLogin } = useAuth();
    const navigate = useNavigate();

    return (
        <Routes>
            <Route
                path="/"
                element={<LandingPage/>}
            />
            <Route
                path="/login"
                element={<AuthenticationTitle onLoginSuccess={() => { handleLogin(); navigate('/gallery'); }} initialAuthState={AuthStateOptions.Login} />}
            />
            <Route
                path="/signup"
                element={<AuthenticationTitle onLoginSuccess={() => { handleLogin(); navigate('/gallery'); }} initialAuthState={AuthStateOptions.Register} />}
            />
            <Route
                path="/waitlist"
                element={<AuthenticationTitle onLoginSuccess={() => { handleLogin(); navigate('/gallery'); }} initialAuthState={AuthStateOptions.JoinWaitlist} />}
            />
            <Route
                path="/login/callback"
                element={<AuthCallback onSuccessfulSignIn={handleLogin} />}
            />
            <Route
                path="/*"
                element={<ProtectedRoute><AuthenticatedLayout /></ProtectedRoute>}
            />
        </Routes>
    );
}
