import React, { useState } from 'react';
import {Fieldset, Group, TextInput, FocusTrap, TagsInput, Text, Flex, Paper, Button} from '@mantine/core';
import { MonthPickerInput, DatePickerInput } from '@mantine/dates';
import { DateFormatControl } from '../sortImages/DateFormatToggle';
import { ImageData } from '../../types/Image';
import { EventData, DateFormat } from '../../types/Event';
import ImageWithSkeleton from '../ImageWithSkeleton';

interface EventDetailsFormProps {
    eventData: EventData;
    onEventUpdate: (event: EventData) => void;
}

export const EventDetailsForm: React.FC<EventDetailsFormProps> = ({
                                                                eventData,
                                                                onEventUpdate
                                                            }) => {
    const [images] = useState<ImageData[]>(eventData.images);
    const [date, setDate] = useState<Date | null>(eventData.date ? new Date(eventData.date) : null);
    const [format, setFormat] = useState<DateFormat>(eventData.dateFormat || DateFormat.DAY);
    const [description, setDescription] = useState<string>(eventData.title || '');
    const [location, setLocation] = useState<string>(eventData.location || '');
    const [tags, setTags] = useState<string[]>(eventData.keywords || []);

    const handleUpdateEvent = () => {
        const updatedEvent: EventData = {
            key: eventData?.key || 'temp-key',
            images: eventData.images,
            title: description || undefined,
            location: location || undefined,
            date: date || undefined,
            dateFormat: format || undefined,
            keywords: tags.length > 0 ? tags : undefined,
        };
        onEventUpdate(updatedEvent);
    };

    return <>
        <Paper shadow="sm" radius="lg" withBorder p="md" style={{ backgroundColor: '#f0f0f0' }}>
            <Text fw={500}>Images Included in Event:</Text>
            <Flex
                mt="xs"
                direction="row"
                wrap="wrap"
                gap={{ base: '3px', sm: '3px' }}
                justify={images.length < 4 ? 'center' : 'flex-start'}
            >
                {images.slice(0, 3).map(image => <div
                        key={image.key}
                        style={{
                            position: 'relative',
                            width: 'calc((100% - 9px) / 4)',
                            aspectRatio: 1 / 1
                        }}
                    >
                        <ImageWithSkeleton
                            src={image.thumbnail_url || ''}
                            alt="Thumbnail"
                            onClick={() => {}}
                        />
                    </div>)}
                {images.length > 3 && <div
                        style={{
                            position: 'relative',
                            width: 'calc((100% - 9px) / 4)',
                            aspectRatio: 1 / 1
                        }}
                    >
                        <ImageWithSkeleton
                            src={images[3]?.thumbnail_url || ''}
                            alt="Thumbnail"
                            onClick={() => {}}
                        />
                        {images.length > 4 && (
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'white',
                                zIndex: 10
                            }}>
                                +{images.length - 4}
                            </div>
                        )}
                    </div>}
            </Flex>
        </Paper>
        <Fieldset mt="md" legend="Add Event Details">
            <FocusTrap>
                <>
                    <Text fw={500}>Date:</Text>
                    <Group gap="sm" grow={false}>
                        {format === DateFormat.MONTH ? <MonthPickerInput
                                size="md"
                                placeholder="Pick month"
                                value={date}
                                onChange={(newDate) => {
                                    setDate(newDate);
                                }}
                                w="100%"
                                style={{ flex: 1 }}
                            /> : <DatePickerInput
                                size="md"
                                placeholder="Pick date"
                                value={date}
                                onChange={(newDate) => {
                                    setDate(newDate);
                                }}
                                w="100%"
                                style={{ flex: 1 }}
                            />}
                        <DateFormatControl
                            dateFormat={format}
                            onDateFormatChange={(newFormat) => {
                                setFormat(newFormat);
                            }}
                        />
                    </Group>

                    <TextInput
                        mt="md"
                        size="md"
                        // leftSection={isPredicted && <IconSparkles size={18} />}
                        label="Description:"
                        value={description}
                        onChange={(e) => {
                            setDescription(e.currentTarget.value);
                        }}
                        // styles={{ input: isPredicted ? { backgroundColor: '#e0f7fa', fontStyle: 'oblique' } : undefined }}
                    />

                    <TextInput
                        size="md"
                        mt="md"
                        // leftSection={isPredicted && <IconSparkles size={18} />}
                        label="Location:"
                        value={location}
                        onChange={(e) => {
                            setLocation(e.currentTarget.value);
                        }}
                        // styles={{ input: isPredicted ? { backgroundColor: '#e0f7fa', fontStyle: 'oblique' } : undefined }}
                    />

                    <TagsInput
                        size="md"
                        mt="md"
                        label="Keyword Tags:"
                        placeholder="Enter tag"
                        value={tags}
                        onChange={(newTags) => {
                            setTags(newTags);
                        }}
                        clearable
                    />
                </>
            </FocusTrap>
        </Fieldset>
        <Button
            variant="filled"
            mt="xl"
            fullWidth
            onClick={() => {
                handleUpdateEvent();
            }}
        >
            Save Event
        </Button>
    </>;
};