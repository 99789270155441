import React, { useState, useEffect } from 'react';
import {Button, Checkbox, createTheme, Divider, Flex, Group, MantineProvider, Paper, Text, Title, Skeleton} from '@mantine/core';
import { ImageData } from '../../types/Image';
import ImageWithSkeleton from '../ImageWithSkeleton';
import { useIntersection } from '@mantine/hooks';

interface UnsortedImageGroupProps {
    images: ImageData[];
    onImageSelect: (images: string | string[]) => void;
    onImageDeselect: (images: string | string[]) => void;
    onImageClick: (image: ImageData) => void;
    onVisibilityChange?: (isVisible: boolean) => void;
}

export const UnsortedImageGroup: React.FC<UnsortedImageGroupProps> = ({
                                                                          images,
                                                                          onImageSelect,
                                                                          onImageDeselect,
                                                                          onImageClick,
                                                                          onVisibilityChange
                                                                      }) => {
    const [selectedImages, setSelectedImages] = useState<Set<string>>(new Set());
    const [hoveredImage, setHoveredImage] = useState<string | null>(null);

    // Set up intersection observer with threshold and rootMargin
    const { ref: inViewRef, entry } = useIntersection({
        root: null,
        threshold: 0.1,
        rootMargin: '150%' // Pre-load when getting close to viewport
    });

    // Report visibility changes to parent component
    useEffect(() => {
        if (onVisibilityChange && entry) {
            onVisibilityChange(entry.isIntersecting);
        }
    }, [entry, onVisibilityChange]);

    const inSelectionMode = () => selectedImages.size >= 1;

    const allImagesSelected = () => images.every(img => selectedImages.has(img.key));

    const handleSelectGroup = () => {
        if (allImagesSelected()) {
            const deselectKeys = Array.from(selectedImages);
            onImageDeselect(deselectKeys);
            setSelectedImages(new Set());
        } else {
            const selectKeys = images.map(img => img.key).filter(key => !selectedImages.has(key));
            onImageSelect(selectKeys);
            setSelectedImages(new Set([...Array.from(selectedImages), ...selectKeys]));
        }
    };

    const toggleImageSelection = (image: ImageData, event?: React.MouseEvent) => {
        event?.stopPropagation();
        if (selectedImages.has(image.key)) {
            const newSelection = new Set(selectedImages);
            newSelection.delete(image.key);
            setSelectedImages(newSelection);
            onImageDeselect(image.key);
        } else {
            setSelectedImages(new Set(selectedImages).add(image.key));
            onImageSelect(image.key);
        }
    };

    const theme = createTheme({ cursorType: 'pointer' });

    return (
        <Paper mb="md" p="md" ref={inViewRef}>
            <Group justify="space-between">
                <div>
                    <Title order={2}>{"Unsorted Images"}</Title>
                    <Text c="dimmed" fs="italic">{'Unknown Date'}</Text>
                </div>
                <Group gap="xs">
                    <Button
                        variant={allImagesSelected() ? "filled" : "light"}
                        radius="md"
                        onClick={handleSelectGroup}
                    >
                        {allImagesSelected() ? 'Deselect' : 'Select'}
                    </Button>
                </Group>
            </Group>
            <Divider mb="sm" />
            <Flex mt="md" direction="row" wrap="wrap" gap={{ base: '3px', sm: '3px' }} justify={{ sm: '3px' }}>
                {images.map((image) => (
                    <div
                        key={`${image.key}`}
                        onMouseEnter={() => setHoveredImage(image.key)}
                        onMouseLeave={() => setHoveredImage(null)}
                        style={{
                            position: 'relative',
                            width: 'calc((100% - 20px) / 6)',
                            aspectRatio: '1 / 1'
                        }}
                    >
                        {(inSelectionMode() || hoveredImage === image.key || selectedImages.has(image.key)) && (
                            <div
                                onClick={(e) => toggleImageSelection(image, e)}
                                style={{
                                    position: 'absolute',
                                    top: 5,
                                    left: 5,
                                    zIndex: 1,
                                    width: '24px',
                                    height: '24px'
                                }}
                            >
                                <MantineProvider theme={theme}>
                                    <Checkbox
                                        color="blue"
                                        radius="xl"
                                        checked={selectedImages.has(image.key)}
                                        readOnly
                                    />
                                </MantineProvider>
                            </div>
                        )}
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                                border: selectedImages.has(image.key) ? '3px solid #228be6' : 'none',
                                overflow: 'hidden'
                            }}
                        >
                            {image.thumbnail_url ? (
                                <ImageWithSkeleton
                                    src={image.thumbnail_url}
                                    alt={`Photo ${image.datetime ? `taken on ${image.datetime}` : 'with unknown date'}`}
                                    onClick={() => {
                                        inSelectionMode() ? toggleImageSelection(image) : onImageClick(image)
                                    }}
                                />
                            ) : (
                                <Skeleton width='100%' height="100%" />
                            )}
                        </div>
                    </div>
                ))}
            </Flex>
        </Paper>
    );
};