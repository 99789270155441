import React, { useState, useEffect } from 'react';
import {Carousel} from '@mantine/carousel';
import { Avatar, Flex, Paper, Text } from '@mantine/core';
import { PersonBetter } from "../../types/Person";
import { PeopleCarouselSkeleton } from './PeopleCarouselSkeleton';
import {fetchPeople} from "../../api/People";

interface PeopleCarouselProps {
    namedPeople: PersonBetter[];
    onPersonClick: (person: PersonBetter) => void;
}

export const PeopleCarousel: React.FC<PeopleCarouselProps> = ({ namedPeople, onPersonClick }) => {
    const [people, setPeople] = useState<PersonBetter[]>(namedPeople);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadPeople = async () => {
            try {
                const fetchedPeople = await fetchPeople();
                setPeople(fetchedPeople);
            } catch (error) {
                console.error('Failed to load people for carousel:', error);
            } finally {
                setLoading(false);
            }
        };

        loadPeople();
    }, [namedPeople]);

    if (loading) {
        return <PeopleCarouselSkeleton />;
    }

    if (people.length === 0) {
        return null;
    }

    return (
        <>
            {/*<Text size="xl" fw={500} mt="sm">Named Faces:</Text>*/}
            <Paper shadow="sm" radius="md" withBorder p="md" mt="md" style={{ backgroundColor: '#f0f0f0' }}>
                <Carousel slideSize="20%" slideGap="md" align="start" withControls loop>
                    {people.map((person) => (
                        <Carousel.Slide key={person.seed_key}>
                            <Flex direction="column" align="center" p="10px" onClick={() => onPersonClick(person)}>
                                <Avatar src={person.profile_url} size={120} radius="50%" mb="md" />
                                <Text size="md" fw={700}>{`${person.first} ${person.last}`}</Text>
                            </Flex>
                        </Carousel.Slide>
                    ))}
                </Carousel>
            </Paper>
        </>
    );
};