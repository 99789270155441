import React, { useEffect, useState } from 'react';
import {Fieldset, Group, TextInput, Button, FocusTrap, TagsInput, Text, Flex, Paper} from '@mantine/core';
import { MonthPickerInput, DatePickerInput } from '@mantine/dates';
import { IconSparkles } from '@tabler/icons-react';
import { DateFormatControl } from './DateFormatToggle';
import { ImageData } from '../../types/Image';
import { EventData, DateFormat } from '../../types/Event';
import { predictEventForImages } from '../../api/Event';
import  ImageWithSkeleton from '../ImageWithSkeleton';

interface EventDetailsFormProps {
    render: boolean; // right now this is what's triggering a re-prediction.
    selectedImages: ImageData[];
    eventData: EventData | null;
    setEventData: (event: EventData) => void;
    setIsReady: (isReady: boolean) => void;
}

// todo: do i need an isReady?
export const EventDetailsForm: React.FC<EventDetailsFormProps> = ({ render, selectedImages, eventData, setEventData, setIsReady }) => {
    const [isPredicting, setIsPredicting] = useState(true);
    const [aiEventPrediction, setAiEventPrediction] = useState<EventData | null>(null);

    const [date, setDate] = useState<Date | null>(null);
    const [format, setFormat] = useState<DateFormat>(DateFormat.DAY);
    const [isDateAI, setIsDateAI] = useState<boolean>(false);

    const [description, setDescription] = useState<string>('');
    const [isDescriptionAI, setIsDescriptionAI] = useState<boolean>(false);

    const [location, setLocation] = useState<string>('');
    const [isLocationAI, setIsLocationAI] = useState<boolean>(false);

    const [tags, setTags] = useState<string[]>([]);

    useEffect(() => {
        setIsReady(!isPredicting);
    }, [isPredicting]);

    useEffect(() => {
        const predict = async () => {
            try {
                const prediction: EventData = await predictEventForImages(selectedImages);
                console.log(prediction);
                setEventData(prediction);

                setAiEventPrediction(prediction); // saves last prediction

                if (prediction.date) {
                    setDate(new Date(prediction.date));
                    setIsDateAI(true);
                }
                if (prediction.dateFormat) {
                    setFormat(prediction.dateFormat);
                }
                if (prediction.title) {
                    setDescription(prediction.title);
                    setIsDescriptionAI(true);
                }
                if (prediction.location) {
                    setLocation(prediction.location);
                    setIsLocationAI(true);
                }
                if (prediction.keywords) {
                    setTags(prediction.keywords);
                }
            } catch (error) {
                console.error('Failed to predict event details:', error);
            } finally {
                setIsPredicting(false);
            }
        };
        setIsPredicting(true);
        predict();
    }, [selectedImages]);

    const handleUpdateEvent = () => {
        if (aiEventPrediction != null) {
            console.log(aiEventPrediction);
            const updatedEvent: EventData = {
                key: aiEventPrediction.key,
                images: selectedImages,
                title: description || undefined,
                location: location || undefined,
                date: date || undefined,
                dateFormat: format || undefined,
                keywords: tags.length > 0 ? tags : undefined,
            };
            console.log("handling update for event ", updatedEvent);
            setEventData(updatedEvent);
        } else {
            console.log("no prediction - event key missing!!!")
            // throw an error, there should be a prediction somewhere - this is where the key comes from!
        }
    };

    const onDateChange = (newDate: Date | null) => {
        setDate(newDate);
        setIsDateAI(false);
        handleUpdateEvent();
    };

    const onDateFormatChange = (newFormat: DateFormat) => {
        setFormat(newFormat);
    };

    const onDescriptionChange = (value: string) => {
        setDescription(value);
        setIsDescriptionAI(false);
        handleUpdateEvent();
    };

    const onLocationChange = (value: string) => {
        setLocation(value);
        setIsLocationAI(false);
        handleUpdateEvent();
    };

    const onTagsChange = (newTags: string[]) => {
        setTags(newTags);
        handleUpdateEvent();
    };

    if (!render) {
        return <></>
    }

    return (
        <>
        <Paper shadow="sm" radius="lg" withBorder p="md" style={{ backgroundColor: '#f0f0f0' }}>
            <Text fw={500}>{"Images Included in Event:"}</Text>
            <Flex
                mt="xs"
                direction="row"
                wrap="wrap"
                gap={{ base: '3px', sm: '3px' }}
                justify={selectedImages.length < 4 ? 'center' : 'flex-start'}
            >
                {selectedImages.slice(0, 3).map(image => (
                    <div
                        key={image.key}
                        style={{
                            position: 'relative',
                            width: 'calc((100% - 9px) / 4)',
                            aspectRatio: 1 / 1
                        }}
                    >
                        <ImageWithSkeleton
                            src={image.thumbnail_url || ''}
                            alt="Thumbnail"
                            onClick={() => {}}
                        />
                    </div>
                ))}
                {selectedImages.length > 3 && (
                    <div
                        style={{
                            position: 'relative',
                            width: 'calc((100% - 9px) / 4)',
                            aspectRatio: 1 / 1
                        }}
                    >
                        <ImageWithSkeleton
                            src={selectedImages[3]?.thumbnail_url || ''}
                            alt="Thumbnail"
                            onClick={() => {}}
                        />
                        {selectedImages.length > 4 && (
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'white',
                                zIndex: 10
                            }}>
                                +{selectedImages.length - 4}
                            </div>
                        )}
                    </div>
                )}
            </Flex>
        </Paper>
        <Fieldset mt="md" legend="Add Event Details">
            <FocusTrap>
                <>
                    <Text fw={500}>Date:</Text>
                    <Group gap="sm" grow={false}>
                        {format === DateFormat.MONTH ? (
                            <MonthPickerInput
                                size="md"
                                placeholder="Pick month"
                                value={date}
                                onChange={onDateChange}
                                styles={{ input: isDateAI ? { backgroundColor: '#e0f7fa', fontStyle: 'oblique' } : undefined }}
                                w="100%"
                                style={{ flex: 1 }}
                            />
                        ) : (
                            <DatePickerInput
                                size="md"
                                placeholder="Pick date"
                                value={date}
                                onChange={onDateChange}
                                styles={{ input: isDateAI ? { backgroundColor: '#e0f7fa', fontStyle: 'oblique' } : undefined }}
                                w="100%"
                                style={{ flex: 1 }}
                            />
                        )}
                        <DateFormatControl dateFormat={format} onDateFormatChange={onDateFormatChange} />
                    </Group>

                    <TextInput
                        mt="md"
                        size="md"
                        leftSection={isDescriptionAI && <IconSparkles size={18} />}
                        label="Description:"
                        value={description}
                        onChange={(e) => onDescriptionChange(e.currentTarget.value)}
                        styles={{ input: isDescriptionAI ? { backgroundColor: '#e0f7fa', fontStyle: 'oblique' } : undefined }}
                    />

                    <TextInput
                        size="md"
                        mt="md"
                        leftSection={isLocationAI && <IconSparkles size={18} />}
                        label="Location:"
                        value={location}
                        onChange={(e) => onLocationChange(e.currentTarget.value)}
                        styles={{ input: isLocationAI ? { backgroundColor: '#e0f7fa', fontStyle: 'oblique' } : undefined }}
                    />

                    <TagsInput size="md" mt="md" label="Keyword Tags:" placeholder="Enter tag" value={tags} onChange={onTagsChange} clearable />
                </>
            </FocusTrap>
        </Fieldset>
        </>
    );
};
