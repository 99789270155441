import React, { useEffect, useState, KeyboardEvent } from 'react';
import { Text, Flex, Image, TextInput, Fieldset, Paper } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import '@mantine/dates/styles.css';
import { PersonBetter } from '../../types/Person';
import { updatePerson } from '../../api/People';

interface NewPeopleModalProps {
    render: boolean;
    newPeople: PersonBetter[];
    onAllNewPeopleDescribed: (allDescribed: boolean) => void;
}

export const NewPeopleModal: React.FC<NewPeopleModalProps> = ({
                                                                  render,
                                                                  newPeople,
                                                                  onAllNewPeopleDescribed
                                                              }) => {
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [localNames, setLocalNames] = useState<{ [key: string]: { first: string; last: string } }>({});
    const [localDates, setLocalDates] = useState<{ [key: string]: Date | null }>({});

    useEffect(() => {
        const initialData = newPeople.reduce((acc, person) => ({
            names: {
                ...acc.names,
                [person.seed_key]: { first: person.first || '', last: person.last || '' },
            },
            dates: {
                ...acc.dates,
                [person.seed_key]: person.dob || null,
            }
        }), { names: {}, dates: {} });

        setLocalNames(initialData.names);
        setLocalDates(initialData.dates);
        checkAllPeopleDescribed();
    }, [newPeople]); // todo: this ESLint warning: ESLint: React Hook useEffect has a missing dependency: 'checkAllPeopleDescribed'. Either include it or remove the dependency array. (react-hooks/exhaustive-deps)

    const checkAllPeopleDescribed = () => {
        console.log("checkingAllPeopleDescribed");
        let allDescribed = newPeople.every(person => {
            let names = localNames[person.seed_key];
            let date = localDates[person.seed_key];
            return names?.first !== '' && names?.last !== '' && date != null;
        });
        onAllNewPeopleDescribed(allDescribed);
    };

    useEffect(() => {
        checkAllPeopleDescribed();
    }, [localNames, localDates]); // todo: this warning: ESLint: React Hook useEffect has a missing dependency: 'checkAllPeopleDescribed'. Either include it or remove the dependency array. (react-hooks/exhaustive-deps)

    const handleNameInput = (value: string, field: 'first' | 'last', person: PersonBetter) => {
        setLocalNames(prev => ({
            ...prev,
            [person.seed_key]: {
                ...prev[person.seed_key],
                [field]: value
            }
        }));
    };

    const handleNameBlur = async (field: 'first' | 'last', person: PersonBetter) => {
        const newValue = localNames[person.seed_key][field];
        if (newValue === person[field]) return;

        const previousValue = person[field];

        // Optimistic update
        person[field] = newValue;
        setErrors(prev => ({ ...prev, [person.seed_key]: '' }));

        try {
            await updatePerson({
                seed_key: person.seed_key,
                first: person.first,
                last: person.last,
                dob: person.dob
            });
        } catch (error) {
            // Rollback on failure
            person[field] = previousValue;
            setLocalNames(prev => ({
                ...prev,
                [person.seed_key]: {
                    ...prev[person.seed_key],
                    [field]: previousValue
                }
            }));
            setErrors(prev => ({ ...prev, [person.seed_key]: `Failed to update ${field}` }));
        }
    };

    const handleDateChange = (date: Date | null, person: PersonBetter) => {
        setLocalDates(prev => ({
            ...prev,
            [person.seed_key]: date,
        }));
    };

    const handleDateBlur = async (person: PersonBetter) => {
        const date = localDates[person.seed_key];

        if (!date || isNaN(date.getTime()) || date > new Date()) {
            setErrors(prev => ({ ...prev, [person.seed_key]: 'Please enter a valid date in the past' }));
            return;
        }

        const previousDate = person.dob;

        // Optimistic update
        person.dob = date;
        setErrors(prev => ({ ...prev, [person.seed_key]: '' }));

        try {
            await updatePerson({
                seed_key: person.seed_key,
                first: person.first,
                last: person.last,
                dob: person.dob
            });
        } catch (error) {
            // Rollback on failure
            person.dob = previousDate;
            setLocalDates(prev => ({
                ...prev,
                [person.seed_key]: previousDate || null
            }));
            setErrors(prev => ({ ...prev, [person.seed_key]: 'Failed to update DOB' }));
        }
    };

    const handleKeyDown = (e: KeyboardEvent, field: 'first' | 'last' | 'date', person: PersonBetter) => {
        if (e.key === 'Enter') {
            if (field === 'date') {
                const date = localDates[person.seed_key];
                if (date) {
                    handleDateBlur(person); // todo: warning, missing .then
                }
            } else {
                handleNameBlur(field, person); // todo: warning, missing .then
            }
            (e.target as HTMLElement).blur();
        }
    };

    if (!render) return null;

    return (
        <>
            <Text size="sm" mb="lg">
                Please add information for the new people you've identified:
            </Text>

            {newPeople.map(person => (
                <Paper
                    key={person.seed_key}
                    shadow="sm"
                    radius="lg"
                    withBorder
                    p="md"
                    style={{ backgroundColor: '#f0f0f0', marginBottom: '1rem' }}
                >
                    <Flex gap="md" align="stretch">
                        <div style={{ width: '200px', flexShrink: 0, display: 'flex', alignItems: 'center' }}>
                            <Image
                                src={person.profile_url}
                                fallbackSrc="https://placehold.co/400x400?text=Placeholder"
                                alt="face_thumbnail"
                                style={{
                                    aspectRatio: 1,
                                    cursor: 'pointer',
                                    width: '100%',
                                    height: 'auto',
                                    borderRadius: '45px',
                                    border: '1px solid grey'
                                }}
                            />
                        </div>

                        <Fieldset style={{ flex: 1 }} legend="Key Information">
                            {errors[person.seed_key] && (
                                <Text c="red" size="sm" mb="sm">
                                    {errors[person.seed_key]}
                                </Text>
                            )}

                            <Flex gap="md" mb="md">
                                <TextInput
                                    label="First Name"
                                    placeholder="Enter first name"
                                    value={localNames[person.seed_key]?.first || ''}
                                    onChange={(event) => handleNameInput(event.currentTarget.value, 'first', person)}
                                    onBlur={() => handleNameBlur('first', person)}
                                    onKeyDown={(e) => handleKeyDown(e, 'first', person)}
                                    required
                                    style={{flex: 1}}
                                />
                                <TextInput
                                    label="Last Name"
                                    placeholder="Enter last name"
                                    value={localNames[person.seed_key]?.last || ''}
                                    onChange={(event) => handleNameInput(event.currentTarget.value, 'last', person)}
                                    onBlur={() => handleNameBlur('last', person)}
                                    onKeyDown={(e) => handleKeyDown(e, 'last', person)}
                                    required
                                    style={{flex: 1}}
                                />
                            </Flex>
                            <DateInput
                                valueFormat="MM/DD/YYYY"
                                placeholder="MM/DD/YYYY"
                                label="Date of Birth"
                                value={localDates[person.seed_key]}
                                onChange={(date) => handleDateChange(date, person)}
                                onKeyDown={(e) => handleKeyDown(e, 'date', person)}
                                onBlur={() => handleDateBlur(person)}
                            />
                        </Fieldset>
                    </Flex>
                </Paper>
            ))}
        </>
    );
};