// todo: this could be refactored and used as a util for multiple pages!
import {DateFormat} from "../../types/Event";

/**
 * Formats a date string into a readable format
 */
export const formatDateKey = (dateString: string): string => {
    const date = new Date(dateString);
    const options = {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    } as const;
    return date.toLocaleDateString('en-US', options);
};

export const formatDateString = (date: Date, dateFormat: DateFormat | undefined) => {
    const dateObj = new Date(date);
    switch (dateFormat) {
        case DateFormat.DAY:
            return dateObj.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
        case DateFormat.MONTH:
            return dateObj.toLocaleDateString(undefined, { year: 'numeric', month: 'long' });
        case DateFormat.YEAR:
            return dateObj.getFullYear().toString();
        default:
            return dateObj.toLocaleDateString();
    }
};

// todo: this could be refactored and used as a util for multiple pages!
/**
 * Checks if more content should be loaded based on viewport and scroll position
 */
export const shouldLoadMore = (): boolean => {
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    const scrollY = window.scrollY;

    const contentNotFillsViewport = scrollHeight <= clientHeight;
    const nearBottom = (scrollY + clientHeight >= scrollHeight * 0.8);

    return contentNotFillsViewport || nearBottom;
};