import React, {useState, useCallback, useRef, useEffect, KeyboardEvent} from 'react';
import { Box, Center, Group, Avatar, TextInput, LoadingOverlay } from '@mantine/core';
import { DateInput, DateValue } from '@mantine/dates';
import { PersonBetter } from "../../types/Person";
import { useDebouncedCallback } from 'use-debounce';
import '@mantine/dates/styles.css';
import {updatePerson} from "../../api/People";

interface PersonalInfoPanelProps {
    person: PersonBetter;
    onUpdate: (updatedFields: Partial<PersonBetter>) => Promise<void>;
    isUpdating: boolean;
}

export const PersonalInfoPanel: React.FC<PersonalInfoPanelProps> = React.memo(({
                                                                                   person,
                                                                                   onUpdate,
                                                                                   isUpdating
                                                                               }) => {
    // Local state for form fields
    const [first, setFirst] = useState(person.first || '');
    const [last, setLast] = useState(person.last || '');
    const [dob, setDob] = useState<DateValue>(person.dob || null);

    // Track whether we have pending changes
    const [hasPendingChanges, setHasPendingChanges] = useState(false);

    // Refs to track last saved values
    const lastSavedRef = useRef({
        first: person.first || '',
        last: person.last || '',
        dob: person.dob?.toISOString() || ''
    });

    // Ref to prevent concurrent saves
    const isSavingRef = useRef(false);

    // Function to check if there are unsaved changes
    const hasUnsavedChanges = useCallback(() => {
        const hasChanges = first !== lastSavedRef.current.first ||
            last !== lastSavedRef.current.last ||
            dob?.toISOString() !== lastSavedRef.current.dob;

        return hasChanges;
    }, [first, last, dob]);

    // Actual save function
    const saveChanges = async (updates: Partial<PersonBetter>) => {
        if (isSavingRef.current) {
            return;
        }

        isSavingRef.current = true;
        setHasPendingChanges(false);

        try {
            await onUpdate(updates);
            // Update last saved values
            lastSavedRef.current = {
                ...lastSavedRef.current,
                ...updates,
                dob: dob?.toISOString() || ''
            };
        } catch (error) {
            // Revert to last saved values on error
            setFirst(lastSavedRef.current.first);
            setLast(lastSavedRef.current.last);
            setDob(lastSavedRef.current.dob ? new Date(lastSavedRef.current.dob) : null);
        } finally {
            isSavingRef.current = false;
        }
    };

    // Debounced save function for text inputs
    const debouncedSave = useDebouncedCallback(
        (updates: Partial<PersonBetter>) => {
            if (hasUnsavedChanges()) {
                saveChanges(updates);
            }
        },
        3000,
        { maxWait: 5000 }
    );

    // Handle text input changes
    const handleTextChange = useCallback((field: 'firstName' | 'lastName', value: string) => {
        setHasPendingChanges(true);

        if (field === 'firstName') {
            setFirst(value);
        } else {
            setLast(value);
        }

        debouncedSave({
            [field]: value
        });
    }, [debouncedSave]);

    // Handle text input blur
    const handleBlur = useCallback(() => {
        if (hasPendingChanges) {
            debouncedSave.flush();
        }
    }, [hasPendingChanges, debouncedSave]);

    // // Handle date changes
    const handleDateChange = useCallback((newDate: DateValue) => {
        setDob(newDate);
        setHasPendingChanges(true);
    }, []);
    //
    // // Handle date input blur
    const handleDateBlur = useCallback(() => {
        if (dob?.toISOString() !== lastSavedRef.current.dob) {
            saveChanges({
                dob: dob! || null
            });
        }
    }, [dob]);


    const handleKeyDown = (e: KeyboardEvent, field: 'first' | 'last' | 'date') => {
        if (e.key === 'Enter') {
            if (field === 'date') {
                console.log("hey!")
                // const date = dob;
                if (dob) {
                    handleDateBlur(); // todo: warning, missing .then
                }
            } else {
                // handleNameBlur(field);
            }
            (e.target as HTMLElement).blur();
        }
    };

    // Sync with external changes to person props
    useEffect(() => {
        if (person.first !== lastSavedRef.current.first) {
            setFirst(person.first || '');
            lastSavedRef.current.first = person.first || '';
        }
        if (person.last !== lastSavedRef.current.last) {
            setLast(person.last || '');
            lastSavedRef.current.last = person.last || '';
        }
        if (person.dob?.toISOString() !== lastSavedRef.current.dob) {
            setDob(person.dob! || null);
            lastSavedRef.current.dob = person.dob?.toISOString() || '';
        }
    }, [person.first, person.last, person.dob]);

    return (
        <Box mt={20} p={20} pos="relative">
            {/*<LoadingOverlay visible={isUpdating} />*/}
            <Center mb={30}>
                <Avatar
                    src={person.profile_url}
                    radius="xl"
                    alt={`${person.first} ${person.last} profile photo`}
                    size={180}
                />
            </Center>
            <Group grow align="flex-start">
                <TextInput
                    size="md"
                    label="First Name"
                    placeholder="Enter first name"
                    value={first}
                    onChange={(e) => handleTextChange('firstName', e.target.value)}
                    onBlur={handleBlur}
                    disabled={isUpdating}
                    required
                    onKeyDown={(e) => handleKeyDown(e, 'first')}
                />
                <TextInput
                    size="md"
                    label="Last Name"
                    placeholder="Enter last name"
                    value={last}
                    onChange={(e) => handleTextChange('lastName', e.target.value)}
                    onBlur={handleBlur}
                    disabled={isUpdating}
                    required
                    onKeyDown={(e) => handleKeyDown(e, 'last')}
                />
            </Group>
            <DateInput
                size="md"
                label="Date of Birth"
                placeholder="MM/DD/YYYY"
                value={dob}
                onChange={handleDateChange}
                onBlur={handleDateBlur}
                valueFormat="MM/DD/YYYY"
                disabled={isUpdating}
                required
                mt="md"
                allowDeselect={false}
                clearable={false}
                preserveTime={false}
                maxLength={10}
                onKeyDown={(e) => handleKeyDown(e, 'date')}
            />
        </Box>
    );
});

PersonalInfoPanel.displayName = 'PersonalInfoPanel';

export default PersonalInfoPanel;