import {ImageData} from "./Image";
import {PersonBetter} from "./Person"

export enum DateFormat {
    DAY = 'day',
    MONTH = 'month',
    YEAR = 'year',
}

export interface EventData {
    key: string;
    images: ImageData[];
    title?: string;
    location?: string;
    date?: Date;
    dateFormat?: DateFormat;
    keywords?: string[];
    attendees?: PersonBetter[];
}