import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
    Flex,
    Loader,
    Center,
    Card,
    Box,
    Image,
    Paper,
    Skeleton,
    Space,
    Stack,
    ThemeIcon,
    Text,
    Anchor
} from '@mantine/core';
import { Face } from '../../types/Face';
import { labelFace, untrackFace, removeIsPersonSuggestionForFace, fetchUnnamedFaces } from '../../api/Faces';
import { UseAutocomplete } from './UseAutocomplete';
import { FaceCard } from './FaceCard';
import { FaceGridSkeleton } from './FaceGridSkeleton';
import {PersonBetter} from "../../types/Person";
import {IconFaceId, IconSeeding} from "@tabler/icons-react";
import {Link} from "react-router-dom";

const FACES_PER_PAGE = 42;
const SCROLL_THRESHOLD = 300; // pixels from bottom to trigger load

interface UnnamedFacesGridProps {
    onNewPersonNeeded: (face: Face, suggestedName: string) => Promise<PersonBetter | null>;
}

// todo: this scroll function is bad! dont know what the issue is, this is very gpt generated and id like to replace with the more succinct logic from the gallery or imageSelection grid
export const FaceGrid: React.FC<UnnamedFacesGridProps> = ({onNewPersonNeeded}) => {
    // Face loading state
    const [faces, setFaces] = useState<Face[]>([]);
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    // Refs for preventing race conditions
    const loadingRef = useRef(false);
    const hasMoreRef = useRef(true);
    const initialLoadComplete = useRef(false);
    const lastScrollPosition = useRef(0);
    const skeletonHeight = useRef(0);

    // UI state
    const [hoveredCard, setHoveredCard] = useState<string>("");
    const [editingFace, setEditingFace] = useState<string>("");
    const [showLoadingSkeleton, setShowLoadingSkeleton] = useState(false);

    // Refs for elements
    const skeletonRef = useRef<HTMLDivElement>(null);

    // Autocomplete hook
    const {
        searchValue,
        setSearchValue,
        suggestions,
        updateSuggestions,
        people,
        loading: peopleLoading
    } = UseAutocomplete({});

    const loadMoreFaces = useCallback(async (offset: number) => {
        if (loadingRef.current || !hasMoreRef.current) return;

        loadingRef.current = true;
        setLoading(true);

        try {
            setShowLoadingSkeleton(true);
            const response = await fetchUnnamedFaces(offset, FACES_PER_PAGE);

            if (response.faces.length > 0) {
                setFaces(prev => [...prev, ...response.faces]);
                setHasMore(response.hasMore);
                hasMoreRef.current = response.hasMore;
            } else {
                setHasMore(false);
                hasMoreRef.current = false;
            }
        } catch (error) {
            console.error('Failed to load faces:', error);
            setHasMore(false);
            hasMoreRef.current = false;
        } finally {
            setLoading(false);
            loadingRef.current = false;
            setShowLoadingSkeleton(false);
            setInitialLoading(false);
            initialLoadComplete.current = true;
        }
    }, []);


    // Initial load
    useEffect(() => {
        setFaces([]);
        setOffset(0);
        setHasMore(true);
        hasMoreRef.current = true;
        initialLoadComplete.current = false;
        setInitialLoading(true);
        loadMoreFaces(0);
    }, [loadMoreFaces]);

    // Scroll handler with debounce
    const handleScroll = useCallback(() => {
        if (!loadingRef.current && hasMoreRef.current) {
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const scrollTop = window.scrollY;

            const distanceFromBottom = documentHeight - (scrollTop + windowHeight);

            if (distanceFromBottom < SCROLL_THRESHOLD) {
                const newOffset = faces.length;
                setOffset(newOffset);
                loadMoreFaces(newOffset);
            }
        }
    }, [faces.length, loadMoreFaces]);

    // Add scroll listener
    useEffect(() => {
        const debouncedScroll = debounce(handleScroll, 100);
        window.addEventListener('scroll', debouncedScroll);
        return () => window.removeEventListener('scroll', debouncedScroll);
    }, [handleScroll]);

    // Debounce helper function
    function debounce(func: Function, wait: number) {
        let timeout: NodeJS.Timeout;
        return function executedFunction(...args: any[]) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

    const updateFacesWithNewSuggestion = async (facesToUpdate: string[], person: PersonBetter) => {
        setFaces((prevFaces) =>
            prevFaces.map((face) =>
                facesToUpdate.includes(face.face_key)
                    ? { ...face, is_person: { person, confirmed: false } }
                    : face
            )
        );
    };

    const handleFaceAction = async (face: Face, action: 'confirm' | 'deny' | 'untrack') => {
        console.log(face)
        console.log(`[FaceGrid] Starting ${action} action for face:`, {
            face_key: face.face_key,
            is_person: face.is_person?.person.seed_key,
            action: action
        });

        if (action === 'confirm' || action === 'untrack') {
            // Optimistically remove face from grid
            const updatedFaces = faces.filter(f => f.face_key !== face.face_key);
            console.log('[FaceGrid] Optimistically removed face from grid. Remaining faces:', updatedFaces.length);
            setFaces(updatedFaces);
        }


        try {
            let actionResult;

            switch (action) {
                case 'confirm':
                    if (face.is_person?.person.seed_key) {
                        console.log('[FaceGrid] Confirming face with person:', {
                            face_key: face.face_key,
                            person_key: face.is_person.person.seed_key,
                            person_name: `${face.is_person.person.first} ${face.is_person.person.last}`
                        });
                        let newFaceSuggestions: string[] = await labelFace(face, face.is_person.person);
                        updateFacesWithNewSuggestion(newFaceSuggestions, face.is_person.person);
                        // actionResult = await labelFace(face);
                        // console.log('[FaceGrid] Label face result:', actionResult);
                    } else {
                        console.warn('[FaceGrid] Cannot confirm face - no person suggestion found:', face.face_key);
                    }
                    break;

                case 'deny':
                    console.log('[FaceGrid] Denying person suggestion for face:', {
                        face_key: face.face_key,
                        suggested_person: face.is_person?.person
                    });
                    setFaces((prev) =>
                        prev.map((f) =>
                            f.face_key === face.face_key ? { ...f, is_person: undefined } : f
                        )
                    );
                    actionResult = await removeIsPersonSuggestionForFace(face);
                    console.log('[FaceGrid] Remove suggestion result:', actionResult);
                    break;

                case 'untrack':
                    console.log('[FaceGrid] Untracking face:', {
                        face_key: face.face_key
                    });
                    actionResult = await untrackFace(face);
                    console.log('[FaceGrid] Untrack result:', actionResult);
                    break;
            }

            console.log(`[FaceGrid] Successfully completed ${action} action for face:`, face.face_key);

        } catch (error) {
            // Detailed error logging
            console.error(`[FaceGrid] Error during ${action} action:`, {
                action,
                face_key: face.face_key,
                error: error instanceof Error ? {
                    message: error.message,
                    stack: error.stack
                } : error
            });

            if (action === 'confirm' || action === 'untrack') {
                // Rollback on error
                console.log('[FaceGrid] Rolling back optimistic update...');
                setFaces(prevFaces => {
                    const rolledBackFaces = [...prevFaces, face];
                    console.log('[FaceGrid] Rolled back faces count:', rolledBackFaces.length);
                    return rolledBackFaces;
                });
            }

            // You might want to show an error notification to the user here
        }
    };

    const handleNameSelection = async (face: Face, selectedName: string) => {
        console.log('[FaceGrid] Handling name selection:', {
            face_key: face.face_key,
            selectedName: selectedName
        });

        const optimisticRemoveFace = () => {
            setFaces((prevFaces) => prevFaces.filter((f) => f.face_key !== face.face_key));
        };

        const rollbackFace = () => {
            setFaces((prevFaces) => [...prevFaces, face]);
        };

        try {
            if (selectedName.startsWith('+ Add "')) {
                const newName = selectedName.match(/"([^"]+)"/)?.[1] || '';
                console.log('[FaceGrid] Adding new person:', newName);

                optimisticRemoveFace();

                const newPerson = await onNewPersonNeeded(face, newName);

                if (newPerson) {
                    console.log('[FaceGrid] Face labeled with new person:', newPerson);
                    let newFaceSuggestions: string[] = await labelFace(face, newPerson);
                    updateFacesWithNewSuggestion(newFaceSuggestions, newPerson)
                } else {
                    throw new Error('Failed to create new person');
                }
            } else {
                const selectedPerson = people.find(
                    (p) => `${p.first} ${p.last}` === selectedName
                );

                if (selectedPerson) {
                    console.log('[FaceGrid] Found matching person:', {
                        person_key: selectedPerson.seed_key,
                        name: `${selectedPerson.first} ${selectedPerson.last}`
                    });

                    optimisticRemoveFace();

                    // await labelFace(face, selectedPerson);
                    let newFaceSuggestions: string[] = await labelFace(face, selectedPerson);
                    updateFacesWithNewSuggestion(newFaceSuggestions, selectedPerson)

                    console.log('[FaceGrid] Face labeled with existing person:', selectedPerson);
                } else {
                    console.warn('[FaceGrid] No matching person found for name:', selectedName);
                }
            }
        } catch (error) {
            console.error('[FaceGrid] Error handling name selection:', {
                face_key: face.face_key,
                selectedName,
                error: error instanceof Error ? { message: error.message, stack: error.stack } : error
            });

            rollbackFace();
        } finally {
            console.log('[FaceGrid] Resetting UI state');
            setEditingFace('');
            setSearchValue('');
        }
    };

    if (initialLoading) {
        return <><Space h="sm"/><FaceGridSkeleton/></>;
    }

    return (
        <Flex
            mt="sm"
            direction="column"
            style={{ minHeight: loading ? `${skeletonHeight.current}px` : 'auto' }}
        >
            <Flex
                direction="row"
                wrap="wrap"
                gap={{base: '1px', sm: '1px'}}
                justify="flex-start"
            >
                {faces.map((face) => (
                    <FaceCard
                        key={face.face_key}
                        face={face}
                        isHovered={hoveredCard === face.face_key}
                        isEditing={editingFace === face.face_key}
                        onHover={setHoveredCard}
                        onLeave={() => setHoveredCard("")}
                        onAction={(action) => handleFaceAction(face, action)}
                        onEdit={() => {
                            setEditingFace(face.face_key);
                            const fullName = face.is_person ? `${face.is_person?.person.first} ${face.is_person?.person.last}`.trim() : "";
                            setSearchValue(fullName);
                            updateSuggestions(fullName);
                        }}
                        autocompleteProps={{
                            searchValue,
                            suggestions,
                            onSearchChange: (value) => {
                                setSearchValue(value);
                                updateSuggestions(value);
                            },
                            onOptionSubmit: (value) => handleNameSelection(face, value),
                            onKeyDown: (e) => {
                                if (e.key === "Tab" && suggestions.length > 0) {
                                    e.preventDefault();
                                    setSearchValue(suggestions[0]);
                                    updateSuggestions(suggestions[0]);
                                } else if (e.key === "Enter" && searchValue) {
                                    const exactMatch = suggestions.find(
                                        (suggestion) => suggestion.toLowerCase() === searchValue.toLowerCase()
                                    );
                                    if (exactMatch) {
                                        handleNameSelection(face, exactMatch);
                                    } else if (suggestions.length > 0) {
                                        handleNameSelection(face, suggestions[0]);
                                    }
                                }
                            },
                        }}
                    />
                ))}
            </Flex>

            {/* Loading skeleton and spinner */}
            {showLoadingSkeleton && hasMore && (
                <div ref={skeletonRef}>
                    <FaceGridSkeleton />
                    <Center mt="md" mb="md">
                        <Loader size="md" />
                    </Center>
                </div>
            )}
            {faces.length === 0 && !loading &&
                <Paper w="100%" mt="md" shadow="md" radius="md" withBorder p="xl">
                    <Stack align="center" gap="md">
                        <ThemeIcon size="xl" radius="xl" color="blue">
                            <IconFaceId size={32} />
                        </ThemeIcon>
                        {people.length === 0 ? (
                            <>
                                <Text size="lg" w={600} ta="center">
                                    Looks like you don't have any faces yet.
                                </Text>
                            <Text c="gray.6" ta="center">
                                <Anchor component={Link} to="/import" underline="always">Import</Anchor> some photos and faces will show up here.
                            </Text>
                            </>
                        ) : (
                            <>
                            <Text size="lg" w={600} ta="center">
                            Looks like you've named all the faces in your collection. 🎉
                    </Text>
                    <Text c="gray.6" ta="center">
                        Great Work! Next add details about people in your <Anchor component={Link} to="/family-tree" underline="always">Family Tree</Anchor>.
                    </Text>
                            </>
                            )}
                    </Stack>
                </Paper>
            }

        </Flex>
    );
};