export interface PersonBetter {
    seed_key: string;
    first: string;
    middle?: string;
    last: string;
    dob?: Date;
    profile_image_key?: string;
    profile_url?: string;
    relationships?: Relationship[];
    life_events?: LifeEvent[];
    notes?: string | null;
}

export enum LifeEventType {
    Birth = 'Birth',
    Death = 'Death',
    Marriage = 'Marriage',
    Divorce = 'Divorce',
    Graduation = 'Graduation',
    CareerChange = 'Career Change',
    Relocation = 'Relocation',
    BirthOfChild = 'Birth of Child',
    Retirement = 'Retirement',
    MilitaryService = 'Military Service',
    AwardRecognition = 'Award/Recognition',
    Other = 'Other'
}

export const LifeEventTypes = [
    LifeEventType.Birth,
    LifeEventType.Death,
    LifeEventType.Marriage,
    LifeEventType.Divorce,
    LifeEventType.Graduation,
    LifeEventType.CareerChange,
    LifeEventType.Relocation,
    LifeEventType.BirthOfChild,
    LifeEventType.Retirement,
    LifeEventType.MilitaryService,
    LifeEventType.AwardRecognition,
    LifeEventType.Other,
];

export interface LifeEvent {
    type: '' | LifeEventType;
    date: Date | null;
    description?: string;
    isLocked?: boolean;
}

export enum RelationshipType {
    HasChild = 'Child',
    HasParent = 'Parent',
    HasSibling = 'Sibling',
    HasSpouse = 'Spouse',
    HasFriend = 'Friend',
    // todo: add more here?
}

export const RelationshipTypes = [
    RelationshipType.HasChild,
    RelationshipType.HasParent,
    RelationshipType.HasSibling,
    RelationshipType.HasSpouse,
    RelationshipType.HasFriend,
];


// todo: enum relationship types
export interface Relationship {
    type: RelationshipType | null;
    name: string | null;
    person?: PersonBetter;
}