import React, { useState } from 'react';
import {
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
    useMantineTheme,
    List,
    ThemeIcon,
    Button,
    Divider,
    Box,
    Group,
    Modal
} from '@mantine/core';
import { IconPhoto, IconUser, IconUsers, IconCheck, IconX } from '@tabler/icons-react';
import classes from './Pricing.module.css';
import { useViewportSize } from '@mantine/hooks';
import {useAuth} from "../UseAuthHook";

type Feature = {
    name: string;
    free: string | boolean,
    getting: string | boolean;
    solo: string | boolean;
    family: string | boolean;
};

const features: Feature[] = [
    { name: 'Photo Analyzed', free: '200', getting: '1,000 Images Analyzed', solo: '5,000 Images Analyzed', family: '20,000 Images Analyzed' },
    { name: 'Downloads Allowed', free: '10 sample images', getting: 'Unlimited Downloads', solo: 'Unlimited Downloads', family: 'Unlimited Downloads' },
    { name: 'Storage Duration', free: '1 month', getting: '1 Year Cloud Storage', solo: '1 Year Cloud Storage', family: '1 Year Cloud Storage' },
    // { name: 'Cluster Images By Event', free: true, getting: true, solo: true, family: true },
    // { name: 'Smart Date Estimation', free: true, getting: true, solo: true, family: true },
    // { name: 'Face Recognition', free: true, getting: true, solo: true, family: true },
    // { name: 'Face Keyword Tagging', free: true, getting: true, solo: true, family: true },
    // { name: 'Intelligent Duplicate Removal', free: true, getting: true, solo: true, family: true },

    // Commented features for future use
    // { name: 'Context-Aware Captions*', free: false, getting: false, solo: true, family: true },
    // { name: 'Photo Restoration*', free: false, getting: false, solo: true, family: true },
    // { name: 'Kinship Detection*', free: false, getting: false, solo: true, family: true },
    // { name: 'Easy Sharing*', free: false, getting: false, solo: true, family: true },
    // { name: 'Photo Digitization App*', free: false, getting: false, solo: true, family: true },
    // { name: 'Manage Multiple Users*', free: false, getting: false, solo: false, family: true },
];

const pricingData = [
    {
        title: '1,000 Credits',
        description: 'Perfect for Beginners',
        price: '$6',
        stripeLink: 'https://buy.stripe.com/test_28o4jsgKw2D2ezeaEE',
        popular: false,
        planKey: 'getting',
    },
    {
        title: '5,000 Credits',
        description: 'Our most popular plan',
        price: '$24',
        stripeLink: 'https://buy.stripe.com/test_3cs8zI2TG3H662I289',
        popular: true,
        planKey: 'solo',
    },
    {
        title: '20,000 Credits',
        description: 'For large collections',
        price: '$90',
        stripeLink: 'https://buy.stripe.com/test_8wM9DMcuggtS0Io5km',
        popular: false,
        planKey: 'family',
    },
];

// Free plan data added
const freePlan = {
    title: 'Demo',
    description: 'Try it Risk Free',
    price: 'FREE',
    stripeLink: '',
    popular: false,
    planKey: 'free',
};

type AuthStatus = 'authenticated' | 'unauthenticated' | 'loading';

export function PricingCards() {
    const theme = useMantineTheme();
    const { width } = useViewportSize();
    const isMobile = width < 768;

    // State for authentication modal
    const [authModalOpen, setAuthModalOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState<string | null>(null);

    // This would come from your auth provider
    // const [authStatus, setAuthStatus] = useState<AuthStatus>('unauthenticated');
    // const [userEmail, setUserEmail] = useState<string | null>(null);

    const { userProfile, isAuthenticated } = useAuth();

    const handlePurchase = (plan: typeof pricingData[0]) => {
        if (isAuthenticated && userProfile.sub && userProfile.email) {
            // Build the Stripe URL with query parameters
            const stripeUrl = new URL(plan.stripeLink);
            stripeUrl.searchParams.append('client_reference_id', userProfile.sub);
            stripeUrl.searchParams.append('prefilled_email', userProfile.email);

            // Redirect to the Stripe checkout page
            window.location.href = stripeUrl.toString();
        } else {
            console.log("Need to have user sign up or log in first!");
            // Optionally, open the auth modal or guide the user to log in
            setAuthModalOpen(true);
        }
    };

    const FeatureIcon = ({ included }: { included: boolean }) => (
        <ThemeIcon size={20} radius="xl" color={included ? 'blue' : 'red'}>
            {included ? <IconCheck size={rem(12)} /> : <IconX size={rem(12)} />}
        </ThemeIcon>
    );

    // Helper function to render feature value or name appropriately
    const renderFeatureText = (feature: Feature, planKey: string) => {
        const specialFeatures = ['Photo Analyzed', 'Storage Duration', 'Downloads Allowed'];
        if (specialFeatures.includes(feature.name)) {
            return `${feature[planKey as keyof Feature]}`;
        }
        return feature.name;
    };

    // Generate all plans including free
    // const allPlans = [freePlan, ...pricingData];

    const plans = pricingData.map((plan) => (
        <Card
            key={plan.title}
            shadow="md"
            radius="md"
            // className={classes.card}
            padding="xl"
            style={{
                border: plan.popular ? `2px solid ${theme.colors.blue[6]}` : 'none',
                opacity: plan.planKey === 'free' ? 0.9 : 1,
                backgroundColor: plan.planKey === 'free' ? theme.colors.gray[0] : 'white'
            }}
        >
            <Text ta="center" fw={700} c='blue' style={{fontFamily: 'Bebas Neue', fontSize: '4rem'}}>
                {plan.price}
            </Text>
            <Text mb="md" ta="center" c="dimmed" style={{fontFamily: 'Bebas Neue', fontSize: '2.5rem'}}>
                {plan.title}
            </Text>
            <Divider mb='md'/>

            <List spacing="sm" size="sm" center>
                {features.map((feature, index) => (
                    <React.Fragment key={index}>
                        <List.Item
                            icon={
                                <FeatureIcon included={!!(feature[plan.planKey as keyof Feature])} />
                            }
                        >
                            {renderFeatureText(feature, plan.planKey)}
                        </List.Item>
                        {index < features.length - 1 && <Divider my="xs" />}
                    </React.Fragment>
                ))}
            </List>
            <Button
                fullWidth
                mt="xl"
                color={plan.planKey === 'free' ? 'gray' : 'blue'}
                onClick={() => handlePurchase(plan)}
            >
                {plan.planKey === 'free' ? 'Get Started' : 'Purchase'}
            </Button>
        </Card>
    ));

    return (
        <div style={{ width: '100%', backgroundColor: '#1F1E20' }}>
            <Container size="lg" py="xl">
                <Title
                    mt='xl' mb='lg'
                    c={'white'}
                    order={2}
                    className={classes.title}
                    ta="center"
                    style={{
                        fontFamily: 'Bebas Neue',
                        fontStyle: 'bold',
                        fontSize: isMobile ? '3rem' : '5rem',
                        textTransform: 'uppercase',
                        whiteSpace: 'nowrap',
                        fontWeight: 400,
                        lineHeight: 0.9
                    }}
                >
                    Pricing Is Easy, <br/> No Subscription required.
                </Title>

                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Text
                        c='white'
                        // className={classes.description}
                        ta="center"
                        mb="xl"
                        style={{
                            fontSize: isMobile ? '1rem' : '1.3rem',
                            lineHeight: 1.5,
                            maxWidth: '70%',
                        }}
                    >
                        With our credit-based pricing, just pay for the number of photos you plan to organize. <br/> Each credit covers 1 upload, unlimited downloads, and a year of cloud storage.
                    </Text>
                </Box>

                <SimpleGrid
                    cols={{ base: 1, md: 3 }}
                    spacing="xl"
                    mt='lg'
                    mb={50}
                    // mt={50}
                >
                    {plans}
                </SimpleGrid>

                {/* Authentication Modal */}
                <Modal
                    opened={authModalOpen}
                    onClose={() => setAuthModalOpen(false)}
                    title="Create account or sign in"
                    centered
                >
                    <Text size="sm" mb="md">
                        Please create an account or sign in to continue with your purchase.
                    </Text>
                    <Group grow>
                        <Button
                            variant="outline"
                            onClick={() => {
                                // Redirect to sign in page with return URL
                                const returnUrl = selectedPlan ?
                                    encodeURIComponent(selectedPlan) :
                                    encodeURIComponent(window.location.href);
                                window.location.href = `/signin?returnUrl=${returnUrl}`;
                            }}
                        >
                            Sign In
                        </Button>
                        <Button
                            onClick={() => {
                                // Redirect to sign up page with return URL
                                const returnUrl = selectedPlan ?
                                    encodeURIComponent(selectedPlan) :
                                    encodeURIComponent(window.location.href);
                                window.location.href = `/signup?returnUrl=${returnUrl}`;
                            }}
                        >
                            Create Account
                        </Button>
                    </Group>
                </Modal>
            </Container>
        </div>
    );
}

// https://buy.stripe.com/test_28o4jsgKw2D2ezeaEE