import { useState, useEffect } from 'react';
import { PersonBetter } from '../../types/Person';
import {fetchPeople} from "../../api/People";

interface UseAutocompleteProps {
    maxSuggestions?: number;
}

export const UseAutocomplete = ({ maxSuggestions = 5 }: UseAutocompleteProps) => {
    const [searchValue, setSearchValue] = useState("");
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [people, setPeople] = useState<PersonBetter[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadPeople = async () => {
            try {
                const fetchedPeople = await fetchPeople();
                setPeople(fetchedPeople);
            } catch (error) {
                console.error('Failed to load people for autocomplete:', error);
            } finally {
                setLoading(false);
            }
        };

        loadPeople();
    }, []);

    const updateSuggestions = (value: string) => {
        const lowerValue = value.toLowerCase();
        let newSuggestions = people
            .map(person => `${person.first} ${person.last}`)
            .filter(name => name.toLowerCase().includes(lowerValue))
            .slice(0, maxSuggestions);

        if (value && !newSuggestions.some(name => name.toLowerCase() === lowerValue)) {
            newSuggestions.push(`+ Add "${value}" as new person`);
        }

        setSuggestions(newSuggestions);
    };

    return {
        searchValue,
        setSearchValue,
        suggestions,
        updateSuggestions,
        people,
        loading
    };
};