// PersonCard.tsx
import React, { useState, useCallback } from 'react';
import { Card, Avatar, Text, Modal } from '@mantine/core';
import { PersonDetailsModal } from './PersonDetailsModal';
import { PersonBetter} from '../../types/Person';

interface FamilyMemberProps {
    person: PersonBetter;
    onUpdate: (updatedMember: PersonBetter) => void;
}

export const PersonCard: React.FC<FamilyMemberProps> = React.memo(({ person, onUpdate}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCardClick = useCallback(() => {
        setIsModalOpen(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    const handleUpdateMember = useCallback((updatedMember: PersonBetter) => {
        onUpdate(updatedMember);
    }, [onUpdate]);

    return (
        <>
            <Card
                key={person.seed_key}
                shadow="xs"
                radius="md"
                style={{
                    width: 'calc((100% - 30px) / 4)',
                    aspectRatio: '1.0',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                }}
                onClick={handleCardClick}
            >
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Avatar
                        src={person.profile_url}
                        radius="xl"
                        alt={`${person.first} ${person.last} profile photo`}
                        style={{
                            width: '120px',
                            height: '120px',
                            marginBottom: '20px',
                        }}
                    />
                    <div style={{textAlign: 'center'}}>
                        <Text size="lg">{person.first} {person.last}</Text>
                        {/*todo: no time zones on dob! - fix this then uncomment*/}
                        {/*<Text size="sm" color="gray">*/}
                        {/*    {person.dob? person.dob.toString() : ""}*/}
                        {/*</Text>*/}
                    </div>
                </div>
            </Card>

            {isModalOpen && (
                <Modal
                    opened={isModalOpen}
                    onClose={handleCloseModal}
                    size="xl"
                    title={`${person.first} ${person.last} - Family Information`}
                >
                    <PersonDetailsModal
                        person={person}
                        onUpdate={handleUpdateMember}
                        onClose={handleCloseModal}
                        // familyMembersBasic={familyMembersBasic}
                    />
                </Modal>
            )}
        </>
    );
});