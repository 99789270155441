// PersonDetailsModal.tsx
import React, { useState, useMemo } from 'react';
import { Box, Tabs, Text } from '@mantine/core';
import { PersonBetter } from "../../types/Person";
import { RelationshipsPanel } from "./RelationshipPanel";
import { LifeEventsPanel } from "./LifeEventPanel";
import { PersonalInfoPanel } from "./PersonalInfoPanel";
import { NotesPanel } from "./NotesPanel";

interface PersonDetailsProps {
    person: PersonBetter;
    onUpdate: (updatedMember: PersonBetter) => void;
    onClose: () => void;
}

export const PersonDetailsModal: React.FC<PersonDetailsProps> = React.memo(({
                                                                                person,
                                                                                onUpdate,
                                                                                onClose,
                                                                            }) => {
    const [editedMember, setEditedMember] = useState<PersonBetter>(person);
    const [isUpdating, setIsUpdating] = useState(false);
    const [error, setError] = useState<string | null>(null);

    // Memoize the handlePartialUpdate function
    const handlePartialUpdate = useMemo(() => async (updatedFields: Partial<PersonBetter>) => {
        setError(null);
        setIsUpdating(true);

        try {
            const updatedMember = {
                ...editedMember,
                ...updatedFields
            };

            setEditedMember(updatedMember);
            await onUpdate(updatedMember);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to update');
            setEditedMember(person);
        } finally {
            setIsUpdating(false);
        }
    }, [editedMember, person, onUpdate]);

    return (
        <Box>
            {error && (
                <Text color="red" mb="md">
                    {error}
                </Text>
            )}
            <Tabs defaultValue="personal">
                <Tabs.List>
                    <Tabs.Tab value="personal">Personal Information</Tabs.Tab>
                    <Tabs.Tab value="relationships">Relationships</Tabs.Tab>
                    <Tabs.Tab value="events">Life Events</Tabs.Tab>
                    <Tabs.Tab value="notes">Notes</Tabs.Tab>
                    {/*<Tabs.Tab value="photos">Linked Photos</Tabs.Tab>*/}
                </Tabs.List>

                <Tabs.Panel value="personal">
                    <PersonalInfoPanel
                        person={editedMember}
                        onUpdate={handlePartialUpdate}
                        isUpdating={isUpdating}
                    />
                </Tabs.Panel>

                <Tabs.Panel value="relationships">
                    <RelationshipsPanel
                        person={editedMember}
                        onUpdate={handlePartialUpdate}
                        isUpdating={isUpdating}
                        // familyMembersBasic={familyMembersBasic} // todo: this is really a way to get autcomplete list?
                    />
                </Tabs.Panel>

                <Tabs.Panel value="events">
                    <LifeEventsPanel
                        person={editedMember}
                        onUpdate={handlePartialUpdate}
                        isUpdating={isUpdating}
                    />
                </Tabs.Panel>

                <Tabs.Panel value="notes">
                    <NotesPanel
                        person={editedMember}
                        onUpdate={handlePartialUpdate}
                        isUpdating={isUpdating}
                    />
                </Tabs.Panel>

                {/*<Tabs.Panel value="photos">*/}
                {/*    <SimpleGrid cols={3}>*/}
                {/*        /!* Photo grid implementation *!/*/}
                {/*    </SimpleGrid>*/}
                {/*</Tabs.Panel>*/}
            </Tabs>
        </Box>
    );
});
