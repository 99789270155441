import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Group, Text, Image, Center, Flex, ActionIcon, RingProgress, Notification, Stack } from '@mantine/core';
import { IconUpload, IconPhoto, IconCheck, IconX } from '@tabler/icons-react';
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { useAuth } from "../UseAuthHook";
import {
    UploadService,
    UploadingFile,
    UploadStats,
    ConnectionStatus,
    UploadProgress
} from './UploadService';

export const FileUploader: React.FC = () => {
    const {updateUserCounters, userProfile} = useAuth();
    const [files, setFiles] = useState<UploadingFile[]>([]);
    const [uploading, setUploading] = useState(false);
    const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>('disconnected');
    const [uploadProgress, setUploadProgress] = useState<UploadProgress>({
        uploadProgress: 0,
        processingProgress: 0,
        duplicateDetectionProgress: 0,
        totalFiles: 0,
        uploadedFiles: 0,
        processedFiles: 0,
        duplicateDetectionComplete: false
    });
    const [uploadStats, setUploadStats] = useState<UploadStats>({
        uploadCount: userProfile?.uploadCount || 0,
        uploadLimit: userProfile?.uploadLimit || 0,
        remainingUploads: (userProfile?.uploadLimit || 0) - (userProfile?.uploadCount || 0)
    });

    const uploadServiceRef = useRef<UploadService | null>(null);

    // Initialize the UploadService
    useEffect(() => {
        const handleUpdates = (
            updatedFiles: UploadingFile[],
            progress: UploadProgress,
            status: ConnectionStatus
        ) => {
            setFiles(updatedFiles);
            setUploadProgress(progress);
            setConnectionStatus(status);

            // If we have files and they're being processed, set uploading to true
            if (updatedFiles.length > 0 && !progress.duplicateDetectionComplete) {
                setUploading(true);
            } else if (progress.duplicateDetectionComplete) {
                setUploading(false);
            }
        };

        const uploadService = new UploadService(handleUpdates);
        uploadServiceRef.current = uploadService;

        // Initialize the service
        uploadService.initialize().catch(error => {
            console.error('Failed to initialize upload service:', error);
        });

        // Clean up on unmount
        return () => {
            if (uploadServiceRef.current) {
                uploadServiceRef.current.cleanup();
            }
        };
    }, []);

    // Update upload stats when userProfile changes
    useEffect(() => {
        if (userProfile) {
            setUploadStats({
                uploadCount: userProfile.uploadCount || 0,
                uploadLimit: userProfile.uploadLimit || 0,
                remainingUploads: (userProfile.uploadLimit || 0) - (userProfile.uploadCount || 0)
            });
        }
    }, [userProfile]);

    // Handle file drop
    const handleDrop = useCallback(async (acceptedFiles: FileWithPath[]) => {
        if (!uploadServiceRef.current || acceptedFiles.length === 0) return;

        // Check if we have enough uploads remaining
        if (uploadStats.remainingUploads <= 0) {
            console.error('Upload limit reached');
            return;
        }

        setUploading(true);

        try {
            await uploadServiceRef.current.uploadFiles(acceptedFiles, uploadStats.remainingUploads);
            updateUserCounters({
                uploadCount: userProfile.uploadCount! + acceptedFiles.length,
            });
        } catch (error) {
            console.error('Error uploading files:', error);
            setUploading(false);
        }
    }, [uploadStats.remainingUploads]);

    // Generate previews for files
    const previews = files.map(({ file, status, fileId }) => {
        const imageUrl = URL.createObjectURL(file);
        return (
            <div key={fileId} style={{
                position: 'relative',
                width: 'calc((100% - 12px) / 4)',
                aspectRatio: 1 / 1,
            }}>
                <Image src={imageUrl} style={{aspectRatio: 1 / 1}} onLoad={() => URL.revokeObjectURL(imageUrl)}/>
                {status === 'uploaded' && (
                    <ActionIcon color="yellow" variant="filled" radius="xl" size="sm"
                                style={{position: 'absolute', top: '3px', right: '3px'}}>
                        <IconCheck size="15px"/>
                    </ActionIcon>
                )}
                {status === 'processed' && (
                    <ActionIcon color="teal" variant="filled" radius="xl" size="sm"
                                style={{position: 'absolute', top: '3px', right: '3px'}}>
                        <IconCheck size="15px"/>
                    </ActionIcon>
                )}
                {status === 'failed' && (
                    <ActionIcon color="red" variant="filled" radius="xl" size="sm"
                                style={{position: 'absolute', top: '3px', right: '3px'}}>
                        <IconX size="15px"/>
                    </ActionIcon>
                )}
            </div>
        );
    });

    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <Center style={{width: '100%'}}>
                <Dropzone
                    onDrop={handleDrop}
                    maxSize={15 * 1024 ** 2}
                    accept={IMAGE_MIME_TYPE}
                    style={{width: '100%', backgroundColor: 'var(--mantine-color-gray-light)'}}
                    disabled={uploading || uploadStats.remainingUploads <= 0 || connectionStatus !== 'connected'}
                >
                    <Group justify="center" gap="xl" mih={220} style={{pointerEvents: 'none', width: '100%'}}>
                        <Dropzone.Accept>
                            <IconUpload
                                style={{width: '52px', height: '52px', color: 'var(--mantine-color-blue-6)'}}
                                stroke={1.5}
                            />
                        </Dropzone.Accept>
                        <Dropzone.Idle>
                            <IconPhoto
                                style={{width: '52px', height: '52px', color: 'var(--mantine-color-dimmed)'}}
                                stroke={1.5}
                            />
                        </Dropzone.Idle>
                        <div>
                            <Text size="xl" inline>
                                Drag images here or click to select files
                            </Text>
                            <Text size="sm" c="dimmed" inline mt={7}>
                                Attach as many files as you like, each file should not exceed 15MB.
                            </Text>
                            <Text size="sm" c="dimmed" inline mt={7}>
                                You have {uploadStats.remainingUploads} uploads remaining.
                            </Text>
                            {/*{connectionStatus !== 'connected' && (*/}
                            {/*    <Text size="sm" c="red" inline mt={7}>*/}
                            {/*        {connectionStatus === 'connecting' ? 'Connecting...' : 'Not connected. Trying to reconnect...'}*/}
                            {/*    </Text>*/}
                            {/*)}*/}
                        </div>
                    </Group>
                </Dropzone>
            </Center>

            <Flex
                mt="md"
                direction="row"
                wrap="wrap"
                gap={{base: '3px', sm: '3px'}}
                justify={{sm: '3px'}}
            >
                {previews}
            </Flex>

            {files.length > 0 && (
                <Stack mt="lg" gap="sm">
                    {/* Upload Progress Notification */}
                    <Notification
                        icon={
                            <RingProgress
                                ml={'sm'} mr={'sm'}
                                sections={[{value: uploadProgress.uploadProgress, color: 'blue'}]}
                                label={
                                    <Text c="blue" fw={700} ta="center" size="xl">
                                        {Math.round(uploadProgress.uploadProgress)}%
                                    </Text>
                                }
                                size={50}
                                thickness={4}
                            />
                        }
                        withBorder
                        color={'none'}
                        radius="lg"
                        title="File Upload"
                        // disallowClose
                    >
                        <Text>{uploadProgress.uploadedFiles}/{uploadProgress.totalFiles} files uploaded</Text>
                        <Text size="sm" c="dimmed">
                            Upload limit: {uploadStats.uploadCount}/{uploadStats.uploadLimit} ({uploadStats.remainingUploads} remaining)
                        </Text>
                    </Notification>

                    {/* Processing Progress Notification */}
                    {uploadProgress.uploadedFiles > 0 && (
                        <Notification
                            icon={
                                <RingProgress
                                    ml={'sm'} mr={'sm'}
                                    sections={[{value: uploadProgress.processingProgress, color: 'green'}]}
                                    label={
                                        <Text c="green" fw={700} ta="center" size="xl">
                                            {Math.round(uploadProgress.processingProgress)}%
                                        </Text>
                                    }
                                    size={50}
                                    thickness={4}
                                />
                            }
                            withBorder
                            color={'none'}
                            radius="lg"
                            title="Image Processing"
                            // disallowClose
                        >
                            <Text>{uploadProgress.processedFiles}/{uploadProgress.uploadedFiles} files processed</Text>
                        </Notification>
                    )}

                    {/* Duplicate Detection Notification */}
                    {uploadProgress.processedFiles > 0 && (
                        <Notification
                            icon={
                                uploadProgress.duplicateDetectionComplete ? (
                                    <RingProgress
                                        ml={'sm'} mr={'sm'}
                                        sections={[{value: 100, color: 'teal'}]}
                                        label={
                                            <Center>
                                                <ActionIcon color="teal" variant="light" radius="xl" size="xl">
                                                    <IconCheck size="20px"/>
                                                </ActionIcon>
                                            </Center>
                                        }
                                        size={50}
                                        thickness={4}
                                    />
                                ) : (
                                    <RingProgress
                                        ml={'sm'} mr={'sm'}
                                        sections={[{value: uploadProgress.duplicateDetectionProgress, color: 'violet'}]}
                                        label={
                                            <Text c="violet" fw={700} ta="center" size="xl">
                                                {Math.round(uploadProgress.duplicateDetectionProgress)}%
                                            </Text>
                                        }
                                        size={50}
                                        thickness={4}
                                    />
                                )
                            }
                            withBorder
                            color={'none'}
                            radius="lg"
                            title={uploadProgress.duplicateDetectionComplete ? "Duplicate Detection Complete" : "Detecting Duplicates"}
                            // disallowClose
                        >
                            <Text>
                                {uploadProgress.duplicateDetectionComplete
                                    ? "All duplicates have been detected"
                                    : "Analyzing images for duplicates..."}
                            </Text>
                        </Notification>
                    )}
                </Stack>
            )}
        </div>
    );
};