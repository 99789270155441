import React, {useCallback, useEffect, useState} from 'react';
import { Button, Divider, Flex, Group, Paper, Blockquote, Image, Title } from '@mantine/core';
import {DuplicateImageGroup, ImageData} from '../../types/Image';
import {fetchDuplicatesInImages, mergeDuplicatesInImage} from "../removeDuplicates/API";

interface RemoveDuplicatesModalProps {
    render: boolean;
    onSkip: () => void;
    inImages: ImageData[];
    onIsReady: () => void;
    onDuplicateRemoved: (removed: ImageData) => void;
    onAllDuplicatesMerged: () => void;

}

export const RemoveDuplicateModal: React.FC<RemoveDuplicatesModalProps> = ({render, onSkip, inImages, onIsReady, onDuplicateRemoved, onAllDuplicatesMerged}) => {
    const [loading, setLoading] = useState(false);
    const [duplicateGroups, setDuplicateGroups] = useState<DuplicateImageGroup[]>([]);


    const loadDuplicates = useCallback(async () => {
        console.log("loading duplicates!")
        setLoading(true);

        try {
            const keys: string[] = inImages.map(i => i.key);
            const response = await fetchDuplicatesInImages(keys);
            if (response.duplicates.length > 0) {
                setDuplicateGroups(prev => [...prev, ...response.duplicates]);
            } else {
                onSkip()
                console.log("skipping duplicates!!!")
            }
        } catch (error) {
            console.error('Error fetching duplicates :', error);
        } finally {
            setLoading(false);
            onIsReady();
        }
    }, []);

    useEffect(() => {
        loadDuplicates();
    }, [inImages]);


    const mergeDuplicates = async (group: DuplicateImageGroup) => {
        const updatedGroups = duplicateGroups.filter(dupGroup => dupGroup.key !== group.key);
        setDuplicateGroups(updatedGroups);

        try {
            await mergeDuplicatesInImage(group);
        } catch (error) {
            console.error('Error merging duplicates:', error);
            setDuplicateGroups(currentGroups => [...currentGroups, group]);
            alert('Failed to merge duplicates. Please try again.');
        }
    };

    if (loading || !render) {
        return <></>;
    }

    return (<div>
        {duplicateGroups.map(group => (
        <Paper key={group.key} shadow="sm" radius="lg" withBorder p="xl" mt="lg">
            <Title order={2}>Duplicate Images</Title>
            <Divider mt="sm" />
            <Flex mt="lg" wrap="wrap" gap="5px">
                {group.images.map((image, idx) => (
                    <div key={image.key} style={{ width: 'calc((100% - 15px) / 3)', aspectRatio: 1 }}>
                        {<Image src={image.thumbnail_url} alt={`Duplicate ${image.key}`} style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>}
                        {/*todo: why wasn't ImageWithSkeleton working here working here?*/}
                    </div>
                ))}
            </Flex>
            <Group justify="flex-end" gap="sm" mt="lg">
                <Button color="gray" variant="outline" onClick={() => mergeDuplicates(group)}>
                    Keep All
                </Button>
                <Button color="blue" onClick={() => mergeDuplicates(group)}>
                    Merge Duplicates
                </Button>
            </Group>
        </Paper>
    ))}
    </div>);
};

export default RemoveDuplicateModal;


//     const mergeDuplicates = async (group: DuplicateImageGroup) => {
//         try {
//             const apiUrl = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/duplicates";
//             await axios.post(apiUrl,
//                 {
//                     sub: userId,
//                     key: group.key
//                 },
//                 {
//                     headers: {
//                         'Authorization': `Bearer ${auth.IdToken}`,
//                         'Content-Type': 'application/json'
//                     }
//                 }
//             );
//
//             // todo: this is a bit of a hack, i should really be returning something from the post request that tells
//             //  me all the image keys / face keys that have been merged so that I can filter those out of previous modal
//             // Get all image keys from the duplicate group except the first one (which is kept)
//             const mergedKeys = group.duplicates.slice(1).map(duplicate => duplicate.key);
//             onDuplicatesMerged(mergedKeys);
//         } catch (error) {
//             console.error('Error merging duplicates:', error);
//         }
//     };
//
//     const mergeAllDuplicates = async () => {
//         try {
//             const allMergedKeys: string[] = [];
//             for (const group of duplicateGroups) {
//                 // For each group, get all keys except the first one
//                 const mergedKeys = group.duplicates.slice(1).map(duplicate => duplicate.key);
//                 allMergedKeys.push(...mergedKeys);
//
//                 await mergeDuplicates(group);
//             }
//             onDuplicatesMerged(allMergedKeys);
//         } catch (error) {
//             console.error('Error merging all duplicates:', error);
//         }
//     };
//
//     if (duplicateGroups.length === 0) {
//         return (
//             <div>
//                 <Title order={3} mb="md">No Duplicates Found</Title>
//                 <Text size="sm" mb="lg">No duplicate images were found in your selection.</Text>
//             </div>
//         );
//     }
//
//     return (
//         <div>
//             <Flex justify="space-between" align="center" mb="md">
//                 <Title order={3}>Duplicate Images Found</Title>
//                 <Button
//                     color="blue"
//                     variant="light"
//                     onClick={mergeAllDuplicates}
//                 >
//                     Merge All Duplicates
//                 </Button>
//             </Flex>
//
//             <Text size="sm" mb="lg">
//                 The following duplicate images were found. You can merge them to keep the best quality version.
//             </Text>
//
//             {duplicateGroups.map((group, index) => (
//                 <Paper
//                     key={group.key}
//                     shadow="sm"
//                     radius="md"
//                     withBorder
//                     p="md"
//                     mt={index > 0 ? "md" : undefined}
//                     style={{ backgroundColor: '#f0f0f0' }}
//                 >
//                     <Text fw={500} mb="xs">Duplicate Set {index + 1}</Text>
//                     <Divider mb="md" />
//
//                     <Flex
//                         direction="row"
//                         wrap="wrap"
//                         gap="xs"
//                         justify="flex-start"
//                     >
//                         {group.duplicates.map((image, idx) => (
//                             <div
//                                 key={idx}
//                                 style={{
//                                     position: 'relative',
//                                     width: 'calc((100% - 20px) / 3)',
//                                     aspectRatio: '1 / 1'
//                                 }}
//                             >
//                                 <ImageWithSkeleton
//                                     src={image.thumbnail_url}
//                                     alt={`Duplicate ${idx + 1}`}
//                                     onClick={() => {}}
//                                 />
//                             </div>
//                         ))}
//                     </Flex>
//
//                     <Group justify="flex-end" mt="sm">
//                         <Button
//                             variant="light"
//                             color="blue"
//                             size="sm"
//                             onClick={() => mergeDuplicates(group)}
//                         >
//                             Merge Images
//                         </Button>
//                     </Group>
//                 </Paper>
//             ))}
//         </div>
//     );
// };