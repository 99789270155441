import React from 'react';
import { Paper, Skeleton, Flex } from '@mantine/core';

export const ImageSelectionGridSkeleton: React.FC = () => {
    return (
        <Flex direction="row" wrap="wrap" gap={{ base: '3px', sm: '3px' }} justify={{ sm: '3px' }}>
            {Array.from({ length: 25 }).map((_, index) => (
                <Paper
                    key={index}
                    radius={0}
                    p={0}
                    style={{
                        position: 'relative',
                        width: 'calc((100% - 15px) / 5)',
                        aspectRatio: '1 / 1',
                    }}
                >
                    <Skeleton height="100%" width="100%" animate={true} />
                </Paper>
            ))}
        </Flex>
    );
};
