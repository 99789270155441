import { Container, Text, Button, Title, Image, Paper, Flex, Stack, Box } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import classes from './Hero.module.css';
import React from "react";

interface HeroProps {
    callToAction: string;
    onCallToActionClick: () => void; // onClick function for call to action button
}

const Arrow = () => (
    <svg width="100%" height="100%" viewBox="0 0 222 197" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="43" width="140" height="131" fill="white"/>
        <path d="M122.586 11.6331L201.716 104.93L121.353 196.092L122.586 11.6331Z" fill="white"/>
    </svg>
);

export const Hero: React.FC<HeroProps> = ({ callToAction, onCallToActionClick }) => {
    const { width } = useViewportSize();
    const isMobile = width < 768;

    return (
        <Box style={{ position: 'relative', overflow: 'hidden' }}>
            <Flex direction={isMobile ? "column" : "row"}>
                <Paper
                    style={{
                        backgroundColor: '#1F1E20',
                        minHeight: isMobile ? '60vh' : '100vh',
                        width: isMobile ? '100%' : '50%',
                        padding: '5%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <Stack gap="lg" w="100%">
                        <Box style={{ position: 'relative' }}>
                            {!isMobile ? (
                                <>
                                <Title
                                    c="white"
                                    order={1}
                                    style={{
                                        fontFamily: 'Bebas Neue',
                                        fontWeight: 400,
                                        fontStyle: 'normal',
                                        fontSize: isMobile ? '3rem' : 'clamp(3.5rem, 5vw, 7rem)',
                                        lineHeight: 1,
                                        marginBottom: '0.2em'
                                    }}
                                >
                                    ORGANIZE SCANNED <br/> PHOTOS IN <br/> MINUTES
                                </Title>
                                {/*todo: I want this arrow to be as big and obnoxious as possible without overlapping the text!*/}
                                <Box
                                    style={{
                                        position: 'absolute',
                                        bottom: '0',
                                        right: '0',
                                        width: 'clamp(80px, 15vw, 222px)',
                                        transform: 'translateX(-10%)'
                                    }}
                                >
                                    <Arrow/>
                                </Box>
                                </>
                            ) : (
                                <>
                                <Title
                                    c="white"
                                    order={1}
                                style={{
                                    fontFamily: 'Bebas Neue',
                                    fontWeight: 400,
                                    fontStyle: 'normal',
                                    fontSize: isMobile ? '3rem' : 'clamp(3.5rem, 5vw, 7rem)',
                                    lineHeight: 1,
                                    marginBottom: '0.2em'
                                }}
                            >
                                ORGANIZE SCANNED PHOTOS <br/> IN MINUTES
                            </Title>
                                </>
                                )}
                        </Box>

                        <Stack gap="md" w={isMobile ? '100%' : 'clamp(280px, 80%, 600px)'}>
                            <Text
                                mt="xl"
                                c="white"
                                style={{
                                    fontSize: 'clamp(1rem, 1.5vw, 1.2rem)',
                                }}
                                ta="left"
                                // ta={isMobile ? "left" : "center"}
                            >
                                Our AI-driven platform intelligently groups photos by events, estimates dates, and generates context-aware captions. We
                                go beyond simple digitization, offering unique insights that bring your memories to life.
                            </Text>
                            <Button
                                fullWidth
                                variant="filled"
                                size={isMobile ? "md" : "xl"}
                                className={classes.control}
                                mt="md"
                                onClick={onCallToActionClick}
                                style={{
                                    alignSelf: isMobile ? 'flex-start' : 'center',
                                    fontSize: isMobile ? '1rem' : 'clamp(1rem, 1.2vw, 1.2rem)'
                                }}
                            >
                                {callToAction}
                            </Button>
                        </Stack>
                    </Stack>
                </Paper>

                {!isMobile && (
                <Paper
                    style={{
                        backgroundColor: 'white',
                        minHeight: isMobile ? '40vh' : '100vh',
                        width: isMobile ? '100%' : '50%',
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        justifyContent: 'center'
                    }}
                >
                </Paper>
                )}
            </Flex>

            {!isMobile && (
                <Box
                    style={{
                        position: 'absolute',
                        width: '54%',
                        right: '3%',
                        top: '50%',
                        transform: 'translateY(-42%)',
                        zIndex: 2
                    }}
                >
                    <Image
                        src={'./HeroScreenShots.png'}
                        style={{
                            width: '100%',
                            height: 'auto'
                        }}
                    />
                </Box>
            )}
        </Box>
    );
}