import React, { useEffect, useState } from 'react';
import { Box, Group, Flex, Button, Burger, Drawer, Divider, Stack, Title, Container } from '@mantine/core';
import { useDisclosure, useViewportSize } from '@mantine/hooks';
import { rem } from '@mantine/core';
import classes from './HeaderMegaMenu.module.css';
import {UserMenu, UserNamed} from '../UserDropdown';
import {IconArrowRight, IconPhoto} from "@tabler/icons-react";

interface AuthenticatedHeaderProps {
    onSortClick: () => void;
    // onRegisterClick: () => void;
}

export const AuthenticatedHeader: React.FC<AuthenticatedHeaderProps> = ({ onSortClick }) => {
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const { width } = useViewportSize();
    const [scrolled, setScrolled] = useState(false);

    // Track scrolling to add shadow only when scrolled
    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 10);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Add scroll animation for navigation links
    const scrollToSection = (elementId: string) => {
        closeDrawer();
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    };

    // Dynamic sizing for small screens
    const isMobile = width < 768;
    const isTablet = width >= 768 && width < 1100;

    // Adjust logo and title size based on screen size
    const logoHeight = isMobile ? 40 : 52;
    const titleFontSize = isMobile ? 28 : isTablet ? 32 : 40;
    const linkFontSize = isMobile ? 16 : 18;
    const buttonSize = isMobile ? "sm" : isTablet ? "sm" : "lg";

    return (
        <Box>
            <header
                className={classes.header}
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 5,
                    padding: isMobile ? '15px 8px' : isTablet ? '20px 15px' : '25px 50px 20px',
                    background: '#ffffff',
                    boxShadow: scrolled ? '0px 2px 10px rgba(0, 0, 0, 0.1)' : 'none',
                    transition: 'box-shadow 0.3s ease'
                }}
            >
                <Container size="xl" p={0} style={{ maxWidth: '100%' }}>
                    <Flex justify="space-between" align="center" h="100%" wrap="nowrap">
                        {/* Left section - Logo */}
                        <Flex align="center" style={{ flexShrink: 1, minWidth: 0 }}>
                            <img
                                src="./logo.png"
                                alt="Company Logo"
                                style={{
                                    height: logoHeight,
                                    marginRight: isMobile ? 10 : 20,
                                    marginTop: -15
                                }}
                            />
                            <Title
                                c="black"
                                order={1}
                                style={{
                                    fontFamily: 'Bebas Neue',
                                    fontSize: titleFontSize,
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                SORT MY SHOEBOX
                            </Title>
                        </Flex>

                        {/* Center section - Navigation links (desktop only) */}
                        <Flex
                            justify="center"
                            align="center"
                            style={{
                                position: 'absolute',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                display: isMobile || isTablet ? 'none' : 'flex',
                            }}
                        >
                            <Group h="100%" gap="xl">
                                <a
                                    href="#steps"
                                    className={classes.link}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        scrollToSection('steps');
                                    }}
                                    style={{ fontSize: rem(linkFontSize) }}
                                >
                                    How It Works
                                </a>
                                <a
                                    href="#features"
                                    className={classes.link}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        scrollToSection('features');
                                    }}
                                    style={{ fontSize: rem(linkFontSize) }}
                                >
                                    Features
                                </a>
                                <a
                                    href="#pricing"
                                    className={classes.link}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        scrollToSection('pricing');
                                    }}
                                    style={{ fontSize: rem(linkFontSize) }}
                                >
                                    Pricing
                                </a>
                            </Group>
                        </Flex>

                        {/* Right section - Buttons */}
                        <Group visibleFrom="lg" gap="md">
                            <UserNamed/>
                            <Button
                                leftSection={<IconPhoto/>}
                                rightSection={<IconArrowRight/>}
                                ml='md'
                                variant="filled"
                                onClick={onSortClick}
                                size={buttonSize}
                            >
                                Sort
                            </Button>
                        </Group>
                    </Flex>
                </Container>
            </header>

            {/* Improved dropdown drawer */}
            <Drawer
                opened={drawerOpened}
                onClose={closeDrawer}
                size="50%"
                position="top"
                withCloseButton
                styles={{
                    body: {
                        padding: '20px',
                    },
                    content: {
                        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                        borderRadius: '0 0 15px 15px'
                    }
                }}
            >
                <Stack gap="xl" align="center" style={{ fontSize: rem(20) }}>
                    <a
                        href="#steps"
                        className={classes.link}
                        onClick={(e) => {
                            e.preventDefault();
                            scrollToSection('steps');
                        }}
                    >
                        How It Works
                    </a>
                    <a
                        href="#features"
                        className={classes.link}
                        onClick={(e) => {
                            e.preventDefault();
                            scrollToSection('features');
                        }}
                    >
                        Features
                    </a>
                    <a
                        href="#pricing"
                        className={classes.link}
                        onClick={(e) => {
                            e.preventDefault();
                            scrollToSection('pricing');
                        }}
                    >
                        Pricing
                    </a>
                </Stack>

                <Divider my="xl" />

                <Flex direction="column" gap="md" mt="xl"> <UserNamed/>
                    <Button
                        leftSection={<IconPhoto/>}
                        rightSection={<IconArrowRight/>}
                        ml='md'
                        variant="filled"
                        onClick={onSortClick}
                        size={buttonSize}
                    >
                        Sort
                    </Button>
                    {/*<Button onClick={() => { onLoginClick(); closeDrawer(); }} fullWidth size="md">*/}
                    {/*    Log in*/}
                    {/*</Button>*/}
                    {/*<Button onClick={() => { onRegisterClick(); closeDrawer(); }} fullWidth variant="outline" size="md">*/}
                    {/*    Sign up*/}
                    {/*</Button>*/}
                </Flex>
            </Drawer>
        </Box>
    );
};