import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleAuthCallback } from '../../api/Auth';
import {Flex, Loader, Stack, Text} from "@mantine/core";

interface AuthCallbackProps {
    onSuccessfulSignIn: () => void;
}

export const AuthCallback: React.FC<AuthCallbackProps> = ({ onSuccessfulSignIn }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        const processCallback = async () => {
            const params = new URLSearchParams(location.search);
            const code = params.get('code');

            if (!code) {
                setError('Authorization code is missing');
                navigate('/login');
                return;
            }

            const result = await handleAuthCallback(code);

            if (result.success) {
                onSuccessfulSignIn();
                navigate('/gallery');
            } else {
                setError(result.error || 'Authentication failed');
                navigate('/login');
            }
        };

        processCallback();
    }, [location, navigate, onSuccessfulSignIn]);

    return (
        <Flex h="100vh" align="center" justify="center">
            <Stack justify="center" align="center">
                <Loader/>
                <Text>Completing authentication...</Text>
            </Stack>
        </Flex>
        // <div className="p-4 text-gray-600">
        //     {error ? <div className="text-red-600">Error: {error}</div> : 'Completing authentication...'}
        // </div>
    );
};