import axios from 'axios';
import {PersonBetter} from "../types/Person";
import {Face} from "../types/Face";

const API_BASE_URL = process.env.REACT_APP_PATH_TO_API;

/**
 * Fetches a paginated list of unnamed faces from the API.
 * @param page Page number for pagination
 * @param limit Number of items per page, or 'NONE' for all items
 * @param includeNamed Whether to include named faces (default: false)
 */
export const fetchUnnamedFaces = async (
    page: number = 0,
    limit: number | 'NONE' = 'NONE',
    includeNamed: boolean = false
): Promise<{faces: Face[], hasMore: boolean}> => {
    console.log("in fetchUnnamedFaces");
    try {
        console.log(`calling fetch faces - page: ${page}, limit: ${limit}`);
        const response = await axios.get(`${API_BASE_URL}/faces`, {
            params: {
                page: page.toString(),
                limit: limit.toString(),
                named: includeNamed,
                unnamed: true
            },
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        });
        console.log(response);

        const faces: Face[] = response.data.faces;
        console.log("example face: ", faces[0]);
        const hasMore: boolean = response.data.hasMore;

        console.log('Fetched faces:', faces);
        console.log('Has more faces:', hasMore);

        return { faces, hasMore };
    } catch (error) {
        console.error('Failed to fetch faces:', error);
        throw new Error('Failed to load faces. Please try again later.');
    }
};

/**
 * Fetches faces associated with a specific person, with optional pagination.
 * @param person Person to fetch faces for
 * @param page Page number for pagination
 * @param limit Number of items per page, or 'NONE' for all items
 */
export const fetchFacesForPerson = async (
    person: PersonBetter,
    page?: number,
    limit?: number | 'NONE'
): Promise<{faces: Face[], hasMore: boolean}> => {
    try {
        const response = await axios.get(`${API_BASE_URL}/faces`, {
            params: {
                person_key: person.seed_key,
                page: page?.toString(),
                limit: limit?.toString(),
                named: true,
                unnamed: true
            },
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        });

        const faces: Face[] = response.data.faces;
        const hasMore: boolean = response.data.hasMore;

        console.log('Fetched faces for person:', faces);
        console.log('Has more faces:', hasMore);

        return { faces, hasMore };
    } catch (error) {
        console.error('Failed to fetch faces for selected user:', error);
        throw new Error(`Failed to load faces for ${person.seed_key}. Please try again later.`);
    }
};

/**
 * Fetches faces associated with specific images.
 * @param imageKeys Array of image keys to fetch faces for
 * @param page Optional page number for pagination
 * @param limit Optional number of items per page, or 'NONE' for all items
 */
export const fetchFacesInImages = async (
    imageKeys: string[],
    page?: number,
    limit?: number | 'NONE'
): Promise<{faces: Face[], hasMore: boolean}> => {
    try {
        // If multiple image keys are provided, we'll need to make multiple requests
        // and combine the results
        const requests = imageKeys.map(imageKey =>
            axios.get(`${API_BASE_URL}/faces`, {
                params: {
                    image_key: imageKey,
                    page: page?.toString(),
                    limit: limit?.toString(),
                    named: true,
                    unnamed: true
                },
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            })
        );

        const responses = await Promise.all(requests);

        // Combine all faces from all responses
        const allFaces: Face[] = responses.flatMap(
            response => response.data.faces
        );

        // If any response has hasMore: true, then we have more results
        const hasMore = responses.some(response => response.data.hasMore);

        console.log('Fetched faces for images:', allFaces);
        console.log('Has more faces:', hasMore);

        return { faces: allFaces, hasMore };
    } catch (error) {
        console.error('Failed to fetch faces for images:', error);
        throw new Error('Failed to load faces for images. Please try again later.');
    }
};


/**
 * Labels one or more faces with a person's identity
 * @param faces - Single face or array of faces to label
 * @param person - Optional person to label faces as. If not provided, uses suggested person from face
 */
export const labelFace = async (faces: Face | Face[], person?: PersonBetter): Promise<string[]> => {
    try {
        // Convert single face to array for consistent handling
        const facesArray = Array.isArray(faces) ? faces : [faces];

        // If person is provided, use their key, otherwise use the first face's suggested person key
        const personKey = person?.seed_key ?? (!facesArray[0].is_person?.confirmed && facesArray[0].is_person?.person.seed_key);

        // Verify we have a valid person key
        if (!personKey) {
            throw new Error('No person key provided or found in face suggestion');
        }

        const response = await axios.patch(
            `${API_BASE_URL}/faces`,
            {
                action: 'LABEL',
                faceKeys: facesArray.map(face => face.face_key),
                personKey: personKey
            },
            {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );

        console.log('Label face response:', response);
        let suggest_person_for_faces: string[] = response.data.suggest_person_for_faces || []
        console.log("Faces to add:", suggest_person_for_faces);
        return suggest_person_for_faces;
    } catch (error) {
        console.error('Failed to label face(s):', error);
        if (error instanceof Error) {
            throw error;
        }
        throw new Error('Failed to label face(s). Please try again later.');
    }
};

/**
 * Untracks one or more faces
 * @param faces - Single face or array of faces to untrack
 */
export const untrackFace = async (faces: Face | Face[]): Promise<{ success: boolean }> => {
    try {
        // Convert single face to array for consistent handling
        const facesArray = Array.isArray(faces) ? faces : [faces];

        const response = await axios.patch(
            `${API_BASE_URL}/faces`,
            {
                action: 'UNTRACK',
                faceKeys: facesArray.map(face => face.face_key)
            },
            {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );

        console.log('Untrack face response:', response);
        return { success: response.status === 200 };
    } catch (error) {
        console.error('Failed to untrack face(s):', error);
        if (error instanceof Error) {
            throw error;
        }
        throw new Error('Failed to untrack face(s). Please try again later.');
    }
};

/**
 * Removes person suggestion for one or more faces
 * @param faces - Single face or array of faces to remove suggestion for
 */
export const removeIsPersonSuggestionForFace = async (faces: Face | Face[]): Promise<{ success: boolean }> => {
    try {
        // Convert single face to array for consistent handling
        const facesArray = Array.isArray(faces) ? faces : [faces];
        const personKey = !facesArray[0].is_person?.confirmed && facesArray[0].is_person?.person.seed_key

        const response = await axios.patch(
            `${API_BASE_URL}/faces`,
            {
                action: 'CLEAR-SUGGESTION',
                faceKeys: facesArray.map(face => face.face_key),
                personKey: personKey
            },
            {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }
        );

        console.log('Clear suggestion response:', response);
        return { success: response.status === 200 };
    } catch (error) {
        console.error('Failed to remove is person suggestion for face(s):', error);
        if (error instanceof Error) {
            throw error;
        }
        throw new Error('Failed to remove is person suggestion for face(s). Please try again later.');
    }
};