import { FileWithPath } from '@mantine/dropzone';
import { v4 as uuidv4 } from 'uuid';
import UploadWebSocket from './UploadWebSocket';
import {useAuth} from "../UseAuthHook";

// todo: THIS IS A CHATGPT FRANKENSTEIN & NEEDS HELP!!

// Numeric status values for easier comparison and counting
export enum UploadStatusValue {
    Failed = -1,
    Pending = 0,
    Uploading = 0.5,
    Uploaded = 1,
    Processing = 1.5,
    Processed = 2
}

export type UploadStatusString = 'pending' | 'uploading' | 'uploaded' | 'processing' | 'processed' | 'failed';

export type ConnectionStatus = 'connecting' | 'connected' | 'disconnected';

export interface UploadingFile {
    file: FileWithPath;
    fileId: string;
    fileName: string;
    fileType: string;
    status: UploadStatusString;
    statusValue: UploadStatusValue;
    progress: number;
    error?: string;
}

export interface UploadStats {
    uploadCount: number;
    uploadLimit: number;
    remainingUploads: number;
}

export interface UploadProgress {
    uploadProgress: number;
    processingProgress: number;
    duplicateDetectionProgress: number;
    totalFiles: number;
    uploadedFiles: number;
    processedFiles: number;
    duplicateDetectionComplete: boolean;
}

type UploadServiceCallback = (files: UploadingFile[], progress: UploadProgress, connectionStatus: ConnectionStatus) => void;

// Debug helper function
const logDebug = (message: string, data?: any) => {
    const timestamp = new Date().toISOString();
    console.log(`[${timestamp}] ${message}`);
    if (data !== undefined) {
        console.log(JSON.stringify(data, null, 2));
    }
};

// Helper to convert string status to value
const getStatusValue = (status: UploadStatusString): UploadStatusValue => {
    switch(status) {
        case 'pending': return UploadStatusValue.Pending;
        case 'uploading': return UploadStatusValue.Uploading;
        case 'uploaded': return UploadStatusValue.Uploaded;
        case 'processing': return UploadStatusValue.Processing;
        case 'processed': return UploadStatusValue.Processed;
        case 'failed': return UploadStatusValue.Failed;
    }
};

export class UploadService {
    private websocket: UploadWebSocket | null = null;
    private files: UploadingFile[] = [];
    private connectionStatus: ConnectionStatus = 'disconnected';
    private callback: UploadServiceCallback;
    private duplicateDetectionComplete: boolean = false;

    constructor(callback: UploadServiceCallback) {
        this.callback = callback;
        logDebug('UploadService initialized');
    }

    public getConnectionStatus(): ConnectionStatus {
        return this.connectionStatus;
    }

    public getFiles(): UploadingFile[] {
        return [...this.files];
    }

    // Get count of files with at least the specified status value
    public getFileCountByMinStatus(minStatusValue: UploadStatusValue): number {
        return this.files.filter(file => file.statusValue >= minStatusValue).length;
    }

    // Debug method to log current file statuses
    public logFileStatuses(): void {
        logDebug('Current file statuses:');
        this.files.forEach(file => {
            logDebug(`File ${file.fileId} (${file.fileName}): status=${file.status}, statusValue=${file.statusValue}, progress=${file.progress}`);
        });
    }

    public async initialize(): Promise<void> {
        logDebug('Initializing UploadService');
        this.setConnectionStatus('connecting');

        this.websocket = new UploadWebSocket(
            this.handleWebSocketMessage.bind(this),
            this.handleWebSocketError.bind(this),
            this.handleWebSocketClose.bind(this)
        );

        try {
            await this.websocket.connect();
            this.setConnectionStatus('connected');
            logDebug('WebSocket connected successfully');
        } catch (error) {
            logDebug('Failed to connect WebSocket:', error);
            this.setConnectionStatus('disconnected');
            throw error;
        }
    }

    public async uploadFiles(acceptedFiles: FileWithPath[], remainingUploads: number): Promise<void> {
        if (acceptedFiles.length === 0) return;

        logDebug(`Preparing to upload ${acceptedFiles.length} files (remaining: ${remainingUploads})`);

        // Prepare files
        const newFiles = acceptedFiles.map(file => ({
            file,
            fileId: uuidv4(),
            fileName: file.name,
            fileType: file.type || 'image/jpeg',
            status: 'pending' as UploadStatusString,
            statusValue: UploadStatusValue.Pending,
            progress: 0
        }));

        // Limit files to remaining upload count
        const filesToUpload = newFiles.slice(0, remainingUploads);

        if (filesToUpload.length < newFiles.length) {
            logDebug(`Only uploading ${filesToUpload.length} of ${newFiles.length} files due to upload limit`);
        }


        // Add files to the internal array
        this.files = [...this.files, ...filesToUpload];
        this.updateCallback();

        // Request pre-signed URLs
        try {
            await this.ensureWebSocketConnection();

            const requestPayload = {
                action: 'request-upload',
                type: 'request-upload',
                files: filesToUpload.map(file => ({
                    fileId: file.fileId,
                    fileName: file.fileName,
                    fileType: file.fileType
                }))
            };

            logDebug('Sending request-upload payload', requestPayload);
            await this.websocket!.send(requestPayload);
        } catch (error) {
            logDebug('Error requesting upload URLs:', error);
            throw error;
        }
    }

    public cleanup(): void {
        logDebug('Cleaning up UploadService');
        if (this.websocket) {
            this.websocket.close();
        }
        this.files = [];
        this.connectionStatus = 'disconnected';
    }

    private async ensureWebSocketConnection(): Promise<UploadWebSocket> {
        logDebug('Ensuring WebSocket connection');
        if (!this.websocket) {
            logDebug('WebSocket not initialized, initializing...');
            await this.initialize();
            return this.websocket!;
        }

        if (this.connectionStatus !== 'connected') {
            logDebug('WebSocket not connected, reconnecting...');
            await this.websocket.connect();
        }

        return this.websocket;
    }

    private handleWebSocketMessage(data: any): void {
        logDebug('WebSocket message received:', data);

        switch (data.type) {
            case 'upload-url':
                logDebug(`Received upload URL for file ${data.fileId}`);
                this.updateFileStatus(data.fileId, 'uploading');
                this.uploadFileToS3(data.fileId, data.presignedPost);
                break;

            case 'confirm-upload':
                logDebug(`Upload confirmed for file ${data.fileId}`);
                this.updateFileStatus(data.fileId, 'uploaded');
                break;

            case 'processing-status':
                if (data.status === 'error') {
                    logDebug(`Processing error for file ${data.fileId}: ${data.message}`);
                    this.updateFileWithError(data.fileId, 'failed', data.message);
                } else {
                    logDebug(`Processing status update for file ${data.fileId}: ${data.status}`);
                    // Make sure to update to processing status if we get a processing-status update
                    this.updateFileStatus(data.fileId, 'processing');
                }
                break;

            case 'processing-complete':
                logDebug(`Processing complete for file ${data.fileId}`);
                this.updateFileStatus(data.fileId, 'processed', () => {
                    logDebug('Running post-processing callback');
                    this.checkAllProcessed();
                });
                break;

            case 'status':
                if (data.status === 'duplicate-detect-complete') {
                    logDebug('Duplicate detection complete');
                    this.duplicateDetectionComplete = true;
                    this.updateCallback();
                }
                break;

            default:
                logDebug(`Unhandled message type: ${data.type}`);
                break;
        }
    }

    private handleWebSocketError(error: Event): void {
        logDebug('WebSocket error:', error);
        this.setConnectionStatus('disconnected');
    }

    private handleWebSocketClose(event: CloseEvent): void {
        logDebug(`WebSocket closed: code=${event.code}, reason=${event.reason}`);
        this.setConnectionStatus('disconnected');
    }

    private setConnectionStatus(status: ConnectionStatus): void {
        logDebug(`Updating connection status: ${this.connectionStatus} -> ${status}`);
        this.connectionStatus = status;
        this.updateCallback();
    }

    private updateFileStatus(fileId: string, status: UploadStatusString, callback?: () => void): void {
        logDebug(`Updating file ${fileId} status to ${status}`);

        // Extract the UUID part if the fileId contains a slash
        const actualFileId = fileId.includes('/') ? fileId.split('/').pop()! : fileId;

        const statusValue = getStatusValue(status);
        let fileUpdated = false;

        this.files = this.files.map(file => {
            if (file.fileId === actualFileId) {
                fileUpdated = true;
                return { ...file, status, statusValue };
            }
            return file;
        });

        if (!fileUpdated) {
            logDebug(`WARNING: File ${fileId} not found when attempting status update to ${status}`);
        }

        this.updateCallback(callback);
        this.logFileStatuses(); // Log all file statuses after update
    }

    private updateFileWithError(fileId: string, status: UploadStatusString, error: string): void {
        logDebug(`Updating file ${fileId} with error: ${error}`);

        // Extract the UUID part if the fileId contains a slash
        const actualFileId = fileId.includes('/') ? fileId.split('/').pop()! : fileId;

        const statusValue = getStatusValue(status);
        let fileUpdated = false;

        this.files = this.files.map(file => {
            if (file.fileId === actualFileId) {
                fileUpdated = true;
                return { ...file, status, statusValue, error };
            }
            return file;
        });

        if (!fileUpdated) {
            logDebug(`WARNING: File ${fileId} not found when attempting to update with error`);
        }

        this.updateCallback();
        this.logFileStatuses(); // Log all file statuses after update
    }

    private updateFileProgress(fileId: string, progress: number): void {
        // Don't log every progress update to avoid log spam
        if (progress % 10 === 0) {
            logDebug(`Updating file ${fileId} progress to ${progress}%`);
        }

        let fileUpdated = false;

        //  Extract the UUID part if the fileId contains a slash
        const actualFileId = fileId.includes('/') ? fileId.split('/').pop()! : fileId;

        // Rest of the method unchanged, but use actualFileId for the comparison
        this.files = this.files.map(file => {
            if (file.fileId === actualFileId) {
                fileUpdated = true;
                return { ...file, progress };
            }
            return file;
        });

        if (!fileUpdated && progress === 100) {
            logDebug(`WARNING: File ${fileId} not found when attempting progress update to ${progress}%`);
        }

        this.updateCallback();
    }

    private updateCallback(callback?: () => void): void {
        const totalCount = this.files.length;

        // Use the numeric status values for counting
        const uploadedCount = this.getFileCountByMinStatus(UploadStatusValue.Uploaded);
        const processedCount = this.getFileCountByMinStatus(UploadStatusValue.Processed);

        const uploadProgress = totalCount > 0 ? (uploadedCount / totalCount) * 100 : 0;
        const processingProgress = uploadedCount > 0 ? (processedCount / uploadedCount) * 100 : 0;

        const progressData = {
            uploadProgress,
            processingProgress,
            duplicateDetectionProgress: this.duplicateDetectionComplete ? 100 : (processedCount === totalCount && totalCount > 0 ? 50 : 0),
            totalFiles: totalCount,
            uploadedFiles: uploadedCount,
            processedFiles: processedCount,
            duplicateDetectionComplete: this.duplicateDetectionComplete
        };

        // Only log on significant changes to reduce log spam
        if (totalCount > 0 &&
            (uploadProgress % 20 === 0 ||
                processingProgress % 20 === 0 ||
                processedCount === totalCount)) {
            logDebug('Progress update', progressData);
        }

        this.callback(this.files, progressData, this.connectionStatus);

        // Execute the callback after updating UI
        if (callback) {
            logDebug('Executing post-update callback');
            setTimeout(callback, 0); // Ensure callback runs after the UI update
        }
    }

    private async uploadFileToS3(fileId: string, presignedPost: any): Promise<void> {
        logDebug(`Starting S3 upload for file ${fileId}`);
        try {
            const file = this.files.find(f => f.fileId === fileId);
            if (!file) {
                logDebug(`ERROR: File ${fileId} not found for S3 upload`);
                return;
            }

            const formData = new FormData();

            // Add the fields from the presigned post to the form data
            Object.entries(presignedPost.fields || {}).forEach(([key, value]) => {
                formData.append(key, value as string);
            });

            // Add the file as the last field
            formData.append('file', file.file);

            // Make the request with progress tracking
            const xhr = new XMLHttpRequest();
            xhr.open('POST', presignedPost.url, true);

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const progress = Math.round((event.loaded / event.total) * 100);
                    this.updateFileProgress(fileId, progress);
                }
            };

            xhr.onload = async () => {
                if (xhr.status >= 200 && xhr.status < 300) {
                    logDebug(`File ${fileId} uploaded successfully to S3`);
                    // Here we update both status and progress
                    this.updateFileStatus(fileId, 'uploaded');
                    this.updateFileProgress(fileId, 100);

                    // Add a delay before starting processing to ensure status is updated
                    setTimeout(() => {
                        this.startProcessingFile(fileId);
                    }, 100);
                } else {
                    logDebug(`Failed to upload file ${fileId} to S3: ${xhr.status} ${xhr.statusText}`);
                    this.updateFileWithError(fileId, 'failed', `Upload failed: ${xhr.status} ${xhr.statusText}`);
                }
            };

            xhr.onerror = () => {
                logDebug(`Network error uploading file ${fileId}`);
                this.updateFileWithError(fileId, 'failed', 'Upload failed due to network error');
            };

            logDebug(`Sending form data to S3 for file ${fileId}`);
            xhr.send(formData);
        } catch (error) {
            logDebug(`Error in S3 upload for file ${fileId}:`, error);
            this.updateFileWithError(fileId, 'failed', `Upload error: ${error}`);
        }
    }

    private async startProcessingFile(fileId: string): Promise<void> {
        logDebug(`Starting processing for file ${fileId}`);
        try {
            const ws = await this.ensureWebSocketConnection();

            const processingPayload = {
                type: 'start-processing',
                action: 'start-processing',
                file: { fileId }
            };

            logDebug('Sending start-processing payload', processingPayload);
            await ws.send(processingPayload);

            // Update the file status to processing after sending the request
            this.updateFileStatus(fileId, 'processing');
        } catch (error) {
            logDebug(`Error starting processing for file ${fileId}:`, error);
            this.updateFileWithError(fileId, 'failed', `Processing request failed: ${error}`);
        }
    }

    private checkAllProcessed(): void {
        logDebug('Checking if all files are processed');

        // Use the numeric status values to simplify this check
        const allProcessed = this.files.every(file =>
            file.statusValue === UploadStatusValue.Processed ||
            file.statusValue === UploadStatusValue.Failed
        );

        logDebug(`All processed check result: ${allProcessed}`);

        if (allProcessed && this.websocket && this.files.length > 0) {
            // Get the fileIds of all processed files
            const processedFileIds = this.files
                .filter(file => file.statusValue === UploadStatusValue.Processed)
                .map(file => file.fileId);

            if (processedFileIds.length > 0) {
                const duplicatePayload = {
                    action: 'detect-duplicates',
                    type: 'detect-duplicates',
                    imageKeys: processedFileIds
                };

                logDebug('Sending detect-duplicates payload', duplicatePayload);
                this.websocket.send(duplicatePayload);
                logDebug('All files processed, detect-duplicates request sent');
            } else {
                logDebug('No successfully processed files to send for duplicate detection');
            }
        } else {
            // If not all files are processed yet, log the current state
            const pendingCount = this.files.filter(f => f.statusValue === UploadStatusValue.Pending).length;
            const uploadingCount = this.files.filter(f => f.statusValue === UploadStatusValue.Uploading).length;
            const uploadedCount = this.files.filter(f => f.statusValue === UploadStatusValue.Uploaded).length;
            const processingCount = this.files.filter(f => f.statusValue === UploadStatusValue.Processing).length;
            const processedCount = this.files.filter(f => f.statusValue === UploadStatusValue.Processed).length;
            const failedCount = this.files.filter(f => f.statusValue === UploadStatusValue.Failed).length;

            logDebug(`Files not all processed yet. Current counts: 
                Pending: ${pendingCount}
                Uploading: ${uploadingCount}
                Uploaded: ${uploadedCount}
                Processing: ${processingCount}
                Processed: ${processedCount}
                Failed: ${failedCount}
                Total: ${this.files.length}`);
        }
    }
}