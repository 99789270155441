import {Stack, Text} from '@mantine/core';
import {Hero} from "./Hero";
import {PricingCards} from "./Pricing";
import {FeaturesGrid} from "./Features";
import {FeaturesSection} from "./Steps"
import {Header} from "./Header";
import {AuthenticatedHeader} from "./AuthenticatedHeader";
import React from "react";
import { useAuth } from '../UseAuthHook'; // Import the useAuth hook

import {useNavigate} from 'react-router-dom';
import {Footer} from "./Footer";

export const LandingPage: React.FC = () => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();

    return (
        <Stack
            bg="var(--mantine-color-body)"
            align="stretch"
            justify="flex-start"
            gap={0}
        >
            {isAuthenticated? (<AuthenticatedHeader onSortClick={() => navigate('/gallery')}/>)
            : (<Header onLoginClick={() => navigate('/login')} onRegisterClick={() => navigate('/signup')}/>)}

            <div id="hero">
                <Hero callToAction={isAuthenticated? "Start Organizing" : "Try It For Free"} onCallToActionClick={isAuthenticated? () => navigate('/gallery') : () => navigate('/signup')}/>
            </div>
            <div id="steps">
                <FeaturesSection/>
            </div>
            <div id="features">
                <FeaturesGrid/>
            </div>
            <div id="pricing">
                <PricingCards/>
            </div>
            <Text ta='center'>* = coming soon!</Text>
            <Footer/>
        </Stack>
    );
}

