import React, { useState } from 'react';
import { AppShell, Burger, Container, Flex } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LeftNavMenu } from './components/LeftNavMenu';
import { FamilyTree } from './components/familyTree/FamilyTree';
import { Gallery } from './components/gallery/Gallery';
import { IdentifyFaces } from './components/identifyFaces/IdentifyFaces';
import { GroupImagesManually } from './components/sortImages/GroupImagesManually';
import { DeleteDuplicates } from './components/removeDuplicates/DeleteDuplicates';
import { FileUploader } from './components/FileUploader';
import { UserMenu } from './UserDropdown';
import { useAuth } from './UseAuthHook';

import './logo-font.css';
export const Logo = () => (
    <svg width="300" height="30" viewBox="0 0 300 30">
        <text
            x="0"
            y="25"
            fontFamily="Oswald, sans-serif"
            fontSize="24"
            fontWeight="500"
            fill="currentColor"
        >
            SORT MY SHOEBOX
        </text>
    </svg>
);

export function AuthenticatedLayout() {
    // const { userProfile, handleLogout } = useAuth();
    const [opened, setOpened] = useState(false);
    const extraPadding = useMediaQuery('(min-width: 1440px)');

    const toggle = () => setOpened((o) => !o);

    // todo: figure out why nav bar is weird for mobile? when the drawer is 'open' the contents only appears on the right part of the screen.
    //  also when the user clicks a page, id like to redirect to that element and close mobile drawer.
    return (
        <AppShell
            header={{ height: 85 }}
            navbar={{
                width: { base: '100%', sm: '300px' },
                breakpoint: 'sm',
                collapsed: { mobile: !opened },
            }}
            padding="md"
        >
            <AppShell.Header>
                <Container size="100%" h="100%">
                    <Flex justify="space-between" align="center" h="100%" ml={extraPadding ? '10vw' : '10px'} mr={extraPadding ? '10vw' : '10px'}>
                        <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
                        <Logo />
                        <UserMenu/>
                    </Flex>
                </Container>
            </AppShell.Header>

            <AppShell.Navbar p="md" style={{ marginLeft: extraPadding ? '10vw' : '10px', borderRight: '1px solid #ccc' }}>
                <Flex direction="column" style={{ height: '100%', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
                    <LeftNavMenu />
                </Flex>
            </AppShell.Navbar>

            <AppShell.Main>
                <Container size="100%" style={{ marginLeft: extraPadding ? '10vw' : '10px', marginRight: extraPadding ? '10vw' : '10px' }}>
                    <Routes>
                        <Route path="/gallery" element={<Gallery />} />
                        <Route path="/family-tree" element={<FamilyTree />} />
                        <Route path="/identify-faces" element={<IdentifyFaces />} />
                        <Route path="/sort-images" element={<GroupImagesManually />} />
                        <Route path="/remove-duplicates" element={<DeleteDuplicates />} />
                        <Route path="/import" element={<FileUploader />} />
                        <Route path="/" element={<Navigate to="/gallery"/>} />
                    </Routes>
                </Container>
            </AppShell.Main>
        </AppShell>
    );
}
