import {Anchor, Box, Container, Flex, Group} from "@mantine/core";
import React from "react";

const footerLinks = [
    {link: 'mailto:grace@sortmyshoebox.com', label: 'Contact'},
    // { link: '#', label: 'Privacy' },
    // { link: '#', label: 'Blog' },
    // { link: '#', label: 'Careers' },
];

export function Footer() {
    const items = footerLinks.map((link) => (
        <Anchor<'a'>
            c="dimmed"
            key={link.label}
            href={link.link}
            size="sm"
        >
            {link.label}
        </Anchor>
    ));

    return (
        <Box w="100%" bg="#f5f5f5" px={40} pb={20} pt="md"> {/* Grey background for footer */}
            <Container fluid>
                <Flex justify="space-between" align="center">
                    <img src="./logo.png" alt="Company Logo" style={{width: 52}}/>
                    <Group>{items}</Group>
                </Flex>
            </Container>
        </Box>
    );
}