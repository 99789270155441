import { v4 as uuidv4 } from 'uuid';

type MessageHandler = (data: any) => void;
type ErrorHandler = (error: Event) => void;
type CloseHandler = (event: CloseEvent) => void;

class UploadWebSocket {
    private socket: WebSocket | null;
    private isConnected: boolean;
    private connectPromise: Promise<WebSocket> | null;
    private onMessage: MessageHandler;
    private onError: ErrorHandler;
    private onClose?: CloseHandler;
    private reconnectAttempts: number;
    private maxReconnectAttempts: number;
    private reconnectDelay: number;

    constructor(
        onMessage: MessageHandler,
        onError: ErrorHandler,
        onClose?: CloseHandler,
        private readonly wsUrl: string = 'fake!' // wss://dwti8un0p3.execute-api.us-west-2.amazonaws.com/production/'
    ) {
        this.socket = null;
        this.isConnected = false;
        this.connectPromise = null;
        this.onMessage = onMessage;
        this.onError = onError;
        this.onClose = onClose;
        this.reconnectAttempts = 0;
        this.maxReconnectAttempts = 5;
        this.reconnectDelay = 1000; // Start with 1 second delay
    }

    connect(): Promise<WebSocket> {
        // If we're already connecting, return the existing promise
        if (this.connectPromise) return this.connectPromise;

        // If we're already connected, return a resolved promise with the socket
        if (this.isConnected && this.socket) {
            return Promise.resolve(this.socket);
        }

        this.connectPromise = new Promise((resolve, reject) => {
            const idToken = sessionStorage.getItem('token');
            if (!idToken) {
                reject(new Error('No ID token available. User might not be authenticated.'));
                return;
            }

            let wsUrl = process.env.REACT_APP_WEBSOCKET
            const fullWsUrl = `${wsUrl}?Authorization=${idToken}`;
            this.socket = new WebSocket(fullWsUrl);

            // Set a connection timeout
            const timeoutId = setTimeout(() => {
                if (this.socket && this.socket.readyState !== WebSocket.OPEN) {
                    this.socket.close();
                    this.connectPromise = null;
                    reject(new Error('WebSocket connection timeout'));
                }
            }, 10000); // 10 second timeout

            this.socket.onopen = () => {
                console.log('WebSocket connection established');
                clearTimeout(timeoutId);
                this.isConnected = true;
                this.reconnectAttempts = 0; // Reset reconnect attempts on successful connection
                this.reconnectDelay = 1000; // Reset reconnect delay
                resolve(this.socket!);
            };

            this.socket.onmessage = (event: MessageEvent) => {
                try {
                    const data = JSON.parse(event.data);
                    console.log('Received WebSocket message:', data);
                    this.onMessage(data);
                } catch (error) {
                    console.error('Failed to parse WebSocket message:', error);
                }
            };

            this.socket.onerror = (error: Event) => {
                console.error('WebSocket error:', error);
                clearTimeout(timeoutId);
                this.onError(error);
                // Don't reject the promise here, let onclose handle it
            };

            this.socket.onclose = (event: CloseEvent) => {
                console.log('WebSocket connection closed:', event.code, event.reason);
                clearTimeout(timeoutId);
                this.isConnected = false;
                this.connectPromise = null;

                if (this.onClose) this.onClose(event);

                // Don't reject the promise if we're already connected
                if (!this.isConnected) {
                    reject(new Error(`WebSocket closed: ${event.code} ${event.reason}`));
                }

                // Attempt to reconnect if we haven't exceeded max attempts
                // and it wasn't a normal closure
                if (this.reconnectAttempts < this.maxReconnectAttempts &&
                    event.code !== 1000) {
                    this.reconnectAttempts++;
                    // Use exponential backoff
                    const delay = this.reconnectDelay * Math.pow(1.5, this.reconnectAttempts - 1);
                    console.log(`Attempting to reconnect in ${delay}ms (attempt ${this.reconnectAttempts}/${this.maxReconnectAttempts})`);

                    setTimeout(() => {
                        this.connect().catch(err => {
                            console.error('Reconnection attempt failed:', err);
                        });
                    }, delay);
                }
            };
        });

        return this.connectPromise;
    }

    send(message: any): Promise<void> {
        // Always ensure we're connected before sending
        return this.connect().then((socket) => {
            if (socket.readyState !== WebSocket.OPEN) {
                throw new Error('WebSocket not in OPEN state');
            }
            console.log('Sending WebSocket message:', message);
            socket.send(JSON.stringify(message));
        });
    }

    isOpen(): boolean {
        return this.isConnected && this.socket?.readyState === WebSocket.OPEN;
    }

    close(): void {
        if (this.socket) {
            // Prevent reconnection attempts on manual close
            this.reconnectAttempts = this.maxReconnectAttempts;
            this.socket.close();
            this.isConnected = false;
            this.connectPromise = null;
        }
    }
}

export default UploadWebSocket;