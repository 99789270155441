import React from "react";
import {useAuth} from "./UseAuthHook";
import {Navigate, useLocation} from "react-router-dom";

interface ProtectedRouteProps {
    children: React.ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({children}) => {
    const {isAuthenticated, loading} = useAuth();
    const location = useLocation();

    console.log("from protected routes..", isAuthenticated, loading);
    if (loading) {
        return <div>Loading...</div>; // or some spinner
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{from: location}} replace/>;
    }

    return <>{children}</>;
};