import React, { useEffect, useState } from 'react';
import { Modal } from '@mantine/core';
import { PersonBetter } from '../../types/Person';
import { PersonDetailsModal } from '../familyTree/PersonDetailsModal';
import { updatePerson } from '../../api/People';

export interface AddFacesToFamilyTreeModalProps {
    person: PersonBetter | null;
    onClose: () => void;
}

export const AddFacesModal: React.FC<AddFacesToFamilyTreeModalProps> = ({
                                                                            person,
                                                                            onClose,
                                                                        }) => {
    const [currentPerson, setCurrentPerson] = useState<PersonBetter | null>(person);

    // Update internal state when the passed `person` changes
    useEffect(() => {
        setCurrentPerson(person);
    }, [person]);

    if (!currentPerson) {
        return <Modal opened={true} onClose={onClose} size="lg" title="Add New Family Member">Loading...</Modal>;
    }

    return (
        <Modal opened={true} onClose={onClose} size="lg" title="Add New Family Member">
        <PersonDetailsModal
            person={currentPerson}
            onClose={onClose}
            onUpdate={async (updatedPerson: PersonBetter) => {
                const updated = await updatePerson(updatedPerson);
                console.log('updated person:', updated);
                setCurrentPerson(updated);
            }}
        />
        </Modal>
    );
};