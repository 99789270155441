import React from 'react';
import { ThemeIcon, Text, Title, Container, SimpleGrid, rem, Group, Badge, Box } from '@mantine/core';
import { IconCalendarTime, IconUsers, IconShare, IconScan, IconCopy, IconTags, IconPhoto, IconPuzzle, IconAbc, IconFaceId } from '@tabler/icons-react';
import classes from './Features.module.css';
import {useViewportSize} from "@mantine/hooks";

const FEATURES = [
    {
        icon: IconPuzzle,
        comingSoon: false,
        title: 'Event Clustering',
        description: 'We\'ll help you identify & group photos from the same event, making it easy to combine collections and relive special moments.'
    },
    {
        icon: IconCalendarTime,
        comingSoon: false,
        title: 'Smart Date Estimation',
        description: 'Our AI-engine analyzes clothing styles, image quality, and other contextual clues to estimate dates for your digital collection.'
    },
    {
        icon: IconCopy,
        comingSoon: false,
        title: 'Intelligent Duplicate Removal',
        description: 'We go beyond metadata to detect duplicate images by comparing the contents of each photo.'
    },
    {
        icon: IconUsers,
        comingSoon: false,
        title: 'Face Recognition',
        description: 'Easily name faces in your images and organize photos by people. Find all photos of specific family members with a single click.'
    },
    {
        icon: IconTags,
        comingSoon: false,
        title: 'Keyword Tagging',
        description: 'Automatically tag images with keywords. Our AI will recognize tags such as events, places, and activities.'
    },
    {
        icon: IconAbc,
        comingSoon: false,
        title: 'Context-Aware Captions*',
        description: 'Generate intelligent captions using image analysis and family tree info, preserving the story behind the image for future generations.'
    },
    // Commented out "Coming Soon" features
    // {
    //     icon: IconPhoto,
    //     comingSoon: true,
    //     title: 'Photo Restoration',
    //     description: 'Revive old or damaged photos with our cutting-edge restoration technology.'
    // },
    // {
    //     icon: IconUsers,
    //     title: 'Kinship Recognition',
    //     comingSoon: true,
    //     description: 'Discover family connections with facial analysis that suggests kinship relationships in your photos.'
    // },
    // {
    //     icon: IconShare,
    //     title: 'Easy Sharing',
    //     comingSoon: true,
    //     description: 'Easily share photos via social media or download links using our cloud storage system.'
    // },
    // {
    //     icon: IconScan,
    //     title: 'Photo Digitization',
    //     comingSoon: true,
    //     description: 'Use our smartphone app to scan and upload images directly to your SortMyShoebox account.'
    // },
];

function Feature({ icon: Icon, title, description, comingSoon }: { icon: React.FC<any>; title: string; description: string; comingSoon: boolean }) {
    const { width } = useViewportSize();
    const isMobile = width < 768;

    return (
        <Box
            p={isMobile ? "xl" : "lg"}
            style={{
                backgroundColor: '#e5f3ff',
                borderRadius: '12px',
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <ThemeIcon variant="light" size={50} radius={40} color="#0969ff">
                <Icon c="white" style={{ width: rem(24), height: rem(24) }} stroke={1.5} />
            </ThemeIcon>
            <Group mt="md" align="flex-end" justify={"space-between"} mb="xs">
                <Text fw={400} style={{ fontFamily: 'Bebas Neue', fontSize: '2.0rem', lineHeight: 1.2 }}>
                    {title}
                </Text>
                {/*{comingSoon && <Badge color="white" size="lg">Coming Soon</Badge>}*/}
            </Group>
            <Text size="md" c="black" lh={1.6} style={{ fontSize: '1.1rem', opacity: 0.9, marginTop: '0.5rem' }}>
                {description}
            </Text>
        </Box>
    );
}

export function FeaturesGrid() {
    const { width } = useViewportSize();
    const isMobile = width < 768;
    const features = FEATURES.map((feature, index) => <Feature {...feature} key={index} />);

    return (
        <div style={{ width: '100%', backgroundColor: '#e5f3ff', padding: isMobile ? '3rem 1rem' : '5rem 0' }}>
            <Container className={classes.wrapper} size={isMobile ? "100%" : "lg"}>
                <Title
                    mt='xl' mb='lg'
                    c={'blue'}
                    order={2}
                    className={classes.title}
                    ta="center"
                    style={{
                        fontFamily: 'Bebas Neue',
                        fontStyle: 'bold',
                        fontSize: isMobile ? '3rem' : '5rem',
                        textTransform: 'uppercase',
                        whiteSpace: 'nowrap',
                        fontWeight: 400,
                        lineHeight: 0.9
                    }}
                >
                    Powerful Sorting Features
                </Title>

                <Container size={660} p={0} mb={isMobile ? 40 : 60}>
                    <Text
                        c="black"
                        className={classes.description}
                        ta="center"
                        mt="md"
                        style={{ fontSize: '1.2rem', opacity: 1.0 }}
                    >
                        With our innovative AI-powered functionality, it has never been easier to preserve, organize, and explore your family's history.
                    </Text>
                </Container>

                <SimpleGrid
                    cols={{ base: 1, sm: 2, md: 3 }}
                    spacing={{ base: 40, sm: 30, md: 40 }}
                    verticalSpacing={{ base: 40, sm: 40, md: 50 }}
                >
                    {features}
                </SimpleGrid>
            </Container>
        </div>
    );
}

export default FeaturesGrid;