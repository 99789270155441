import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    Blockquote,
    Title,
    Flex,
    Paper,
    Button,
    Image,
    Divider,
    PaperProps,
    Group,
    Skeleton,
    Text,
    Center, ThemeIcon, Stack, Anchor
} from '@mantine/core';
import {IconInfoCircle, IconCircleCheck} from "@tabler/icons-react";
import ImageWithSkeleton from "../ImageWithSkeleton";
import {DuplicateImageGroup} from "../../types/Image";
import { shouldLoadMore } from '../gallery/Utils'; // todo: use
import { fetchDuplicates, mergeDuplicatesInImage} from './API';
import {Link} from "react-router-dom"; // Assuming Faces.ts exports these methods

const GROUPS_PER_PAGE = 20;

export function DeleteDuplicates() {
    const [loading, setLoading] = useState(false);
    const [duplicateGroups, setDuplicateGroups] = useState<DuplicateImageGroup[]>([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const loadingRef = useRef(false);
    const hasMoreRef = useRef(true);

    const loadMoreGroups = useCallback(async (currentPage: number) => {
        if (loadingRef.current || !hasMoreRef.current) return;

        loadingRef.current = true;
        setLoading(true);

        try {
            const response = await fetchDuplicates(currentPage, GROUPS_PER_PAGE);
            if (response.duplicates.length > 0) {
                setDuplicateGroups(prev => [...prev, ...response.duplicates]);
                setHasMore(response.hasMore);
                hasMoreRef.current = response.hasMore;
            } else {
                setHasMore(false);
                hasMoreRef.current = false;
            }
        } catch (error) {
            console.error('Error fetching duplicate groups:', error);
            setHasMore(false);
            hasMoreRef.current = false;
        } finally {
            setLoading(false);
            loadingRef.current = false;
        }
    }, []);

    useEffect(() => {
        setDuplicateGroups([]);
        setPage(1);
        setHasMore(true);
        hasMoreRef.current = true;
        loadMoreGroups(1);
    }, [loadMoreGroups]);

    const handleScroll = useCallback(() => {
        if (
            window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100 &&
            !loadingRef.current &&
            hasMoreRef.current
        ) {
            setPage(prevPage => {
                loadMoreGroups(prevPage + 1);
                return prevPage + 1;
            });
        }
    }, [loadMoreGroups]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    const mergeDuplicates = async (group: DuplicateImageGroup) => {
        const updatedGroups = duplicateGroups.filter(dupGroup => dupGroup.key !== group.key);
        setDuplicateGroups(updatedGroups);

        try {
            await mergeDuplicatesInImage(group);
        } catch (error) {
            console.error('Error merging duplicates:', error);
            setDuplicateGroups(currentGroups => [...currentGroups, group]);
            alert('Failed to merge duplicates. Please try again.');
        }
    };

    const DuplicateGroupsSkeleton = () => (
        <div>
            {[...Array(3)].map((_, idx) => (
                <Paper key={idx} shadow="sm" radius="lg" withBorder p="xl" mt={idx > 0 ? 'lg' : undefined}>
                    <Skeleton height={28} width="40%" radius="xl" />
                    <Divider mt="sm" />
                    <Flex mt="lg" wrap="wrap" gap="5px">
                        {[...Array(2)].map((_, i) => (
                            <Skeleton key={i} width="calc((100% - 15px) / 3)" radius="md" style={{ aspectRatio: 1 }} />
                        ))}
                    </Flex>
                    <Group justify="flex-end" gap="sm" mt="lg">
                        <Skeleton height={36} width={100} radius="md" />
                        <Skeleton height={36} width={140} radius="md" />
                    </Group>
                </Paper>
            ))}
        </div>
    );

    return (
        <Flex direction="column">
            <Title order={1}>Remove Duplicates</Title>
            <Divider mt="xs"/>
            <Blockquote color="blue" icon={<IconInfoCircle/>} mt="lg" mb="lg">
                Deleting duplicates ensures a clutter-free photo collection while merging all relevant metadata from
                duplicate photos.
            </Blockquote>
            {loading && <DuplicateGroupsSkeleton/>}
            {duplicateGroups.length === 0 ? (
                <Paper w="100%" mt="md" shadow="md" radius="md" withBorder p="xl">
                    <Stack align="center" gap="md">
                        <ThemeIcon size="xl" radius="xl" color="blue">
                            <IconCircleCheck size={32}/>
                        </ThemeIcon>
                        <Text size="lg" w={600} ta="center">
                            Looks like you don't have any duplicates! 🎉
                        </Text>
                        <Text c="gray.6" ta="center">
                            <Anchor component={Link} to="/import" underline="always">Import</Anchor> more photos and keep your photo collection clutter-free.
                        </Text>
                    </Stack>
                </Paper>
            ) : (
                duplicateGroups.map(group => (
                    <Paper key={group.key} shadow="sm" radius="lg" withBorder p="xl" mt="lg">
                        <Title order={2}>Duplicate Images</Title>
                        <Divider mt="sm"/>
                        <Flex mt="lg" wrap="wrap" gap="5px">
                            {group.images.map((image, idx) => (
                                <div key={image.key} style={{width: 'calc((100% - 15px) / 3)', aspectRatio: 1}}>
                                    {<Image src={image.thumbnail_url} alt={`Duplicate ${image.key}`}
                                            style={{width: '100%', height: '100%', objectFit: 'cover'}}/>}
                                    {/*todo: why wasn't ImageWithSkeleton working here working here?*/}
                                </div>
                            ))}
                        </Flex>
                        <Group justify="flex-end" gap="sm" mt="lg">
                            <Button color="gray" variant="outline" onClick={() => mergeDuplicates(group)}>
                                Keep All
                            </Button>
                            <Button color="blue" onClick={() => mergeDuplicates(group)}>
                                Merge Duplicates
                            </Button>
                        </Group>
                    </Paper>
                ))
            )}

        </Flex>
    );
}

export default DeleteDuplicates;