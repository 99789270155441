import React, { useState, useRef, useEffect } from 'react';
import {
    ActionIcon,
    Button,
    Checkbox,
    createTheme,
    Divider,
    Flex,
    Group,
    MantineProvider,
    Paper,
    Stack,
    Box,
    Text,
    Title, Skeleton
} from '@mantine/core';
import { EventData } from '../../types/Event';
import { ImageData } from '../../types/Image';
import ImageWithSkeleton from '../ImageWithSkeleton';
import { IconEdit } from '@tabler/icons-react';
import {formatDateString} from "./Utils";
import { useIntersection } from '@mantine/hooks';


interface EventGroupProps {
    event: EventData;
    onImageSelect: (images: string | string[]) => void;
    onImageDeselect: (images: string | string[]) => void;
    onImageClick: (image: ImageData) => void;
    onEditEventClick: (event: EventData) => void;
    onVisibilityChange?: (eventKey: string, isVisible: boolean) => void;
}

export function EventGroup({
                               event,
                               onImageSelect,
                               onImageDeselect,
                               onImageClick,
                               onEditEventClick,
                               onVisibilityChange
                           }: EventGroupProps) {

    // Create a ref for the event group container
    const containerRef = useRef<HTMLDivElement>(null);

    // Set up intersection observer with threshold and rootMargin
    const { ref: inViewRef, entry } = useIntersection({
        root: null,
        threshold: 0.1,
        rootMargin: '150%' // Pre-load when getting close to viewport
    });

    // Use a callback ref to set both refs
    const setRefs = (node: HTMLDivElement | null) => {
        // // Update containerRef
        // containerRef.current = node;

        // Update intersection ref
        // This is type-safe as inViewRef is a callback function provided by useIntersection
        inViewRef(node);
    };

    // Report visibility changes to parent component
    useEffect(() => {
        if (onVisibilityChange && entry) {
            onVisibilityChange(event.key, entry.isIntersecting);
        }
    }, [entry, event.key, onVisibilityChange]);

    const [selectedImages, setSelectedImages] = useState<Set<string>>(new Set());
    const [hoveredImage, setHoveredImage] = useState<string | null>(null);

    const inSelectionMode = () => selectedImages.size >= 1;

    const isEventSelected = () => event.images.every(img => selectedImages.has(img.key));

    const handleSelectGroup = () => {
        if (isEventSelected()) {
            const deselectKeys = Array.from(selectedImages);
            onImageDeselect(deselectKeys);
            setSelectedImages(new Set());
        } else {
            const selectKeys = event.images.map(img => img.key).filter(key => !selectedImages.has(key));
            onImageSelect(selectKeys);
            setSelectedImages(new Set([...Array.from(selectedImages), ...selectKeys]));
        }
    };

    const toggleImageSelection = (image: ImageData, event?: React.MouseEvent) => {
        event?.stopPropagation();
        if (selectedImages.has(image.key)) {
            const newSelection = new Set(selectedImages);
            newSelection.delete(image.key);
            setSelectedImages(newSelection);
            onImageDeselect(image.key);
        } else {
            setSelectedImages(new Set(selectedImages).add(image.key));
            onImageSelect(image.key);
        }
    };

    const theme = createTheme({ cursorType: 'pointer' });

    return (
        <Box ref={setRefs}>

        <Paper mb="md" p="md">
            <Group justify="space-between">
                <div>
                    <Title order={2}>{event.title || "Untitled Event"}</Title>
                    <Text c="dimmed" fs="italic">
                        {event.date ? formatDateString(event.date, event.dateFormat) : 'Unknown Date'}
                    </Text>
                </div>
                <Group gap="xs">
                    <Button
                        variant={isEventSelected() ? "filled" : "light"}
                        radius="md"
                        onClick={handleSelectGroup}
                    >
                        {isEventSelected() ? 'Deselect' : 'Select'}
                    </Button>
                    <ActionIcon
                        variant="light"
                        size="lg"
                        radius="md"
                        aria-label="edit event data"
                        onClick={() => onEditEventClick(event)}
                    >
                        <IconEdit />
                    </ActionIcon>
                </Group>
            </Group>
            <Divider mb="sm" />
            <Flex mt="md" direction="row" wrap="wrap" gap={{ base: '3px', sm: '3px' }} justify={{ sm: '3px' }}>
                {event.images.map((image) => (
                    <div
                        key={`${image.key}`}
                        onMouseEnter={() => setHoveredImage(image.key)}
                        onMouseLeave={() => setHoveredImage(null)}
                        style={{
                            position: 'relative',
                            width: 'calc((100% - 20px) / 6)',
                            aspectRatio: '1 / 1'
                        }}
                    >

                        { (inSelectionMode() || hoveredImage === image.key || selectedImages.has(image.key)) && (
                            <div
                                onClick={(e) => toggleImageSelection(image, e)}
                                style={{
                                    position: 'absolute',
                                    top: 5,
                                    left: 5,
                                    zIndex: 1,
                                    width: '24px',
                                    height: '24px'
                                }}
                            >
                                <MantineProvider theme={theme}>
                                    <Checkbox
                                        color="blue"
                                        radius="xl"
                                        checked={selectedImages.has(image.key)}
                                        readOnly
                                    />
                                </MantineProvider>
                            </div>
                        )}
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                height: '100%',
                                border: selectedImages.has(image.key) ? '3px solid #228be6' : 'none',
                                overflow: 'hidden'
                            }}
                        >
                            {image.thumbnail_url ? (
                                <ImageWithSkeleton
                                    src={image.thumbnail_url}
                                    alt={`Photo ${image.datetime ? `taken on ${image.datetime}` : 'with unknown date'}`}
                                    onClick={() => {inSelectionMode() ? toggleImageSelection(image) : onImageClick(image)}}
                                />
                            ) : (
                                <Skeleton width='100%' height="100%" />
                            )}
                        </div>
                    </div>
                ))}
            </Flex>
        </Paper>
        </Box>
    );
};