import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Flex, Avatar, Text, SimpleGrid, Card, Image, Skeleton, Box } from '@mantine/core';
import { PersonBetter } from '../../types/Person';
import { Face } from '../../types/Face';
import { fetchFacesForPerson } from '../../api/Faces';

interface FacesForPersonModalProps {
    person: PersonBetter;
    onClose: () => void;
}

export const FacesForPersonModal: React.FC<FacesForPersonModalProps> = ({ person, onClose }) => {
    const [faces, setFaces] = useState<Face[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const modalContentRef = useRef<HTMLDivElement>(null);
    const loadingRef = useRef(false);
    const hasMoreRef = useRef(true);

    const loadMoreFaces = useCallback(async (currentPage: number) => {
        if (loadingRef.current || !hasMoreRef.current) return;

        loadingRef.current = true;
        setIsLoading(true);

        try {
            // Use the updated API to fetch all faces for the person
            const response = await fetchFacesForPerson(person, currentPage, 20);
            setFaces(prev => [...prev, ...response.faces]);
            setHasMore(response.hasMore);
            hasMoreRef.current = response.hasMore;
        } catch (error) {
            console.error('Failed to load faces:', error);
            setHasMore(false);
            hasMoreRef.current = false;
        } finally {
            setIsLoading(false);
            loadingRef.current = false;
        }
    }, [person]);

    useEffect(() => {
        setFaces([]);
        setPage(1);
        setHasMore(true);
        hasMoreRef.current = true;
        loadMoreFaces(1);
    }, [person, loadMoreFaces]);

    const handleScroll = useCallback(() => {
        if (!modalContentRef.current) return;

        const { scrollTop, scrollHeight, clientHeight } = modalContentRef.current;

        if (scrollHeight - scrollTop <= clientHeight + 100) {
            if (!loadingRef.current && hasMoreRef.current) {
                setPage(prevPage => {
                    loadMoreFaces(prevPage + 1);
                    return prevPage + 1;
                });
            }
        }
    }, [loadMoreFaces]);

    return (
        <Modal
            opened={!!person}
            onClose={onClose}
            size="lg"
            centered
            styles={{
                content: {
                    height: '70vh',
                    display: 'flex',
                    flexDirection: 'column',
                },
                body: {
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            {person && (
                <Flex onScroll={handleScroll} direction="column" align="center" style={{ flexShrink: 0 }}>
                    <Avatar src={person.profile_url} size={160} radius="50%" />
                    <Text size="xl" fw={700} mt="md">
                        {`${person.first} ${person.last}`}
                    </Text>
                    <SimpleGrid mt="md" cols={4} spacing="sm">
                        {faces.map((face) => (
                            <Card
                                key={face.face_key}
                                p={0}
                                style={{
                                    aspectRatio: '1',
                                    overflow: 'hidden',
                                }}
                            >
                                <Image
                                    src={face.face_url}
                                    fit="cover"
                                    style={{ width: '100%', height: '100%' }}
                                />
                            </Card>
                        ))}
                        {isLoading &&
                            Array.from({ length: 16 }).map((_, index) => (
                                <Skeleton
                                    key={index}
                                    style={{
                                        aspectRatio: '1',
                                        width: '100%',
                                    }}
                                />
                            ))}
                    </SimpleGrid>
                </Flex>
            )}
        </Modal>
    );
};