import React from "react";
import {Avatar, Flex, Menu, Text, UnstyledButton, Button} from "@mantine/core";
import {IconChevronDown, IconCreditCard, IconLogout, IconSettings} from "@tabler/icons-react";
import {useAuth} from "./UseAuthHook";

export const UserMenu: React.FC = () => {
    const { userProfile, handleLogout } = useAuth();

    return (
        <Menu position="bottom-end" shadow="md" width={300}>
            <Menu.Target>
                <UnstyledButton>
                    <Flex align="center" gap="md">
                        <Avatar
                            src={userProfile.profileUrl || null}
                            color="gray"
                            radius="xl"
                            size="md"
                        />
                        <Text className="oswald-username" size="lg">
                            {`${userProfile.firstName} ${userProfile.lastName}`.toUpperCase()}
                        </Text>
                        <IconChevronDown size="1.2rem" stroke={1.5} />
                    </Flex>
                </UnstyledButton>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Label>
                    <Flex direction="column">
                        <Text size="md">{userProfile.email}</Text>
                    </Flex>
                </Menu.Label>
                <Menu.Divider />

                {/* Enhanced credit display */}
                <Flex direction="column" gap="xs" p="sm" style={{ backgroundColor: '#f0f9ff', borderRadius: '8px' }}>
                    <Text size="md" c="black" w={700}>Credits Used:</Text>
                    <Flex justify="space-between">
                        <Text size="sm" c="black">Uploads:</Text>
                        <Text size="sm" c={userProfile.uploadCount! >= userProfile.uploadLimit! ? 'red' : 'black'}>
                            {userProfile.uploadCount} / {userProfile.uploadLimit}
                        </Text>
                    </Flex>
                    <Flex justify="space-between">
                        <Text size="sm" c="black">Downloads:</Text>
                        <Text size="sm" c={userProfile.downloadCount! >= userProfile.downloadLimit! ? 'red' : 'black'}>
                            {userProfile.downloadCount} / {userProfile.downloadLimit! >= 100 ? '∞' : userProfile.downloadLimit}
                            {/*{userProfile.downloadCount} / {userProfile.downloadLimit > 100 ? '∞' : userProfile.downloadLimit}*/}
                        </Text>
                    </Flex>
                    <Button variant="light" color="blue" size="xs" leftSection={<IconCreditCard size="1rem" />}>
                        Buy More Credits
                    </Button>
                </Flex>

                <Menu.Divider />

                <Menu.Item leftSection={<IconSettings size="1rem" />}>Settings</Menu.Item>
                <Menu.Item
                    color="red"
                    leftSection={<IconLogout size="1rem" />}
                    onClick={handleLogout}
                >
                    Logout
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};


export const UserNamed: React.FC = () => {
    const { userProfile, handleLogout } = useAuth();

    return (
        <Menu position="bottom-end" shadow="md" width={300}>
            <Menu.Target>
                <UnstyledButton>
                    <Flex align="center" gap="md">
                        <Text className="oswald-username" size="lg">
                            {`Hello, ${userProfile.firstName}`.toUpperCase()}
                        </Text>
                        {/*<IconChevronDown size="1.2rem" stroke={1.5} />*/}
                    </Flex>
                </UnstyledButton>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Label>
                    <Flex direction="column">
                        <Text size="md">{userProfile.email}</Text>
                    </Flex>
                </Menu.Label>

                <Menu.Divider />

                <Menu.Item leftSection={<IconSettings size="1rem" />}>Settings</Menu.Item>
                <Menu.Item leftSection={<IconCreditCard size="1rem" />}>My Purchases</Menu.Item>

                <Menu.Divider />

                <Menu.Item
                    color="red"
                    leftSection={<IconLogout size="1rem" />}
                    onClick={handleLogout}
                >
                    Logout
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};