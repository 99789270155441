import {PersonBetter} from "../types/Person";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_PATH_TO_API;

/**
 * Fetches a list of people associated with the current user.
 * Converts date of birth (dob) to Date objects and logs the result.
 */
export const fetchPeople = async (): Promise<PersonBetter[]> => {
    try {
        const response = await axios.get(`${API_BASE_URL}/person`, {
            params: {sub: localStorage.getItem('sub')},
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        });

        const familyMembers: PersonBetter[] = response.data.map((member: PersonBetter) => ({
            ...member,
            dob: member.dob ? new Date(member.dob) : null
        }));

        console.log('Parsed family members with dates:', familyMembers);

        return familyMembers;
    } catch (error) {
        console.error('Failed to fetch people:', error);
        throw new Error('Failed to load people. Please try again later.');
    }
};

/**
 * Creates a new person by splitting the provided name into first and last names.
 * Handles edge cases where the name is non-standard.
 * Makes a POST request to the /person endpoint and returns the created PersonBetter.
 */
export const createPerson = async (name: string, face_key: string): Promise<PersonBetter> => {
    try {
        // Basic name splitting logic
        const nameParts = name.trim().split(" ");

        let firstName = nameParts[0] || "";
        let lastName = nameParts.slice(1).join(" ") || "";

        const payload = {
            first: firstName,
            last: lastName,
            profile_photo_key: face_key
        };

        console.log(payload);

        const response = await axios.post(`${API_BASE_URL}/person`, payload, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
        });

        // todo: if response.status == 200 vs 201

        if (response.data.dob) {
            let createdPerson: PersonBetter = {
                ...response.data,
                dob: new Date(response.data.dob)
            }
            console.log("Created person:", createdPerson);
            return createdPerson
        } else {
            let createdPerson: PersonBetter = response.data
            console.log("Created person:", createdPerson);
            return createdPerson
        }
    } catch (error) {
        console.error("Failed to create person:", error);
        throw new Error("Failed to create person. Please try again later.");
    }
};


interface UpdatePersonPayload {
    seed_key: string;
    first?: string;
    middle?: string;
    last?: string;
    dob?: string; // Notice this is a string because we're sending it as ISO format
    profile_image_key?: string;
    notes?: string;
}

export const updatePerson = async (person: Partial<PersonBetter> & { seed_key: string }): Promise<PersonBetter> => {
    try {
        const payload: UpdatePersonPayload = {
            seed_key: person.seed_key,
            first: person.first,
            middle: person.middle,
            last: person.last,
            dob: person.dob ? person.dob.toISOString() : undefined,
            profile_image_key: person.profile_image_key,
            notes: person.notes? person.notes : undefined,
        };

        const response = await axios.patch(`${API_BASE_URL}/person`, payload, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                "Content-Type": "application/json",
            },
        });

        const updatedPerson: PersonBetter = {
            ...response.data,
            dob: response.data.dob ? new Date(response.data.dob) : undefined,
        };

        console.log("Updated person:", updatedPerson);
        return updatedPerson;
    } catch (error) {
        console.error("Failed to update person:", error);
        throw new Error("Failed to update person. Please try again later.");
    }
};