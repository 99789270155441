import {DateFormat, EventData} from '../types/Event';
import {ImageData} from '../types/Image'
import axios from 'axios';
import {v4 as uuidv4} from 'uuid';

const API_BASE_URL = process.env.REACT_APP_PATH_TO_API;

export const predictEventForImages = async (images: ImageData[]): Promise<EventData> => {
    const imageKeys: string[] = images.map((i) => i.key);
    try {
        const response = await axios.post(`${API_BASE_URL}/event/predict-details`,
            { image_keys: imageKeys },
            {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        console.log(response.data);

        // todo: local offset - this is not a long term solution! Very hacked - need something better / more consistant!
        const eventDate = new Date(response.data.event_date);
        const localOffsetMinutes = eventDate.getTimezoneOffset();
        eventDate.setMinutes(eventDate.getMinutes() + localOffsetMinutes);


        const dateFormat: DateFormat = eventDate.getDate() === 1 ? DateFormat.MONTH : DateFormat.DAY;

        const eventSuggestion: EventData = {
            key: uuidv4(),
            images: images,
            title: response.data.event_title,
            location: response.data.event_location,
            date: eventDate,
            dateFormat: isNaN(eventDate.getTime()) ? undefined : dateFormat, // Leave undefined if date is invalid
            keywords: response.data.event_keywords || [],
        };

        console.log('Parsed event prediction: ', eventSuggestion);

        return eventSuggestion;
    } catch (error) {
        console.error('Failed to fetch predictions for event:', error);
        throw new Error('Failed to fetch predictions for event. Please try again later.');
    }
};

interface CreateEventPayload {
    key: string;
    image_keys: string[];
    title?: string;
    location?: string;
    date?: string;
    date_format?: 'day' | 'month' | 'year';
    keywords?: string[];
    attendee_keys?: string[];
}

export const createEvent = async (event: EventData): Promise<boolean> => {
    // todo: verify key is not empty & image is >= 1 (i.e. key list is not empty)
    try {
        const payload: CreateEventPayload = {
            key: event.key!,
            image_keys: event.images?.map((image) => image.key) || [],
            title: event.title,
            location: event.location,
            date: event.date ? event.date.toISOString() : undefined,
            date_format: event.dateFormat,
            keywords: event.keywords,
            attendee_keys: event.attendees?.map((person) => person.seed_key) || [],
        };

        console.log('Creating event with payload: ', JSON.stringify(payload));

        const response = await axios.post(`${API_BASE_URL}/event/new`, // todo: i want this to just be POST /event but need to update the api definition
            payload,
            {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        return response.status === 200;
        // const createdEvent: EventData = response.data;
        // console.log('Created event: ', createdEvent);
        //
        // return createdEvent;
    } catch (error) {
        console.error('Failed to create event:', error);
        throw new Error('Failed to create event. Try again later.');
    }
};

export const updateEvent = async (event: Partial<EventData>): Promise<EventData> => {
    try {
        const payload: CreateEventPayload = {
            key: event.key!,
            title: event.title,
            location: event.location,
            date: event.date ? event.date.toISOString() : undefined,
            date_format: event.dateFormat,
            keywords: event.keywords,
            image_keys: event.images?.map((image) => image.key) || [],
            attendee_keys: event.attendees?.map((person) => person.seed_key) || [],
        };

        console.log('Updating event with payload: ', JSON.stringify(payload));

        const response = await axios.patch(`${API_BASE_URL}/events`,
            payload,
            {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        const createdEvent: EventData = response.data;
        console.log('Updated event: ', createdEvent);

        return createdEvent;
    } catch (error) {
        console.error('Failed to update event:', error);
        throw new Error('Failed to update event. Try again later.');
    }
};

// Helper function to adjust ISO date string to user's timezone
export const adjustToLocalTimezone = (isoDateString: string): string => {
    console.log('original: ', isoDateString);
    const date = new Date(isoDateString);

    if (isNaN(date.getTime())) {
        console.warn('Invalid date:', isoDateString);
        return isoDateString;
    }

    const offsetMinutes = date.getTimezoneOffset();
    date.setMinutes(date.getMinutes() + offsetMinutes);

    console.log('new: ', date.toISOString());
    return date.toISOString();
};


export const fetchEvents = async (): Promise<{ events: EventData[]}> => {
    try {
        const response = await axios.get(`${API_BASE_URL}/events`, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        });

        // Transform null values to undefined
        const eventData: EventData[] = response.data.events.map((event: any) => cleanEvent(event));
        return {events: eventData};

    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 400) {
            throw new Error(error.response.data.error || 'Invalid parameters provided');
        }
        console.error('Failed to fetch events:', error);
        throw new Error('Failed to load events. Please try again later.');
    }
};

// Helper function to convert nulls to undefined
const cleanEvent = (event: any): EventData => {
    const cleanField = (value: any) => value === null ? undefined : value;

    return {
        key: event.key,
        title: cleanField(event.title),
        location: cleanField(event.location),
        date: event.date ? new Date(event.date) : undefined,
        dateFormat: cleanField(event.dateFormat),
        keywords: cleanField(event.keywords) || [],
        images: event.images.map((image: any) => ({
            key: image.key,
            thumbnail_url: image.thumbnail_url,
            image_url: image.image_url,
            datetime: cleanField(image.datetime),
            event_key: cleanField(image.event_key),
        }))
    };
};