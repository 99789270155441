import React, { useState, useEffect } from 'react';
import {Flex, Text, Blockquote, Title, Divider, Stack, ThemeIcon, Paper, Anchor} from '@mantine/core';
import {IconCircleCheck, IconInfoCircle, IconSeeding} from '@tabler/icons-react';
import axios from 'axios';
import { PersonCard } from './PersonCard';
import { PersonSkeleton} from './PersonSkeleton';
import {PersonBetter} from '../../types/Person';
import {fetchPeople} from "../../api/People";
import { Link } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_PATH_TO_API;

export function FamilyTree() {
    const [isLoading, setIsLoading] = useState(true);
    const [familyMembers, setFamilyMembers] = useState<PersonBetter[]>([]);
    const [updateError, setUpdateError] = useState<string | null>(null);

    useEffect(() => {
        loadFamilyMembers();
    }, []);

    const loadFamilyMembers = async () => {
        try {
            let familyMembers: PersonBetter[] = await fetchPeople();
            // console.log(`API BaseUrl: ${API_BASE_URL}`)
            // const response = await axios.get(`${API_BASE_URL}/person`, {
            //     params: {
            //         sub: localStorage.getItem('sub')
            //     },
            //     headers: {
            //         'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            //         'Content-Type': 'application/json'
            //     }
            // });
            //
            // // Parse dates in the response data
            // let familyMembers: PersonBetter[] = response.data.map((member: PersonBetter) => ({
            //     ...member,
            //     dob: member.dob ? new Date(`${member.dob}`) : null
            // }));
            //
            // console.log('Parsed family members with dates:', familyMembers);
            setFamilyMembers(familyMembers);
        } catch (error) {
            console.error('Error fetching family members:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const onUpdate = async (updatedMember: PersonBetter) => {
        setUpdateError(null);
        try {
            const payload = {
                seed_key: updatedMember.seed_key,
            };

            const cleanPayload = Object.entries(payload).reduce((acc, [key, value]) => {
                if (value != null) {
                    acc[key] = value;
                }
                return acc;
            }, {} as any);

            const response = await axios.patch(
                `${API_BASE_URL}/person`,
                cleanPayload,
                {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status !== 200) {
                throw new Error('Failed to update family person');
            }
        } catch (error) {
            console.error('Error updating family person:', error);
            setUpdateError(error instanceof Error ? error.message : 'Failed to update family person');
            await loadFamilyMembers();
        }
    };

    return (
        <Flex direction="column">
            <Title order={1}>Identify Faces</Title>
            <Divider mt="xs" />
            {updateError && (
                <Text color="red" mt="xs" mb="xs">
                    {updateError}
                </Text>
            )}
            <Blockquote color="blue" icon={<IconInfoCircle />} mt="lg" mb="lg">
                <p>
                    Your family tree isn't just a record of the past—it's the key to organizing and preserving your memories. By adding family members and their birthdays, you enable Sort My Shoebox's AI to more accurately predict when your photos were taken, helping you better organize your photo collection.
                </p>
                <p>
                    In the future, this space will also serve as a hub for tracking and storing genealogical information, such as marriages, children, occupations, and significant life events. With AI-powered suggestions, we'll help uncover the hidden clues in each photograph as you piece together your family history.
                </p>
            </Blockquote>
            <Flex
                wrap="wrap"
                direction={{ base: 'column', sm: 'row' }}
                gap={{ base: '10px', sm: '10px' }}
                justify="flex-start"
            >
                {isLoading
                    ? Array(4).fill(null).map((_, index) => <PersonSkeleton key={`skeleton-${index}`} />)
                    : familyMembers.map((person) => (
                        <PersonCard
                            key={person.seed_key}
                            person={person}
                            onUpdate={onUpdate}
                        />
                    ))}
                {familyMembers.length === 0 && !isLoading && (
                    <Paper w="100%" mt="md" shadow="md" radius="md" withBorder p="xl">
                        <Stack align="center" gap="md">
                            <ThemeIcon size="xl" radius="xl" color="blue">
                                <IconSeeding size={32} />
                            </ThemeIcon>
                            <Text size="lg" w={600} ta="center">
                                Looks like you haven't added any family members yet.
                            </Text>
                            <Text c="gray.6" ta="center">
                                Tag some people in the <Anchor component={Link} to="/identify-faces" underline="always">Identify Faces</Anchor> tab & they will show up here.
                            </Text>
                        </Stack>
                    </Paper>
                )}
            </Flex>
        </Flex>
    );
}