import React, {useEffect} from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { AppRoutes } from './AppRoutes';

// todo: implement back button! this is obviously not going to cut it...
export const useBackButton = (callback: () => void) => {
    useEffect(() => {
        window.history.pushState(null, '', window.location.pathname);
        window.addEventListener('popstate', callback);
        return () => {
            window.removeEventListener('popstate', callback);
        };
    }, [callback]);
};

export default function App() {
    return (
        <MantineProvider>
            <BrowserRouter>
                <AppRoutes />
            </BrowserRouter>
        </MantineProvider>
    );
}

