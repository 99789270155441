import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import React from 'react';
import { AuthProvider } from 'react-oidc-context';
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import './index.css';  // Import your global styles

// todo: investigate what exactly this AuthProvider/cognitoAuthConfig past me imported/defined.
//  i don't think current me is using it but its probably helpful... lol

const cognitoAuthConfig = {
    authority: "https://cognito-idp.us-west-2.amazonaws.com/us-west-2_h68AazbCA",
    client_id: "4js1rtmaqhg5tr7sbs2emq4j3f",
    redirect_uri: "https://localhost:3000",
    response_type: "code",
    scope: "email openid phone",
};

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    // <StrictMode>
        <AuthProvider {...cognitoAuthConfig}>
            <App />
        </AuthProvider>
    // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
