import React from 'react';
import { Paper, Skeleton, Divider, Flex } from '@mantine/core';

// todo: groups/number of images in group could be refactored to constants to make it easier to modify in the future.
// todo: should width calculations, be formula of some sort instead of hardcoded?
// todo: should gaps of 3x be a constant of some sort.

export const GalleryContentSkeleton: React.FC = () => (
    <>
        {[1, 2, 3].map((groupIndex) => (
            <Paper
                key={`skeleton-group-${groupIndex}`}
                mt={groupIndex > 0 ? "lg" : undefined}
                mb="xl"
            >
                <Skeleton height={30} width="50%" mb="sm" />
                <Divider mb="sm" />
                <Flex
                    mt="md"
                    direction="row"
                    wrap="wrap"
                    gap={{ base: '3px', sm: '3px' }}
                    justify={{ sm: '3px' }}
                >
                    {[1, 2, 3, 4, 5, 6].map((_, index) => (
                        <Skeleton
                            key={`skeleton-image-${groupIndex}-${index}`}
                            style={{
                                width: 'calc((100% - 20px) / 6)',
                                aspectRatio: 1 / 1,
                                cursor: 'pointer',
                            }}
                        />
                    ))}
                </Flex>
            </Paper>
        ))}
    </>
);