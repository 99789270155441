import React, {useState, useEffect, useRef, useCallback} from 'react';
import {
    Blockquote,
    Button,
    Divider,
    Flex,
    Title,
    Loader,
    Modal,
    Skeleton,
    Paper,
    Checkbox,
    Text,
    Box, Stack, ThemeIcon, Anchor
} from '@mantine/core';
import {IconInfoCircle, IconArrowRight, IconCircleCheck} from '@tabler/icons-react';
import {EventDetailsForm} from './EventDetailsForm';
import {ConfirmFacesModalContent} from './ConfirmFacesForEvent';
import {ImageData} from '../../types/Image';
import {EventData} from '../../types/Event';
import {PersonBetter} from "../../types/Person";
import {RemoveDuplicateModal} from './RemoveDuplicateModal';
import {NewPeopleModal} from "./ConfirmNewPersonModal";
import {createEvent} from "../../api/Event";
import {fetchImageDetails, fetchImages} from "../../api/Image";
import {UnsortedImageGroup} from "./UnsortedImageGroup";
import {ImageSelectionGridSkeleton} from "./ImageSelectionGridSkeleton";
import {Link} from "react-router-dom";

export const GroupImagesManually: React.FC = () => {
    // State management for selected images and grid images
    const [ungroupedImages, setUngroupedImages] = useState<ImageData[]>([]);
    const [selectedImages, setSelectedImages] = useState<ImageData[]>([]);
    const [selectedImageKeys, setSelectedImageKeys] = useState<Set<string>>(new Set());
    const [gridIsLoading, setGridIsLoading] = useState(false);

    const [newPeople, setNewPeople] = useState<PersonBetter[]>([]);
    const [eventData, setEventData] = useState<EventData | null>(null);

    // Modal states
    const [duplicatesIsLoaded, setDuplicatesIsLoaded] = useState<boolean>(false);
    const [allDuplicatesMerged, setAllDuplicatesMerged] = useState<boolean>(false);
    const [confirmFacesIsLoaded, setConfirmFacesIsLoaded] = useState<boolean>(false);
    const [allFacesConfirmed, setAllFacesConfirmed] = useState<boolean>(false);
    const [newPeopleIsLoaded, setNewPeopleIsLoaded] = useState<boolean>(true);
    const [allNewPeopleDescribed, setAllNewPeopleDescribed] = useState<boolean>(false);
    const [detailsIsLoaded, setDetailsIsLoaded] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalPage, setModalPage] = useState<number>(1);

    const [isUnsortedVisible, setIsUnsortedVisible] = useState(false);
    const [visibleImageKeys, setVisibleImageKeys] = useState<Set<string>>(new Set());

    // Keep track of image requests that are in progress
    const inFlightImageRequests = useRef<Set<string>>(new Set());

    // Container ref for info at the bottom
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState<number | null>(null);

    useEffect(() => {
        const loadData = async () => {
            await Promise.all([loadUnsorted()]);
            // once I'm done loading images & the first image renders,
            // I can set the container width on mount.
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };

        loadData();

        const handleResize = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const loadUnsorted = async () => {
        setGridIsLoading(true);
        try {
            let {images} = await fetchImages(false, true);
            setUngroupedImages(images);
            console.log(`Loaded ${images.length} unsorted images`);
        } catch (error) {
            console.error('Failed to load unsorted images:', error);
        } finally {
            setGridIsLoading(false);
        }
    };

    // Load thumbnails for visible events
    const loadThumbnailsForVisibleEvents = useCallback(async () => {
        if (!isUnsortedVisible) return;

        // Get all image keys from visible events that don't have thumbnails yet
        // and are not already being fetched
        const imagesToLoad: string[] = [];


        // Then check unsorted images if they're visible
        if (isUnsortedVisible) {
            ungroupedImages.forEach(image => {
                if (!image.thumbnail_url && !inFlightImageRequests.current.has(image.key)) {
                    imagesToLoad.push(image.key);
                }
            });
        }

        if (imagesToLoad.length === 0) return;

        try {
            // Mark these images as in-flight
            imagesToLoad.forEach(key => inFlightImageRequests.current.add(key));

            // Batch image thumbnail requests (max 50 at a time)
            const BATCH_SIZE = 50;
            for (let i = 0; i < imagesToLoad.length; i += BATCH_SIZE) {
                const batch = imagesToLoad.slice(i, i + BATCH_SIZE);

                // Fetch image data will return a map of image keys to urls
                const {image_details} = await fetchImageDetails(batch);

                // Update unsorted images with thumbnail data
                setUngroupedImages(prevUnsorted => {
                    const updatedUnsorted = [...prevUnsorted];

                    Object.entries(image_details).forEach(([key, url]) => {
                        // Type assertion to ensure url is treated as a string
                        const thumbnailUrl = url as string;

                        // Find the image in unsorted and update its thumbnail_url
                        const imageIndex = updatedUnsorted.findIndex(img => img.key === key);
                        if (imageIndex !== -1) {
                            updatedUnsorted[imageIndex] = {
                                ...updatedUnsorted[imageIndex],
                                thumbnail_url: thumbnailUrl
                            };
                        }

                        // Remove from in-flight set once processed
                        inFlightImageRequests.current.delete(key);
                    });

                    return updatedUnsorted;
                });
            }
        } catch (error) {
            console.error('Failed to load thumbnails:', error);

            // In case of error, remove all batch items from in-flight requests
            imagesToLoad.forEach(key => inFlightImageRequests.current.delete(key));
        }
    }, [ungroupedImages, isUnsortedVisible]);

    // Effect to load thumbnails when visible events or unsorted visibility changes
    useEffect(() => {
        loadThumbnailsForVisibleEvents();
    }, [isUnsortedVisible, loadThumbnailsForVisibleEvents]);


    // Function to reset all states
    const resetState = () => {
        setSelectedImages([]);
        setNewPeople([]);
        setModalPage(1);
        setDuplicatesIsLoaded(false);
        setAllDuplicatesMerged(false);
        setConfirmFacesIsLoaded(false);
        setAllFacesConfirmed(false);
        setNewPeopleIsLoaded(true);
        setAllNewPeopleDescribed(false);
        setDetailsIsLoaded(false);
        setEventData(null);
    };

    const nextPage = () => {
        setModalPage((prev) => prev + 1);
    };

    const startEventWorkflow = () => {
        // todo: get the list of images in ungroupedImages ImageData[] where image.key IN selectedImageKeys: Set<string>
        // images
        const images = ungroupedImages.filter(image => selectedImageKeys.has(image.key));
        console.log('Creating event with images:', images);
        setSelectedImages(images);
        setModalOpen(true);
    };

    const endEventWorkflow = () => {
        console.log('Cancelling event workflow');
        setModalOpen(false);
        resetState();
    };

    const onConfirmEvent = async () => {
        if (!eventData) {
            console.error("No event data available");
            return;
        }

        // Store the current state for potential rollback
        const previousUngroupedImages = [...ungroupedImages];

        try {
            // Optimistically remove the selected images
            const selectedImageKeys = new Set(selectedImages.map(img => img.key));
            const updatedImages = ungroupedImages.filter(img => !selectedImageKeys.has(img.key));
            setUngroupedImages(updatedImages);

            // Close modal and reset state
            setModalOpen(false);
            resetState();

            console.log("Creating event with data:", eventData);
            await createEvent(eventData);
            console.log("Event created successfully!");

        } catch (error) {
            console.error("Failed to create event:", error);
            // Rollback to previous state
            setUngroupedImages(previousUngroupedImages);
            // Optionally show an error notification to the user
        }
    };

    useEffect(() => {
        if (modalPage === 3 && newPeopleIsLoaded && newPeople.length <= 0) {
            console.log('Skipping NewPeopleModal because no new people were added.');
            nextPage();
        }
    }, [modalPage, newPeopleIsLoaded, newPeople]);


    const onImageSelect = (images: string | string[]) => {
        setSelectedImageKeys((prevSelected) => {
            const updatedSelection = new Set(prevSelected);
            if (Array.isArray(images)) {
                images.forEach((image) => updatedSelection.add(image));
            } else {
                updatedSelection.add(images);
            }
            return updatedSelection;
        });
    };

    const onImageDeselect = (images: string | string[]) => {
        setSelectedImageKeys((prevSelected) => {
            const updatedSelection = new Set(prevSelected);
            if (Array.isArray(images)) {
                images.forEach((image) => updatedSelection.delete(image));
            } else {
                updatedSelection.delete(images);
            }
            return updatedSelection;
        });
    };

    // Track unsorted images visibility
    const handleUnsortedVisibilityChange = (isVisible: boolean) => {
        setIsUnsortedVisible(isVisible);
    };

    // a note here about displaying modal contents: // todo: can you improve this explanation/comment in a succinct way?
    // because I want my paging to be "preloaded" and preloading them requires changing page contents based up the users actions in previous pages,
    // I end up with a component where all of these modal contents are "rendered" rather than coditionally based on something like "page == 1". This means that these
    // components will update in the background returning an empty react component unless the render flag is true.
    return (
        <Flex direction="column" style={{width: '100%', minHeight: '100vh', position: 'relative'}}>
            <Title order={1}>Manually Group Images</Title>
            <Divider mt="xs"/>
            <Blockquote color="blue" icon={<IconInfoCircle/>} mt="lg" mb="lg">
                Select images that belong to the same event and click "Create Event" to group them together.
                This manual method gives you full control over how your images are organized.
            </Blockquote>
            <div ref={containerRef}>
                {gridIsLoading && (<ImageSelectionGridSkeleton/>)}
                {!gridIsLoading && ungroupedImages.length === 0 && (
                    <Paper w="100%" mt="md" shadow="md" radius="md" withBorder p="xl">
                        <Stack align="center" gap="md">
                            <ThemeIcon size="xl" radius="xl" color="blue">
                                <IconCircleCheck size={32}/>
                            </ThemeIcon>
                            <Text size="lg" w={600} ta="center">
                                You don't have any images to sort.
                            </Text>
                            <Text c="gray.6" ta="center">
                                <Anchor component={Link} to="/import" underline="always">Import</Anchor> some images &
                                start organizing!
                            </Text>
                        </Stack>
                    </Paper>
                )}
                {ungroupedImages.length > 0 && !gridIsLoading && (
                    <UnsortedImageGroup
                        key={'unsorted-image-grouping'}
                        images={ungroupedImages}
                        onImageSelect={onImageSelect}
                        onImageDeselect={onImageDeselect}
                        onImageClick={onImageSelect}
                        onVisibilityChange={handleUnsortedVisibilityChange}
                        containerWidth={containerWidth}
                        containerLeft={containerRef.current?.getBoundingClientRect().left || 0}
                    />
                )}
            </div>
            {selectedImageKeys.size > 0 && (
                <Box style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.75)',
                    position: 'fixed',
                    bottom: 0,
                    zIndex: 2,
                    width: containerWidth ? `${containerWidth}px` : '100%',
                    left: containerRef.current?.getBoundingClientRect().left || 0,
                    transition: 'width 0.2s ease, left 0.2s ease'
                }}>
                    <Flex justify="center">
                        <Button
                            mt="sm"
                            mb="sm"
                            size="md"
                            onClick={() => startEventWorkflow()}
                            disabled={selectedImageKeys.size <= 0}
                            style={{width: '50%'}}
                        >
                            {selectedImageKeys.size !== 0
                                ? `Create Event with ${selectedImageKeys.size} Selected Image${selectedImageKeys.size !== 1 ? 's' : ''}`
                                : 'Select Images to Create an Event'
                            }
                        </Button>
                    </Flex>
                </Box>
            )}
            {modalOpen && (
                <Modal
                    opened={modalOpen}
                    size="80%"
                    onClose={() => endEventWorkflow()}
                    title="Create New Event" // todo: maybe is can vary based in the step (i.e. merge duplicate in event, identify faces in event, update new people, confirm event details)
                    style={{
                        width: '80vw',
                        height: '80vh',
                        maxWidth: 'none',
                    }}
                >
                    {((modalPage === 1 && !duplicatesIsLoaded) ||
                        (modalPage === 2 && !confirmFacesIsLoaded) ||
                        (modalPage === 3 && !newPeopleIsLoaded) ||
                        (modalPage === 4 && !detailsIsLoaded)) && (
                        <Flex justify="center" mt="xl">
                            <Loader size="lg"/>
                        </Flex>
                    )}

                    <RemoveDuplicateModal
                        render={modalPage === 1 && duplicatesIsLoaded}
                        onSkip={() => nextPage()}
                        inImages={selectedImages}
                        onIsReady={() => setDuplicatesIsLoaded(true)}
                        onDuplicateRemoved={(removedImage: ImageData) => {
                            // todo: remove Image from selectedImages & maybe log a message describing change?
                        }}
                        onAllDuplicatesMerged={() => setAllDuplicatesMerged(true)}
                    />

                    <ConfirmFacesModalContent
                        render={modalPage === 2 && confirmFacesIsLoaded}
                        onSkip={() => nextPage()}
                        inImages={selectedImages}
                        onIsReady={() => setConfirmFacesIsLoaded(true)}
                        setNewPersonReady={(ready: boolean) => setNewPeopleIsLoaded(ready)}
                        onNewPersonAdded={(newPerson: PersonBetter) => {
                            console.log(newPerson);
                            setNewPeople((newPeople) => [...newPeople, newPerson]);
                            // todo: when i know setNewPeople has updated,  setNewPeopleIsLoaded(true);
                            setNewPeopleIsLoaded(true);
                            console.log("new people contents:", newPeople);
                        }}
                        onAllFacesConfirmed={() => setAllFacesConfirmed(true)}
                    />

                    <NewPeopleModal
                        render={modalPage === 3 && newPeopleIsLoaded}
                        newPeople={newPeople}
                        onAllNewPeopleDescribed={(allDescribed: boolean) => setAllNewPeopleDescribed(allDescribed)}
                    />
                    <EventDetailsForm
                        render={modalPage === 4 && detailsIsLoaded}
                        selectedImages={selectedImages}
                        eventData={eventData}
                        setEventData={setEventData}
                        setIsReady={setDetailsIsLoaded}
                    />

                    {/* todo: is there a better way to structure these button options so its not so repetitive */}
                    {(modalPage === 1 && duplicatesIsLoaded) && (
                        <Button
                            variant={allDuplicatesMerged ? "filled" : "light"}
                            mt="xl"
                            fullWidth
                            onClick={() => {
                                nextPage()
                            }}
                            rightSection={<IconArrowRight size={14}/>}
                        >
                            {}
                            {allDuplicatesMerged ? "Next" : "Continue without Merging"}
                        </Button>
                    )}

                    {(modalPage === 2 && confirmFacesIsLoaded) && (
                        <Button
                            variant={allFacesConfirmed ? "filled" : "light"}
                            mt="xl"
                            fullWidth
                            onClick={() => nextPage()}
                            rightSection={<IconArrowRight size={14}/>}
                        >
                            {allFacesConfirmed ? "Next" : "Continue without Naming"}
                        </Button>
                    )}

                    {(modalPage === 3 && newPeopleIsLoaded) && (
                        <Button
                            variant={allNewPeopleDescribed ? "filled" : "light"}
                            mt="xl"
                            fullWidth
                            onClick={() => nextPage()}
                            rightSection={<IconArrowRight size={14}/>}
                        >
                            {allNewPeopleDescribed ? "Next" : "Skip Without Describing People"}
                        </Button>
                    )}
                    {(modalPage === 4 && detailsIsLoaded) && (
                        <Button
                            variant="filled"
                            mt="xl"
                            fullWidth
                            onClick={() => {
                                onConfirmEvent();
                            }}
                        >
                            Confirm Event
                        </Button>
                    )}
                </Modal>
            )}
        </Flex>
    );
};