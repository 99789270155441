import React from "react";
import {Card, Flex, Paper, Skeleton, Text} from "@mantine/core";

export const FaceGridSkeleton: React.FC = () => (
    <>
        {/*<Text size="xl" fw={500} mt="md">Unnamed Faces:</Text>*/}
        <Flex
            mt="1px"
            direction="row"
            wrap="wrap"
            gap={{base: '1px', sm: '1px'}}
            justify="flex-start"
        >
            {Array(42).fill(0).map((_, index) => (
                <div
                    key={index}
                    style={{
                        position: 'relative',
                        width: 'calc((100% - 17px) / 6)',
                        margin: '1px'
                    }}
                >
                    <Card
                        p={0}
                        w="100%"
                        style={{aspectRatio: '4 / 5'}}
                    >
                        <Flex direction="column" h="100%">
                            <Skeleton
                                style={{flexBasis: '80%'}}
                                height="100%"
                                width="100%"
                            />
                            <Paper
                                style={{
                                    flexBasis: '20%',
                                    width: '100%',
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0
                                }}
                                bg="lightgray"
                            >
                                <Skeleton height={30} width="80%" mx="auto" mt="xs"/>
                            </Paper>
                        </Flex>
                    </Card>
                </div>
            ))}
        </Flex>
    </>
);