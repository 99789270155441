import axios from 'axios';
import React from "react";

const API_BASE_URL = process.env.REACT_APP_PATH_TO_API;

export interface AuthResponse {
    token: string;
    expiresAt: number; // seconds since epoch
    sub: string;
    userAttributes: {
        firstName: string;
        lastName: string;
        email: string;
        picture: string;
        uploadCount: string;
        uploadLimit: string;
        downloadCount: string;
        downloadLimit: string;
    }
}

export interface AuthResult {
    success: boolean;
    error?: string;
}


export interface TokenCheckResult {
    isValid: boolean;
    wasRefreshed: boolean;
    error?: string;
}

export const checkAndRefreshTokenIfNeeded = async (): Promise<TokenCheckResult> => {
    try {
        const expiresAtString = sessionStorage.getItem('expiresAt');
        const expiresAt = expiresAtString ? Number(expiresAtString) : null;
        const currentTime = Math.floor(Date.now() / 1000);

        // If expiresAt is missing or invalid, assume a new tab and attempt a refresh
        if (!expiresAt || isNaN(expiresAt) || expiresAt <= currentTime) {
            console.log('No valid token or token expired. Attempting to refresh.');
            const refreshResult = await refreshToken();

            if (refreshResult.success) {
                return {
                    isValid: true,
                    wasRefreshed: true
                };
            } else {
                return {
                    isValid: false,
                    wasRefreshed: false,
                    error: refreshResult.error
                };
            }
        }

        // Check if token is close to expiry (e.g., < 5 mins)
        const secondsUntilExpiry = expiresAt - currentTime;
        if (secondsUntilExpiry > 300) {
            return {
                isValid: true,
                wasRefreshed: false
            };
        }

        // Token is expiring soon, attempt refresh
        console.log('Token expires in less than 5 minutes. Refreshing!');
        const refreshResult = await refreshToken();

        if (refreshResult.success) {
            return {
                isValid: true,
                wasRefreshed: true
            };
        } else {
            return {
                isValid: false,
                wasRefreshed: false,
                error: refreshResult.error
            };
        }
    } catch (error) {
        console.error('Token check/refresh error:', error);
        return {
            isValid: false,
            wasRefreshed: false,
            error: 'Failed to check/refresh token'
        };
    }
};


// Hook for using token refresh in components
export const useTokenRefresh = () => {
    const checkAndRefreshTokens = React.useCallback(async (): Promise<boolean> => {
        const result = await checkAndRefreshTokenIfNeeded();
        return result.isValid;
    }, []);

    return checkAndRefreshTokens;
};

// Helper function to store auth session data
const storeAuthSession = (authResponse: AuthResponse) => {
    console.log(authResponse);
    localStorage.setItem('sub', authResponse.sub);
    sessionStorage.setItem('token', authResponse.token);
    sessionStorage.setItem('expiresAt', authResponse.expiresAt.toString());

    const { userAttributes } = authResponse;
    sessionStorage.setItem('email', userAttributes.email);
    sessionStorage.setItem('firstName', userAttributes.firstName);
    sessionStorage.setItem('lastName', userAttributes.lastName);
    sessionStorage.setItem('profileUrl', userAttributes.picture);
    sessionStorage.setItem('uploadCount', userAttributes.uploadCount);
    sessionStorage.setItem('uploadLimit', userAttributes.uploadLimit);
    sessionStorage.setItem('downloadCount', userAttributes.downloadCount);
    sessionStorage.setItem('downloadLimit', userAttributes.downloadLimit);
};

// Process API response and store session data
const processSocialAuthResponse = async (response: Response): Promise<AuthResult> => {
    if (!response.ok) {
        const errorData = await response.json();
        return {
            success: false,
            error: errorData.message || 'Authentication failed. Please try again.'
        };
    }

    try {
        const data = await response.json();
        console.log(data);

        // Parse the JSON string in data.body
        const authData = JSON.parse(data.body);

        // Now pass the parsed object to storeAuthSession
        storeAuthSession(authData);

        return { success: true };
    } catch (error) {
        console.error("Error processing response data:", error);
        return {
            success: false,
            error: 'Error processing authentication response'
        };
    }
};

/// Process API response and store session data
const processAuthResponse = async (response: Response): Promise<AuthResult> => {
    if (!response.ok) {
        const errorData = await response.json();
        return {
            success: false,
            error: errorData.message || 'Authentication failed. Please try again.'
        };
    }

    try {
        const data = await response.json();
        storeAuthSession(data);

        return { success: true };
    } catch (error) {
        console.error("Error processing response data:", error);
        return {
            success: false,
            error: 'Error processing authentication response'
        };
    }
};

// Handle OAuth callback
export const handleAuthCallback = async (code: string): Promise<AuthResult> => {
    try {
        const response = await fetch(`${API_BASE_URL}/auth/token-exchange`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ code }),
        });

        return await processSocialAuthResponse(response);
    } catch (error) {
        console.error('Auth callback error:', error);
        return {
            success: false,
            error: 'Authentication failed. Please try again.'
        };
    }
};

// Handle direct sign in
export const signIn = async (email: string, password: string): Promise<AuthResult> => {
    try {
        const response = await fetch(`${API_BASE_URL}/auth/signin`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ email, password }),
        });
        console.log(response);
        return await processAuthResponse(response);

    } catch (error) {
        console.error('Sign in error:', error);
        return {
            success: false,
            error: 'Authentication failed. Please try again.'
        };
    }
};

// Refresh token
export const refreshToken = async (): Promise<AuthResult> => {
    console.log('Hey! from refreshing token...');
    try {
        const response = await fetch(`${API_BASE_URL}/auth/refresh-token`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        });

        return await processAuthResponse(response);
    } catch (error) {
        console.error('Token refresh error:', error);
        return {
            success: false,
            error: 'Failed to refresh authentication token'
        };
    }
};

// Logout
export const logout = async (): Promise<void> => {
    try {
        await fetch(`${API_BASE_URL}/auth/sign-out`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
        });

        // Clear all auth-related storage
        localStorage.removeItem('sub');
        sessionStorage.clear();
    } catch (error) {
        console.error('Logout error:', error);
    }
};
