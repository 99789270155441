import React, {useState, useEffect, useRef, useCallback} from 'react';
import {Paper, Text, Flex, Fieldset, Card, Box, Group, Image} from '@mantine/core';
import {Face} from "../../types/Face";
import {ImageData} from "../../types/Image";
import {PersonBetter} from "../../types/Person";
import {UseAutocomplete} from "../identifyFaces/UseAutocomplete";
import {
    fetchFacesInImages,
    labelFace,
    removeIsPersonSuggestionForFace,
    untrackFace
} from "../../api/Faces";
import {createPerson} from "../../api/People";
import {FaceGridSkeleton} from "../identifyFaces/FaceGridSkeleton";
import {FaceCard} from "../identifyFaces/FaceCard";
import { IconStack2 } from '@tabler/icons-react';

interface ConfirmFacesModalContentProps {
    render: boolean,
    onSkip: () => void;
    inImages: ImageData[],
    onIsReady: () => void,
    setNewPersonReady: (ready: boolean) => void,
    onNewPersonAdded: (person: PersonBetter) => void,
    onAllFacesConfirmed: () => void,
    setNewPeopleReady?: (ready: boolean) => void
}

// todo: determine if i still want the stack functionality? this might be the place to implement this content.
//  alternative i could do a "people in images carosel - honestly, i might like this option better?

export const ConfirmFacesModalContent: React.FC<ConfirmFacesModalContentProps> = ({
                                                                                      render,
                                                                                      onSkip,
                                                                                      inImages,
                                                                                      onIsReady,
                                                                                      setNewPersonReady,
                                                                                      onNewPersonAdded,
                                                                                      onAllFacesConfirmed,
                                                                                      setNewPeopleReady
                                                                                  }) => {
// export function ConfirmFacesModalContent({inImages, onNewPersonAdded}: ConfirmFacesModalContentProps) { // todo: del

    // Face loading state
    // const [faces, setFaces] = useState<Face[]>([]);
    const [confirmedFaces, setConfirmedFaces] = useState<Face[]>([]);
    const [unconfirmedFaces, setUnconfirmedFaces] = useState<Face[]>([]);
    const [loading, setLoading] = useState(false);


    const [expandedStacks, setExpandedStacks] = useState<{ [key: string]: boolean }>({});

    // todo: sort & track confirmed vs unconfirmed faces
    // todo: after each successful image handling, i should check if unconfirmed.length = 0. When this is true trigger onAllFacesConfirmed()
    //  note here, that i want ot check length after handleFaceAction is complete as I don't want to trigger onAllFacesConfirmed if the change could still be rolled back.

    // UI state
    const [hoveredCard, setHoveredCard] = useState<string>("");
    const [editingFace, setEditingFace] = useState<string>("");

    // Autocomplete hook
    // todo: how can i set this up so that I can reload occasionally (i.e. specifically when i add a new person)
    const {
        searchValue,
        setSearchValue,
        suggestions,
        updateSuggestions,
        people,
        loading: peopleLoading
    } = UseAutocomplete({});


    const loadFaces = useCallback(async () => {
        setLoading(true);
        try {
            const keys: string[] = inImages.map(i => i.key);
            const response = await fetchFacesInImages(keys);

            if (response.faces.length > 0) {
                const confirmed = response.faces.filter((face) => face.is_person?.confirmed);
                const unconfirmed = response.faces.filter((face) => !face.is_person?.confirmed);
                setConfirmedFaces(confirmed);
                setUnconfirmedFaces(unconfirmed);
                // setFaces(prev => [...prev, ...response.faces]);
            } else {
                onSkip()
            }

            // todo: handle sorting these faces into confirmed/unconfirmed.
            //  if no unconfirmed, onAllFacesConfirmed() &

        } catch (error) {
            console.error('Failed to load faces:', error);

        } finally {
            setLoading(false);
            onIsReady();

        }
    }, []);

    // Initial load
    useEffect(() => {
        loadFaces() // todo: what would the .then be for this?
    }, [loadFaces]);


    const updateFacesWithNewSuggestion = async (facesToUpdate: string[], person: PersonBetter) => {
        setUnconfirmedFaces((prevFaces) =>
            prevFaces.map((face) =>
                facesToUpdate.includes(face.face_key)
                    ? { ...face, is_person: { person, confirmed: false } }
                    : face
            )
        );
    };

    const handleFaceAction = async (face: Face, action: 'confirm' | 'deny' | 'untrack') => {
        console.log(face)
        console.log(`[FaceGrid] Starting ${action} action for face:`, {
            face_key: face.face_key,
            is_person: face.is_person?.person.seed_key,
            action: action
        });



        try {
            let actionResult;

            switch (action) {
                case 'confirm':
                    setUnconfirmedFaces((prev) => prev.filter((f) => f.face_key !== face.face_key));
                    setConfirmedFaces((prev) => [...prev, { ...face, is_person: { ...face.is_person!, confirmed: true } }]);
                    if (face.is_person?.person.seed_key) {
                        console.log('[FaceGrid] Confirming face with person:', {
                            face_key: face.face_key,
                            person_key: face.is_person.person.seed_key,
                            person_name: `${face.is_person.person.first} ${face.is_person.person.last}`
                        });
                        let newFaceSuggestions: string[] = await labelFace(face, face.is_person.person);
                        updateFacesWithNewSuggestion(newFaceSuggestions, face.is_person.person);

                        console.log('[FaceGrid] Label face result:', actionResult);
                        if (unconfirmedFaces.length - 1 === 0) {
                            onAllFacesConfirmed();
                        }

                    } else {
                        console.warn('[FaceGrid] Cannot confirm face - no person suggestion found:', face.face_key);
                    }
                    break;

                case 'deny':
                    // todo: optimistically sets face is_person undefined. ideally if this doesnt success we we roll this back?
                    setUnconfirmedFaces((prev) =>
                        prev.map((f) =>
                            f.face_key === face.face_key ? { ...f, is_person: undefined } : f
                        )
                    );
                    console.log('[FaceGrid] Denying person suggestion for face:', {
                        face_key: face.face_key,
                        suggested_person: face.is_person?.person
                    });
                    actionResult = await removeIsPersonSuggestionForFace(face);
                    console.log('[FaceGrid] Remove suggestion result:', actionResult);
                    break;

                case 'untrack':
                    setUnconfirmedFaces((prev) => prev.filter((f) => f.face_key !== face.face_key));
                    console.log('[FaceGrid] Untracking face:', {
                        face_key: face.face_key
                    });
                    actionResult = await untrackFace(face);
                    console.log('[FaceGrid] Untrack result:', actionResult);
                    break;
            }

            console.log(`[FaceGrid] Successfully completed ${action} action for face:`, face.face_key);

        } catch (error) {
            // Detailed error logging
            console.error(`[FaceGrid] Error during ${action} action:`, {
                action,
                face_key: face.face_key,
                error: error instanceof Error ? {
                    message: error.message,
                    stack: error.stack
                } : error
            });

            // Rollback on error
            console.log('[FaceGrid] Rolling back optimistic update...');

            // setUnconfirmedFaces((prev) => prev.filter((f) => f.face_key !== face.face_key));

            if (action === 'confirm') {
                setUnconfirmedFaces(prevFaces => {
                    const rolledBackFaces = [...prevFaces, face];
                    console.log('[FaceGrid] Rolled back faces count:', rolledBackFaces.length);
                    return rolledBackFaces;
                });
                setConfirmedFaces((prev) => prev.filter((f) => f.face_key !== face.face_key));
            }
            // You might want to show an error notification to the user here
        }
    };

    const handleNameSelection = async (face: Face, selectedName: string) => {
        console.log('[FaceGrid] Handling name selection:', {
            face_key: face.face_key,
            selectedName: selectedName
        });

        setUnconfirmedFaces((prev) => prev.filter((f) => f.face_key !== face.face_key));

        if (selectedName.startsWith('+ Add "')) {
            setNewPersonReady(false);
            const newName = selectedName.match(/"([^"]+)"/)?.[1] || '';
            console.log('[FaceGrid] Adding new person:', newName);
            try {
                let newPerson: PersonBetter = await createPerson(newName, face.face_key);
                setConfirmedFaces((prev) => [...prev, { ...face, is_person: {person: newPerson, confirmed: true } }]);
                // todo: should also reload autocomplete / update options to include this new person?

                let newFaceSuggestions: string[] = await labelFace(face, newPerson);
                updateFacesWithNewSuggestion(newFaceSuggestions, newPerson);
                // passes on new person back so we know to ask the user for additional details in the next modal.
                onNewPersonAdded(newPerson)
            } catch (e) {
                // rollback face upates
                setUnconfirmedFaces(prevFaces => {
                    const rolledBackFaces = [...prevFaces, face];
                    console.log('[FaceGrid] Rolled back faces after error:', rolledBackFaces.length);
                    return rolledBackFaces;
                });
                setNewPersonReady(true);
                throw e;
            }
        } else {
            const selectedPerson = people.find(
                p => `${p.first} ${p.last}` === selectedName
            );

            if (selectedPerson) {
                setConfirmedFaces((prev) => [...prev, { ...face, is_person: {person: selectedPerson, confirmed: true } }]);
                console.log('[FaceGrid] Found matching person:', {
                    person_key: selectedPerson.seed_key,
                    name: selectedPerson.first && selectedPerson.last ? `${selectedPerson.first} ${selectedPerson.last}` : ""
                });

                try {
                    console.log('[FaceGrid] Labeling face with selected person:', {
                        face_key: face.face_key,
                        person_key: selectedPerson.seed_key
                    });

                    let newFaceSuggestions: string[] = await labelFace(face, selectedPerson);
                    updateFacesWithNewSuggestion(newFaceSuggestions, selectedPerson);
                    // console.log('[FaceGrid] Label result:', result);
                } catch (error) {
                    console.error('[FaceGrid] Error labeling face:', {
                        face_key: face.face_key,
                        person_key: selectedPerson.seed_key,
                        error: error instanceof Error ? {
                            message: error.message,
                            stack: error.stack
                        } : error
                    });

                    setUnconfirmedFaces(prevFaces => {
                        const rolledBackFaces = [...prevFaces, face];
                        console.log('[FaceGrid] Rolled back faces count:', rolledBackFaces.length);
                        return rolledBackFaces;
                    });
                    setConfirmedFaces((prev) => prev.filter((f) => f.face_key !== face.face_key));
                }
            } else {
                console.warn('[FaceGrid] No matching person found for name:', selectedName);
            }
        }

        console.log('[FaceGrid] Resetting UI state');
        setEditingFace("");
        setSearchValue("");
    };

    // const toggleStack = (personKey: string) => {
    //     setExpandedStacks((prev) => ({
    //         ...prev,
    //         [personKey]: !prev[personKey],
    //     }));
    // };
    //
    // const renderConfirmedStacks = () => {
    //     const groupedFaces = confirmedFaces.reduce<{ [key: string]: Face[] }>((acc, face) => {
    //         const personKey = face.is_person!.person.seed_key;
    //         if (!acc[personKey]) acc[personKey] = [];
    //         acc[personKey].push(face);
    //         return acc;
    //     }, {});
    //
    //
    //     return Object.entries(groupedFaces).map(([key, faces]) => (
    //             faces.map((face) => (
    //                 <Box>
    //                     <FaceCard
    //                         key={face.face_key}
    //                         face={face}
    //                         isHovered={hoveredCard === face.face_key}
    //                         isEditing={editingFace === face.face_key}
    //                         onHover={setHoveredCard}
    //                         onLeave={() => setHoveredCard("")}
    //                         onAction={(action) => handleFaceAction(face, action)}
    //                         onEdit={() => {
    //                             setEditingFace(face.face_key);
    //                             const fullName = face.is_person ? `${face.is_person?.person.first} ${face.is_person?.person.last}`.trim() : "";
    //                             setSearchValue(fullName);
    //                             updateSuggestions(fullName);
    //                         }}
    //                         autocompleteProps={{
    //                             searchValue,
    //                             suggestions,
    //                             onSearchChange: (value) => {
    //                                 setSearchValue(value);
    //                                 updateSuggestions(value);
    //                             },
    //                             onOptionSubmit: (value) => handleNameSelection(face, value),
    //                             onKeyDown: (e) => {
    //                                 if (e.key === "Tab" && suggestions.length > 0) {
    //                                     e.preventDefault();
    //                                     setSearchValue(suggestions[0]);
    //                                     updateSuggestions(suggestions[0]);
    //                                 } else if (e.key === "Enter" && searchValue) {
    //                                     const exactMatch = suggestions.find(
    //                                         (suggestion) => suggestion.toLowerCase() === searchValue.toLowerCase()
    //                                     );
    //                                     if (exactMatch) {
    //                                         handleNameSelection(face, exactMatch);
    //                                     } else if (suggestions.length > 0) {
    //                                         handleNameSelection(face, suggestions[0]);
    //                                     }
    //                                 }
    //                             },
    //                         }}
    //                     />
    //                     {faces.length > 1 && (
    //                         <Group
    //                             gap={'3px'}
    //                             style={{
    //                                 position: 'absolute',
    //                                 top: 5,
    //                                 right: 5,
    //                                 backgroundColor: 'rgba(48, 48, 48, 1.0)',
    //                                 borderRadius: '15%',
    //                                 cursor: 'pointer',
    //                                 padding: '2px 4px',
    //                             }}
    //                             onClick={() => toggleStack(key)}
    //                         >
    //                             <Text size="md" c="white" w="bold" pl={'2px'}>
    //                                 {expandedStacks[key] ? faces.length : '1+'}
    //                             </Text>
    //                             <IconStack2 size={20} color="white" />
    //                         </Group>
    //                     )}
    //                 </Box>
    //             ))
    //     ));
    // };

    if (loading || !render) {
        return <></>;
    }

    return (
        <div>
            <Paper shadow="sm" radius="lg" withBorder p="md">
                <Text size="lg" mb="md">Confirmed People In Event:</Text>
                <Flex wrap="wrap">
                    {confirmedFaces.map((face) => (
                        <FaceCard
                            key={face.face_key}
                            face={face}
                            isHovered={hoveredCard === face.face_key}
                            isEditing={editingFace === face.face_key}
                            onHover={setHoveredCard}
                            onLeave={() => setHoveredCard("")}
                            onAction={(action) => handleFaceAction(face, action)}
                            onEdit={() => {
                                setEditingFace(face.face_key);
                                const fullName = face.is_person ? `${face.is_person?.person.first} ${face.is_person?.person.last}`.trim() : "";
                                setSearchValue(fullName);
                                updateSuggestions(fullName);
                            }}
                            autocompleteProps={{
                                searchValue,
                                suggestions,
                                onSearchChange: (value) => {
                                    setSearchValue(value);
                                    updateSuggestions(value);
                                },
                                onOptionSubmit: (value) => handleNameSelection(face, value),
                                onKeyDown: (e) => {
                                    if (e.key === "Tab" && suggestions.length > 0) {
                                        e.preventDefault();
                                        setSearchValue(suggestions[0]);
                                        updateSuggestions(suggestions[0]);
                                    } else if (e.key === "Enter" && searchValue) {
                                        const exactMatch = suggestions.find(
                                            (suggestion) => suggestion.toLowerCase() === searchValue.toLowerCase()
                                        );
                                        if (exactMatch) {
                                            handleNameSelection(face, exactMatch);
                                        } else if (suggestions.length > 0) {
                                            handleNameSelection(face, suggestions[0]);
                                        }
                                    }
                                },
                            }}
                        />
                    ))}
                </Flex>
            </Paper>

            <Fieldset mt="md" legend="Please confirm/name these faces:">
                <Flex wrap="wrap">
                    {unconfirmedFaces.map((face) => (
                        <FaceCard
                            key={face.face_key}
                            face={face}
                            isHovered={hoveredCard === face.face_key}
                            isEditing={editingFace === face.face_key}
                            onHover={setHoveredCard}
                            onLeave={() => setHoveredCard("")}
                            onAction={(action) => handleFaceAction(face, action)}
                            onEdit={() => {
                                setEditingFace(face.face_key);
                                const fullName = face.is_person ? `${face.is_person?.person.first} ${face.is_person?.person.last}`.trim() : "";
                                setSearchValue(fullName);
                                updateSuggestions(fullName);
                            }}
                            autocompleteProps={{
                                searchValue,
                                suggestions,
                                onSearchChange: (value) => {
                                    setSearchValue(value);
                                    updateSuggestions(value);
                                },
                                onOptionSubmit: (value) => handleNameSelection(face, value),
                                onKeyDown: (e) => {
                                    if (e.key === "Tab" && suggestions.length > 0) {
                                        e.preventDefault();
                                        setSearchValue(suggestions[0]);
                                        updateSuggestions(suggestions[0]);
                                    } else if (e.key === "Enter" && searchValue) {
                                        const exactMatch = suggestions.find(
                                            (suggestion) => suggestion.toLowerCase() === searchValue.toLowerCase()
                                        );
                                        if (exactMatch) {
                                            handleNameSelection(face, exactMatch);
                                        } else if (suggestions.length > 0) {
                                            handleNameSelection(face, suggestions[0]);
                                        }
                                    }
                                },
                            }}
                        />
                    ))}
                </Flex>
            </Fieldset>
        </div>
    );

    // return (
    //     <>
    //         <Paper shadow="sm" radius="lg" withBorder p="md" style={{backgroundColor: "#f0f0f0"}}>
    //             <Text size="lg" mb="md">Confirmed People In Event:</Text>
    //
    //             {/*todo: this should probably be the face stacks as originally implemented */}
    //             {/* or something like the carousel in the id faces?*/}
    //             {/* either of these options require preprocessing of faces when returned from api call. */}
    //             {/* I think this processing/sorting should actually be happening on the frontend side! */}
    //             {/* No need to modify the API to return these people groups/stacks/etc.*/}
    //             {/* Confirmed Faces Section */}
    //             <Flex direction="row" wrap="wrap" gap={{base: "1px", sm: "1px"}} justify="flex-start">
    //                 {confirmedFaces
    //                     .map((face) => (
    //                         <FaceCard
    //                             key={face.face_key}
    //                             face={face}
    //                             isHovered={hoveredCard === face.face_key}
    //                             isEditing={editingFace === face.face_key}
    //                             onHover={setHoveredCard}
    //                             onLeave={() => setHoveredCard("")}
    //                             onAction={(action) => handleFaceAction(face, action)}
    //                             onEdit={() => {
    //                                 setEditingFace(face.face_key);
    //                                 const fullName = face.is_person ? `${face.is_person?.person.first} ${face.is_person?.person.last}`.trim() : "";
    //                                 setSearchValue(fullName);
    //                                 updateSuggestions(fullName);
    //                             }}
    //                             autocompleteProps={{
    //                                 searchValue,
    //                                 suggestions,
    //                                 onSearchChange: (value) => {
    //                                     setSearchValue(value);
    //                                     updateSuggestions(value);
    //                                 },
    //                                 onOptionSubmit: (value) => handleNameSelection(face, value),
    //                                 onKeyDown: (e) => {
    //                                     if (e.key === "Tab" && suggestions.length > 0) {
    //                                         e.preventDefault();
    //                                         setSearchValue(suggestions[0]);
    //                                         updateSuggestions(suggestions[0]);
    //                                     } else if (e.key === "Enter" && searchValue) {
    //                                         const exactMatch = suggestions.find(
    //                                             (suggestion) => suggestion.toLowerCase() === searchValue.toLowerCase()
    //                                         );
    //                                         if (exactMatch) {
    //                                             handleNameSelection(face, exactMatch);
    //                                         } else if (suggestions.length > 0) {
    //                                             handleNameSelection(face, suggestions[0]);
    //                                         }
    //                                     }
    //                                 },
    //                             }}
    //                         />
    //                     ))}
    //             </Flex>
    //         </Paper>
    //
    //         <Fieldset mt="md" legend="Please confirm/name these faces:">
    //             <Flex mt="sm" direction="row" wrap="wrap" gap={{base: "1px", sm: "1px"}} justify="flex-start">
    //                 {unconfirmedFaces
    //                     .map((face) => (
    //                         <FaceCard
    //                             key={face.face_key}
    //                             face={face}
    //                             isHovered={hoveredCard === face.face_key}
    //                             isEditing={editingFace === face.face_key}
    //                             onHover={setHoveredCard}
    //                             onLeave={() => setHoveredCard("")}
    //                             onAction={(action) => handleFaceAction(face, action)}
    //                             onEdit={() => {
    //                                 setEditingFace(face.face_key);
    //                                 const fullName = face.is_person ? `${face.is_person?.person.first} ${face.is_person?.person.last}`.trim() : "";
    //                                 setSearchValue(fullName);
    //                                 updateSuggestions(fullName);
    //                             }}
    //                             autocompleteProps={{
    //                                 searchValue,
    //                                 suggestions,
    //                                 onSearchChange: (value) => {
    //                                     setSearchValue(value);
    //                                     updateSuggestions(value);
    //                                 },
    //                                 onOptionSubmit: (value) => handleNameSelection(face, value),
    //                                 onKeyDown: (e) => {
    //                                     if (e.key === "Tab" && suggestions.length > 0) {
    //                                         e.preventDefault();
    //                                         setSearchValue(suggestions[0]);
    //                                         updateSuggestions(suggestions[0]);
    //                                     } else if (e.key === "Enter" && searchValue) {
    //                                         const exactMatch = suggestions.find(
    //                                             (suggestion) => suggestion.toLowerCase() === searchValue.toLowerCase()
    //                                         );
    //                                         if (exactMatch) {
    //                                             handleNameSelection(face, exactMatch);
    //                                         } else if (suggestions.length > 0) {
    //                                             handleNameSelection(face, suggestions[0]);
    //                                         }
    //                                     }
    //                                 },
    //                             }}
    //                         />
    //                     ))}
    //             </Flex>
    //         </Fieldset>
    //     </>
    // );
};


// data to query from backend on load
// const [loadingFaces, setLoadingFaces] = useState(false);
// const [loadingPeople, setLoadingPeople] = useState(false);

// // todo: could refactor stack logic w/ add / remove functions
// // these confirmedStacks are used to group & display confirmed faces in a visually concise way.
// // The stackedStates constant tracks which stacks are open vs closed.
// // const [confirmedStacks, setConfirmedStacks] = useState<FaceStack[]>([]); // todo: face stacks are no more...
// const [stackedStates, setStackedStates] = useState<{[key: string]: boolean}>({});
// const toggleStackState = (stackKey: string) => {
//     setStackedStates(prev => ({
//         ...prev,
//         [stackKey]: !(prev[stackKey] ?? true) // default to true if not set
//     }));
// };
//
// // todo: could refactor with onConfirm and onRollbackConfirm callbacks...
// // States that track data for faces that are being named.
// const [unconfirmedFaces, setUnconfirmedFaces] = useState<Face[]>([]);
// const [editingFace, setEditingFace] = useState<Face | null>(null); // this tracks which face is currently being edited
// const [editingValue, setEditingValue] = useState<string>(""); // this is the name that is currently typed into the face
// const [peopleInDatabase, setPeopleInDatabase] = useState<PersonBetter[]>([]); // todo: this maybe isn't needed - just used to fillout autocomplete states?
// const [autocompleteOptionsAll, setAutocompleteOptionsAll] = useState<AutocompletePersonOptions[]>([]);
// const [autocompleteOptionsDisplayed, setAutocompleteOptionsDisplayed] = useState<AutocompletePersonOptions[]>([]);
//
// // this will control what Face cards the buttons are displayed on (i.e. confirm, deny, untrack)
// const [hoveredFace, setHoveredFace] = useState<Face | null>(null); // todo: is this need or do the buttons just act on hover?
//
// // when a new person is added, this will control a modal to prompt for additional details.
// const [addNewPersonOpen, setAddNewPersonOpen] = useState(false);
// const [newPersonData, setNewPersonData] = useState<{face: Face, name: string} | null>(null);
//
// useEffect(() => {
//     console.log("confirm faces mounted?")
//     console.log(people);
//     processPeople(people);
//     console.log(faces);
//     // processFaces(faces);
// }, []);
//
// const processPeople = async (people: PersonBetter[]) => {
//     try {
//         // Create autocomplete options
//         const autocompleteOptions: AutocompletePersonOptions[] = people.map(person => ({
//             display_name: `${person.first} ${person.last}`,
//             person: person
//         }));
//
//         setPeopleInDatabase(people);
//         console.log('people in database:', peopleInDatabase);
//
//         setAutocompleteOptionsAll(autocompleteOptions)
//         // setAutocompleteOptionsDisplayed(autocompleteOptions)
//         console.log('autocomplete autocompleteOptionsDisplayed:', autocompleteOptions);
//
//     } catch (error) {
//         console.error('Error fetching people', error);
//     } finally {
//         setLoadingPeople(false);
//
//     }
// };

// const processFaces = async (faces: Face[]) => {
//
//     try {
//
//         // Separate confirmed and unconfirmed faces
//         const unconfirmedFacesList = faces.filter(face => !face.confirmed);
//         setUnconfirmedFaces(unconfirmedFacesList);
//
//         // Group confirmed faces into stacks
//         const confirmedFaceStacks: FaceStack[] = [];
//         const confirmedFaces = faces.filter(face => face.confirmed);
//
//         // Group faces by person (if available)
//         const faceGroups: {[key: string]: Face[]} = confirmedFaces.reduce((acc, face) => {
//             // Use person's seed_key or face_key as the grouping key
//             const groupKey = face.is_person?.seed_key || face.face_key;
//
//             if (!acc[groupKey]) {
//                 acc[groupKey] = [];
//                 stackedStates[groupKey] = true;
//             }
//             acc[groupKey].push(face);
//
//             return acc;
//         }, {} as {[key: string]: Face[]});
//
//         // Convert grouped faces into FaceStacks
//         Object.entries(faceGroups).forEach(([key, groupedFaces]) => {
//             const stack: FaceStack = {
//                 stack_key: key,
//                 faces: groupedFaces
//             };
//             confirmedFaceStacks.push(stack);
//         });
//
//         // Set the confirmed stacks state
//         setConfirmedStacks(confirmedFaceStacks);
//
//         console.log('Confirmed Stacks:', confirmedFaceStacks);
//         console.log('Unconfirmed Faces:', unconfirmedFacesList);
//
//     } catch (error) {
//         console.error('Error fetching faces', error);
//     } finally {
//         setLoadingFaces(false);
//     }
// };
//
// const handleAutocompleteChange = (value: string, face: Face) => {
//     console.log("Autocomplete changed:", value); // Debug log
//     setEditingValue(value);
//
//     // Filter autocomplete options based on the input value
//     const filteredKnownPeople = autocompleteOptionsAll
//         .filter(option =>
//             option.display_name.toLowerCase().startsWith(value.toLowerCase())
//         )
//         .slice(0, 3);
//
//     // Create an "Add new" option if no matches exist
//     const addNewOption: AutocompletePersonOptions = {
//         display_name: `+ Add "${value}"`,
//         person: undefined
//     };
//
//     // If no matches start with the value, add the "Add new" option
//     const finalTags = filteredKnownPeople.length === 0
//         ? [addNewOption]
//         : filteredKnownPeople;
//
//     setAutocompleteOptionsDisplayed(finalTags);
// };
//
// // todo: what if i deleted this?
// const handleAutoCompleteSelect = (selectedOption: AutocompletePersonOptions, face: Face) => {
//     console.log("Autocomplete selected:", selectedOption); // Debug log
//     // ... rest of the existing method
// };
//
// const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, face: Face) => {
//     console.log("Key pressed:", event.key); // Debug log
//
//     if (event.key === 'Enter') {
//         console.log("Enter key pressed with value:", editingValue); // Debug log
//         // Find a matching person option
//         const matchingOption = autocompleteOptionsAll.find(option =>
//             option.display_name.toLowerCase() === editingValue.toLowerCase()
//         );
//
//         if (matchingOption && matchingOption.person) {
//             console.log("found a matching option");
//             // If exact match found, select that person
//             handleAutoCompleteSelect(matchingOption, face);
//             nameAsExistingPerson(face, matchingOption.person);
//             // todo: don't clear editing states until nameAsExistingPerson has executed? or copy parameters before clearing? or move these calls to end of nameAsExisting person?
//             setEditingFace(null);
//             setEditingValue("");
//         } else if (editingValue.trim()) {
//             console.log("need to create a new person!!!!");
//             setAddNewPersonOpen(true); // this will open the modal that will create faces & name on close
//         }
//         setAutocompleteOptionsDisplayed([]) // empty after enter
//     } else if (event.key === 'Tab') {
//         console.log("Tab key pressed with tags:", autocompleteOptionsDisplayed); // Debug log
//         event.preventDefault();
//         if (autocompleteOptionsDisplayed.length > 0 && autocompleteOptionsDisplayed[0].person !== undefined) {
//             // Set the first tag's display name as the editing value
//             setEditingValue(autocompleteOptionsDisplayed[0].display_name); // todo: edit so we don't get "add" option.
//         }
//     }
// };
//
// const makeApiCall = async (personKey: string, faces: Face[], action: 'confirm' | 'deny' | 'untrack' | 'label') => {
//     try {
//         // Extract the face keys from the faces array
//         let faceKeys: string[] = faces.map(face => face.face_key);
//
//         const response = await axios.patch(`${API_BASE_URL}/faces`, {
//             uid: userId,
//             person_key: personKey,
//             face_keys: faceKeys,
//             action,
//         }, {
//             headers: {
//                 'Authorization': `Bearer ${auth.IdToken}`,
//                 'Content-Type': 'application/json'
//             }
//         });
//
//         return response.data;
//     } catch (error) {
//         console.error(`Error ${action}ing face:`, error);
//         throw new Error(`An error occurred while ${action}ing the face. Please try again.`);
//     }
// };
//
// const confirmUnnamedFace = async (face: Face) => {
//     // Ensure the face has an associated person
//     if (!face.is_person) {
//         alert('No person information available for this face');
//         return;
//     }
//
//     // Store the original state in case we need to rollback
//     const originalState = {
//         unconfirmedFaces: unconfirmedFaces,
//         confirmedStacks: confirmedStacks
//     };
//
//     try {
//         // Optimistically update the UI
//         // 1. Mark the face as confirmed
//         const confirmedFace = { ...face, confirmed: true };
//
//         // 2. Remove from unconfirmed faces
//         setUnconfirmedFaces(prev =>
//             prev.filter(f => f.face_key !== face.face_key)
//         );
//
//         // 3. Add to confirmed stacks
//         setConfirmedStacks(prev => {
//             // Check if a stack for this person already exists
//             const existingStackIndex = prev.findIndex(
//                 stack => stack.stack_key === face.is_person?.seed_key
//             );
//
//             if (existingStackIndex !== -1) {
//                 // Add face to existing stack
//                 const updatedStacks = [...prev];
//                 updatedStacks[existingStackIndex] = {
//                     ...updatedStacks[existingStackIndex],
//                     faces: [...updatedStacks[existingStackIndex].faces, confirmedFace]
//                 };
//                 return updatedStacks;
//             } else {
//                 // Create a new stack
//                 return [...prev, {
//                     stack_key: face.is_person!.seed_key, // todo: why need to use ! (i check at the start if face.is_person!)
//                     faces: [confirmedFace]
//                 }];
//             }
//         });
//
//         // Make API call to confirm the face
//         await makeApiCall(
//             face.is_person.seed_key,
//             [face],
//             'confirm'
//         );
//
//     } catch (error) {
//         // Rollback the changes if API call fails
//         setUnconfirmedFaces(originalState.unconfirmedFaces);
//         setConfirmedStacks(originalState.confirmedStacks);
//
//         const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
//         alert(errorMessage);
//     }
// };
//
// const nameAsExistingPerson = async (face: Face, person: PersonBetter) => {
//     // Ensure the face has an associated person
//     // if (!face.is_person) {
//     //     alert('No person information available for this face');
//     //     return;
//     // }
//
//     // Store the original state in case we need to rollback
//     const originalState = {
//         unconfirmedFaces: unconfirmedFaces,
//         confirmedStacks: confirmedStacks
//     };
//
//     try {
//         // 1. Prepare the named face
//         const namedFace: Face = {
//             ...face,
//             confirmed: true,
//             is_person: person
//         };
//
//         // 2. Remove from unconfirmed faces
//         setUnconfirmedFaces(prev =>
//             prev.filter(f => f.face_key !== face.face_key)
//         );
//
//         // 3. Add to confirmed stacks
//         setConfirmedStacks(prev => {
//             // Check if a stack for this person already exists
//             const existingStackIndex = prev.findIndex(
//                 stack => stack.stack_key === person.seed_key
//             );
//
//             if (existingStackIndex !== -1) {
//                 // Add face to existing stack
//                 const updatedStacks = [...prev];
//                 updatedStacks[existingStackIndex] = {
//                     ...updatedStacks[existingStackIndex],
//                     faces: [...updatedStacks[existingStackIndex].faces, namedFace]
//                 };
//                 return updatedStacks;
//             } else {
//                 // Create a new stack
//                 return [...prev, {
//                     stack_key: person.seed_key,
//                     faces: [namedFace]
//                 }];
//             }
//         });
//
//         // Make API call to label the face
//         await makeApiCall(
//             person.seed_key,
//             [face],
//             'label'
//         );
//
//     } catch (error) {
//         // Rollback the changes if API call fails
//         setUnconfirmedFaces(originalState.unconfirmedFaces);
//         setConfirmedStacks(originalState.confirmedStacks);
//
//         const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
//         alert(errorMessage);
//     }
// };
//
// const denyUnnamedFace = async (face: Face) => {
//     if (!face.is_person) {
//         alert('No person information available for this face');
//         return;
//     }
//
//     // Store the original is_person in case we need to rollback
//     const originalIsPerson = face.is_person;
//
//     try {
//         // Optimistically update the face's is_person to null
//         setUnconfirmedFaces(prev =>
//             prev.map(f =>
//                 f.face_key === face.face_key
//                     ? {face_key: f.face_key, face_url: f.face_url, confirmed: false} // rewrite without person
//                     : f
//             )
//         );
//
//         await makeApiCall(
//             face.is_person.seed_key,
//             [face],
//             'deny'
//         );
//
//     } catch (error) {
//         // Rollback the changes if API call fails
//         setUnconfirmedFaces(prev =>
//             prev.map(f =>
//                 f.face_key === face.face_key
//                     ? { ...f, is_person: originalIsPerson } // add back original person
//                     : f
//             )
//         );
//
//         const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
//         alert(errorMessage);
//     }
// };
//
// //
// const untrackFace = async (face: Face) => {
//     // Store the original face in case we need to rollback
//     const originalFace = { ...face };
//
//     try {
//         // Optimistically remove the face from unconfirmedFaces
//         setUnconfirmedFaces(prev =>
//             prev.filter(f => f.face_key !== face.face_key)
//         );
//
//         await makeApiCall(
//             '', // person key not required to untrack
//             [face],
//             'untrack'
//         );
//
//     } catch (error) {
//         // Rollback the changes if API call fails
//         setUnconfirmedFaces(prev => [...prev, originalFace]);
//
//         const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
//         alert(errorMessage);
//     }
// };
//
// const handleAddFamilyMember = async (
//     face: Face,
//     firstName: string,
//     lastName: string,
//     dateOfBirth: string
// ) => {
//
//     try {
//         // Create a new API endpoint for adding a person
//         const response = await axios.post(
//             "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/person",
//             {
//                 uid: userId,
//                 first_name: firstName,
//                 last_name: lastName,
//                 dob: dateOfBirth,
//                 key: face.face_key
//             },
//             {
//                 headers: {
//                     'Authorization': `Bearer ${auth.IdToken}`,
//                     'Content-Type': 'application/json'
//                 }
//             }
//         );
//
//         console.log(response.data.body);
//         let newPerson: PersonBetter = JSON.parse(response.data.body);
//         console.log(newPerson);
//
//         // Update peopleInDatabase state
//         setPeopleInDatabase(prevPeople => {
//             // Check if the person already exists to avoid duplicates
//             const existingPersonIndex = prevPeople.findIndex(p => p.seed_key === newPerson.seed_key);
//
//             if (existingPersonIndex !== -1) {
//                 // Update existing person if found
//                 const updatedPeople = [...prevPeople];
//                 updatedPeople[existingPersonIndex] = newPerson;
//                 return updatedPeople;
//             } else {
//                 // Add new person
//                 return [...prevPeople, newPerson];
//             }
//         });
//
//         // Create AutocompletePersonOptions for the new person
//         const newAutocompleteOption: AutocompletePersonOptions = {
//             display_name: `${newPerson.first} ${newPerson.last}`,
//             person: newPerson
//         };
//
//         // Update autocompleteOptionsAll state
//         setAutocompleteOptionsAll(prevOptions => {
//             // Check if the option already exists to avoid duplicates
//             const existingOptionIndex = prevOptions.findIndex(
//                 opt => opt.person?.seed_key === newPerson.seed_key
//             );
//
//             if (existingOptionIndex !== -1) {
//                 // Update existing option if found
//                 const updatedOptions = [...prevOptions];
//                 updatedOptions[existingOptionIndex] = newAutocompleteOption;
//                 return updatedOptions;
//             } else {
//                 // Add new option
//                 return [...prevOptions, newAutocompleteOption];
//             }
//         });
//
//         // Name the face with the newly created person
//         nameAsExistingPerson(face, newPerson);
//
//     } catch (error) {
//         console.error('Error adding family person:', error);
//         alert('Failed to add family person. Please try again.');
//     } finally {
//         onNewPersonAdded();
//     }
// };
//
//
// // const openAddNewPersonModal = async (face: UnnamedFaceInImage, person: People) => {
// //     // Instead of immediately creating a new person, open the family tree modal
// //     setCurrentNewPersonData({
// //         face: face,
// //         name: person.person_name
// //     });
// //     setIsAddFamilyTreeModalOpen(true);
// // };
//
//
// const renderStackCounter = (stack: FaceStack, index: number) => (
//     <Group
//         gap={'3px'}
//         style={{
//             position: 'absolute',
//             top: 5,
//             right: 5,
//             backgroundColor: 'rgba(48, 48, 48, 1.0)',
//             borderRadius: '15%',
//             cursor: 'pointer',
//             padding: '2px 4px'
//         }}
//         onClick={() => toggleStackState(stack.stack_key)}
//     >
//         <Text size="md" c="white" w="bold" pl={'2px'}>
//             {stackedStates[stack.stack_key] ? stack.faces.length: `${index + 1} of ${stack.faces.length}`}
//         </Text>
//         <IconStack2 size={20} color="white"/>
//     </Group>
// );
//
// const renderFaceCard = (stack: FaceStack, index: number) => (
//     <div style={{
//         position: 'relative',
//         width: 'calc((100% - 17px) / 6)',
//         margin: '1px'
//     }}>
//         <Card
//             p={0}
//             w="100%"
//             style={{aspectRatio: '4 / 5', position: 'relative'}}
//         >
//             <Box style={{height: '80%', position: 'relative'}}>
//                 <Image
//                     src={stack.faces[index].face_url}
//                     height="100%"
//                     width="100%"
//                     fit="cover"
//                 />
//                 {(stack.faces.length > 1) && renderStackCounter(stack, index)}
//             </Box>
//             <Paper
//                 style={{
//                     height: '20%',
//                     width: '100%',
//                     borderTopLeftRadius: 0,
//                     borderTopRightRadius: 0,
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                 }}
//                 bg="#303030"
//             >
//                 <Text
//                     size="sm"
//                     w="bold"
//                     c="white"
//                     ta="center"
//                 >
//                     {(stack.faces[index].is_person?.first + ' ' + stack.faces[index].is_person?.last)}
//                 </Text>
//             </Paper>
//         </Card>
//     </div>
// );
//
// return (
//     <>
//     <>
//         {/*todo: only render this paper component/confirmed faces section if confirmed faces > 1*/}
//         <Paper shadow="sm" radius="lg" withBorder p="md" style={{backgroundColor: '#f0f0f0'}}>
//             <Text size="lg" mb="md">Confirmed People In Event:</Text>
//             {/* Confirmed Faces Section */}
//             <Flex
//                 direction="row"
//                 wrap="wrap"
//                 gap={{base: '1px', sm: '1px'}}
//                 justify="flex-start"
//             >
//                 {/*todo: implement a renderConfirmedFacesStack function rather than this weird iteration & renderFaceCard calls.*/}
//                 {confirmedStacks.map((stack) => (
//                     stack.faces.map((_, index) => (
//                         (index === 0 || !(stackedStates[stack.stack_key] ?? true)) && renderFaceCard(stack, index)
//                     ))
//                 ))}
//             </Flex>
//         </Paper>
//         <Fieldset mt="md" legend="Please confirm/name these faces:">
//             <Flex
//                 mt="md"
//                 direction="row"
//                 wrap="wrap"
//                 gap={{base: '1px', sm: '1px'}}
//                 justify="flex-start"
//             >
//                 {/*todo: implement a renderFacesToConfirm. Extract any large components (e.g. autocomplete functionality) so that code is modular & reusable.*/}
//                 {unconfirmedFaces.map((face) => (
//                     <div key={face.face_key} style={{
//                         position: 'relative',
//                         width: 'calc((100% - 17px) / 6)',
//                         cursor: 'pointer',
//                         margin: '1px'
//                     }}>
//                         <Card
//                             p={0}
//                             w="100%"
//                             style={{aspectRatio: '4 / 5'}}
//                             onMouseEnter={() => setHoveredFace(face)}
//                             onMouseLeave={() => setHoveredFace(null)}
//
//                         >
//                             <Flex direction="column" h="100%">
//                                 <Box style={{flexBasis: '80%', position: 'relative'}}>
//                                     <Image
//                                         src={face.face_url}
//                                         height="100%"
//                                         width="100%"
//                                         fit="cover"
//                                     />
//                                 </Box>
//                                 <Paper
//                                     style={{
//                                         flexBasis: '20%',
//                                         width: '100%',
//                                         borderTopLeftRadius: 0,
//                                         borderTopRightRadius: 0
//                                     }}
//                                     bg="lightgray"
//                                 >
//                                     {editingFace === face ? (
//                                         <Autocomplete
//                                             value={editingValue}
//                                             data={autocompleteOptionsDisplayed.map(autocompleteOptions => autocompleteOptions.display_name)}
//                                             onChange={(value) => handleAutocompleteChange(value, face)}
//                                             onKeyDown={(e) => handleInputKeyDown(e, face)}
//                                             // onSubmit={(e) => {
//                                             //     console.log("Autocomplete submitted:", e); // Debug log
//                                             //     if (autocompleteOptionsDisplayed.length > 0) {
//                                             //         handleAutoCompleteSelect(autocompleteOptionsDisplayed[0], face);
//                                             //     }
//                                             // }}
//                                             onFocus={(e) => {
//                                                 console.log("Autocomplete focused"); // Debug log
//
//                                                 // Set the editing value first
//                                                 const initialValue = face.is_person
//                                                     ? `${face.is_person.first + ' ' + face.is_person.last}`
//                                                     : "";
//                                                 setEditingValue(initialValue);
//
//                                                 // Use setTimeout to ensure the value is set before selecting
//                                                 setTimeout(() => {
//                                                     // This method works across different browsers to select all text
//                                                     e.target.setSelectionRange(0, initialValue.length);
//                                                 }, 0);
//                                             }}
//                                             styles={{
//                                                 root: {
//                                                     display: 'flex',
//                                                     justifyContent: 'center',
//                                                     alignItems: 'center',
//                                                     width: '100%',
//                                                     height: '100%',
//                                                     paddingLeft: '3px',
//                                                     paddingRight: '3px',
//                                                     zIndex: 3
//                                                 },
//                                                 input: {
//                                                     textAlign: 'center'
//                                                 }
//                                             }}
//                                             autoFocus
//                                         />
//                                     ) : (
//                                         hoveredFace === face ? (
//                                             <Group grow preventGrowOverflow={false} wrap="nowrap" h="100%"
//                                                    style={{paddingLeft: '5%', paddingRight: '5%'}}>
//                                                 <Flex justify="center" align="center" w="15%" h="100%">
//                                                     <Tooltip
//                                                         label={face.is_person ? `This is not ${face.is_person.first + ' ' + face.is_person.last}` : 'Untrack this face'}
//                                                         withArrow
//                                                         openDelay={500}
//                                                         position="bottom"
//                                                     >
//                                                         {/*todo: i think this might actually be more readable if I have code for 2 different buttons (and 2 different tooltips above)
//                                                             (i.e. the denyUnnamedFace w/ XIcon & untrack face with Trash) and use face.is_person to
//                                                             determine which button is rendered.*/}
//                                                         <Button
//                                                             variant="transparent"
//                                                             color="dark"
//                                                             onClick={() => face.is_person ? denyUnnamedFace(face) : untrackFace(face)}
//                                                             p={0}
//                                                             h="100%"
//                                                             w="100%"
//                                                         >
//                                                             {face.is_person ? <IconX size={16}/> :
//                                                                 <IconTrash size={16}/>}
//                                                         </Button>
//                                                     </Tooltip>
//                                                 </Flex>
//                                                 <Flex justify="center" align="center" w="70%" h="100%">
//                                                     {/*todo: for smaller names of the "?" icon, the target size for onClick is really small - can i make this a "text box" instead so the onClick target is larger?*/}
//                                                     <Text
//                                                         size="sm"
//                                                         ta="center"
//                                                         c="dark"
//                                                         style={{cursor: 'pointer'}}
//                                                         onClick={() => setEditingFace(face)}
//                                                     >
//                                                         {face.is_person ? `${face.is_person.first + ' ' + face.is_person.last}?` : "?"}
//                                                     </Text>
//                                                 </Flex>
//                                                 <Flex justify="center" align="center" w="15%" h="100%">
//                                                     {face.is_person ? (
//                                                         <Tooltip
//                                                             label={`Confirm face as ${face.is_person.first + ' ' + face.is_person.last}`}
//                                                             withArrow
//                                                             openDelay={500}
//                                                             position="bottom"
//                                                         >
//                                                             <Button
//                                                                 variant="transparent"
//                                                                 color="dark"
//                                                                 onClick={() => confirmUnnamedFace(face)}
//                                                                 p={0}
//                                                                 h="100%"
//                                                                 w="100%"
//                                                             >
//                                                                 <IconCheck size={16}/>
//                                                             </Button>
//                                                         </Tooltip>
//                                                     ) : (
//                                                         <Button
//                                                             variant="transparent"
//                                                             color="lightgray"
//                                                             onClick={() => setEditingFace(face)}
//                                                             p={0}
//                                                             h="100%"
//                                                             w="100%"
//                                                         >
//                                                             <IconPencil size={16}/>
//                                                         </Button>
//                                                     )}
//                                                 </Flex>
//                                             </Group>
//                                         ) : (
//                                             <Flex justify="center" align="center" h="100%">
//                                                 <Text
//                                                     size="sm"
//                                                     ta="center"
//                                                     c="dark"
//                                                     style={{cursor: 'pointer'}}
//                                                     onClick={() => setEditingFace(face)}
//                                                 >
//                                                     {face.is_person ? `${face.is_person.first + ' ' + face.is_person.last}?` : "?"}
//                                                 </Text>
//                                             </Flex>
//                                         )
//                                     )}
//                                 </Paper>
//                             </Flex>
//                         </Card>
//                     </div>
//                 ))}
//             </Flex>
//         </Fieldset>
//     </>
// {addNewPersonOpen && (
//     <AddNewPerson
//         onClose={() => {
//             // Close the modal
//             setAddNewPersonOpen(false);
//
//             // set the editing faces & values to null
//             setEditingFace(null);
//             setEditingValue('');}}
//         onAddFamilyMember={handleAddFamilyMember}
//         face={editingFace!} // todo: this should never be null but force unpack is perhaps not awesome?
//         name={editingValue}
//     />
// )}
// </>
// );
//     return (<></>);
// }